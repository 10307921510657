import { createTheme } from '@mui/material/styles';
import {
  Palette as MuiPallete,
  PaletteOptions as MuiPaletteOptions,
} from '@mui/material/styles/createPalette';

declare module '@mui/material/styles/createPalette' {
  // @ts-ignore
  interface Palette extends MuiPallete {
    tertiary: {
      main: string;
    };
    bgcolor: {
      main: string;
      secondary: string;
    };
    input: {
      after: string;
    };
    button: {
      contained: string;
    };
  }

  // @ts-ignore
  interface PaletteOptions extends MuiPaletteOptions {
    tertiary: {
      main: string;
    };
    bgcolor: {
      main: string;
      secondary: string;
    };
    input: {
      after: string;
    };
    button: {
      contained: string;
    };
  }
}
export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1424, // It is 1440px - 16px because Safari counts scrollbar which is 16px and content displays wrong
  xl: 1920,
};

const mainTheme = createTheme({
  breakpoints: {
    values: BREAKPOINTS,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#7986cb',
    },
    secondary: {
      main: '#00bcd4',
    },
    tertiary: {
      main: '#BD17D4',
    },
    bgcolor: {
      main: '#04020f',
      secondary: '#1A1831',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#c5e2cc',
    },
    input: {
      after: '#FFFFFF',
    },
    button: {
      contained: '#7986cb',
    },
  },
  shape: {
    borderRadius: 5,
  },

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#04020f',
          backgroundImage: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#04020f',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '5px',
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.01)',
          backgroundImage: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          backdropFilter: 'blur(3px)',
        }),
        paper: ({ theme }) => ({
          maxWidth: '1400px',
          overflowX: 'hidden',
          backdropFilter: 'blur(300px)',
          borderRadius: '16px',
          backgroundImage:
            'linear-gradient(356deg, rgba(16,5,37,1) 0%, rgba(19,19,48,1) 54%, rgba(21,36,34,1) 100%);',
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:after': {
            borderColor: theme.palette.input.after,
            borderWidth: '1px',
          },
          'input:-webkit-autofill': {
            transition: 'all 9999s ease-in-out 0s',
            'transition-property': 'background-color, color',
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: 'rgba(255,255,255,0.05)',
          backdropFilter: 'blur(15px)',
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          // borderRadius: '12px !important',
          // borderWidth: '1px',
          // borderColor: 'rgba(255, 255, 255, 0.30)',
          // backgroundColor: 'rgba(255, 255, 255, 0.05) !important',
          borderColor: 'rgba(255, 255, 255, 0.20) !important',
          '&:before': {
            borderColor: 'rgba(255, 255, 255, 0.20) !important',
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: '#FFFFFF',
          '&.Mui-focused': {
            color: '#FFFFFF',
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          whiteSpace: 'nowrap',
        }),
        contained: ({ theme }) => ({
          background: theme.palette.button.contained,
          [mainTheme.breakpoints.up('xs')]: {
            padding: '0.75rem 3.375rem',
          },
          [mainTheme.breakpoints.up('xl')]: {
            padding: '0.875rem 3rem',
          },
        }),
        outlined: () => ({
          borderColor: 'rgba(255, 255, 255, 0.20)',
          borderRadius: '12px',
          borderWidth: '1px !important',
          backgroundColor: 'rgba(255, 255, 255, 0.05) !important',
          [mainTheme.breakpoints.up('xs')]: {
            padding: '0.75rem 3.375rem',
          },
          [mainTheme.breakpoints.up('xl')]: {
            padding: '0.875rem 3rem',
          },
        }),
      },
    },
  },

  typography: {
    fontFamily: ['Exo 2', 'sans-serif'].join(','),
    button: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: '400',
      textTransform: 'none',
    },
  },
});

// Typography
mainTheme.typography.h1 = {
  fontSize: '2.5rem',
  lineHeight: '3rem',
  fontWeight: 500,

  [mainTheme.breakpoints.up('lg')]: {
    fontSize: '3.5rem',
    lineHeight: '4.5rem',
  },
  [mainTheme.breakpoints.up('xl')]: {
    fontSize: '4.5rem',
  },
};

mainTheme.typography.h2 = {
  fontSize: '2rem',
  lineHeight: '2.5rem',
  fontWeight: '500',

  [mainTheme.breakpoints.up('lg')]: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
  },
  [mainTheme.breakpoints.up('xl')]: {
    fontSize: '4rem',
    lineHeight: '4.5rem',
  },
};

mainTheme.typography.h3 = {
  fontSize: '1.875rem',
  lineHeight: '2.5rem',
  fontWeight: '500',

  [mainTheme.breakpoints.up('lg')]: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
  },
  [mainTheme.breakpoints.up('xl')]: {
    fontSize: '3rem',
    lineHeight: '4.5rem',
  },
};

mainTheme.typography.h4 = {
  fontSize: '1.5rem',
  lineHeight: '2rem',
  fontWeight: '500',

  [mainTheme.breakpoints.up('lg')]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  [mainTheme.breakpoints.up('xl')]: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
  },
};

mainTheme.typography.h5 = {
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: '500',

  [mainTheme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  [mainTheme.breakpoints.up('xl')]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
};

mainTheme.typography.subtitle1 = {
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  fontWeight: '600',

  [mainTheme.breakpoints.up('lg')]: {
    fontSize: '1.25rem',
  },
  [mainTheme.breakpoints.up('xl')]: {
    fontSize: '1.5rem',
  },
};

mainTheme.typography.subtitle2 = {
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: '600',
};

mainTheme.typography.body1 = {
  fontSize: '1rem',
  lineHeight: '1.5rem',
};

mainTheme.typography.body2 = {
  fontSize: '0.875rem',
  lineHeight: '1rem',
  fontWeight: 400,
};

mainTheme.typography.caption = {
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.125rem',

  [mainTheme.breakpoints.up('xl')]: {
    fontSize: '0.875rem',
  },
};

export { mainTheme as theme };
