import { complexityColors, complexityLabels } from '@pages/Product/BreakDowns/DnD';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import React from 'react';
import { Step } from '@proto/marketplace/product/v1/break_down_pb';

const Complexity = ({ step }: { step: Step }) => {
  return (
    <Tooltip
      title={`${
        complexityLabels[step.data?.complexity as keyof typeof complexityLabels]
      } complexity`}
    >
      <Box className="relative group w-[20px] h-[40px] justify-center flex gap-1 flex-col">
        {Array.from({ length: step.data?.complexity as number }).map((_, index) => (
          <Box>
            <Box
              key={_ as string}
              className={`w-full h-[1px] ${
                complexityColors[step.data?.complexity as keyof typeof complexityColors]
              } text-[#04020f] rounded-full `}
            />
          </Box>
        ))}
      </Box>
    </Tooltip>
  );
};

export default Complexity;
