import Box from '@mui/material/Box';
import React from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTagsList } from '@services/queries/TagsQueries';
import { Tag, TagType } from '@proto/profiler/tags/v1/tags_pb';

const SearchOrgByTags = ({
  handleClickTag,
  handleClearTags,
}: {
  handleClickTag: (id: number) => void;
  handleClearTags: () => void;
}) => {
  const { data: domains } = useTagsList({ tagType: TagType.INDUSTRY_DOMAIN, enabled: true });
  const [searchParams] = useSearchParams();
  const selectedTags = (searchParams.get('tags') || '').split(',').map((id) => Number(id));

  return (
    <Box className="relative">
      <Autocomplete
        multiple
        disabledItemsFocusable
        disableClearable
        disableCloseOnSelect
        value={domains?.tags?.tags?.filter((tag) => selectedTags.includes(tag.tagId)) || []}
        options={domains?.tags?.tags || []}
        getOptionLabel={(option: Tag) => option.value}
        isOptionEqualToValue={(option: Tag, value) => option.tagId === value.tagId}
        onChange={(e, data, reason, details) => {
          if (details?.option) {
            handleClickTag(details.option.tagId);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={option.value}
              {...getTagProps({ index })}
              variant={option.active ? 'outlined' : 'filled'}
            />
          ))
        }
        renderInput={(params) => (
          <TextField variant="standard" {...params} label="Industry domains" />
        )}
      />
    </Box>
  );
};

export default SearchOrgByTags;
