import { useParams } from 'react-router-dom';
import * as React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import SelectDemandStatus from '@pages/Marketplace/Dashboards/SelectDemandStatus';
import UpdateInterviewRequirements from '@pages/Marketplace/Dashboards/UpdateInterviewRequirements';
import UpdateDemandTraits from '@pages/Marketplace/Dashboards/UpdateDemandTraits';
import Tags from '@components/Tags/Tags';
import { useAllocateSlots, useGetDemand } from '@services/queries/MarketplaceQueries';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import ManageEvaluationsList from '@pages/Marketplace/Dashboards/ManageEvaluationsList';
import Button from '@mui/material/Button';
import TabContainer from '@components/TabContainer/TabContainer';
import ListInterviewers from '@pages/Marketplace/Dashboards/ListInterviewers';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import ListInvitedCandidates from '@pages/Marketplace/ManageDemands/Interviews/ListInvitedCandidates';

const tabsNav = ['Info', 'Evaluations', 'Candidates', 'Interviewers'];

const TabHeader = ({ demand }: { demand: Demand | undefined }) => {
  return demand ? (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box className="flex gap-4 items-center ">
        <Typography variant="subtitle2">{demand?.entity.case?.toUpperCase()}</Typography>
        <Box>
          {demand?.entity.value?.tags?.tags
            .filter((tag) => tag.tagType === TagType.ROLE)
            .map((tag) => {
              return (
                <Chip
                  size="small"
                  key={tag.tagId}
                  variant="outlined"
                  className="!font-bold"
                  label={tag.value}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  ) : null;
};
const ManageInterviewPage = () => {
  const { demandId, dashboardId } = useParams();
  const { data: demand } = useGetDemand({ demandId: demandId || '', type: 'interview' });
  const interview = demand?.entity?.value;
  const { mutate } = useAllocateSlots();

  const tabsContent = [
    <Box display="flex" flexDirection="column" gap={2}>
      {/* <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} /> */}
      <TabHeader demand={demand} />
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap={1}>
          {demand && <UpdateDemandTraits demand={demand} />}

          {interview?.tags?.tags && <Tags tags={interview.tags.tags} />}
          {demand && <UpdateInterviewRequirements demand={demand} />}
          <Box display="flex">
            <SelectDemandStatus status={demand?.status?.status?.toString() || ''} />
          </Box>
        </Box>
      </Box>
    </Box>,

    <Box display="flex" flexDirection="column" gap={2}>
      <TabHeader demand={demand} />
      {demand && <ManageEvaluationsList demand={demand} />}
      <Button onClick={() => mutate({ interviewId: demandId as string })}>Allocate</Button>
    </Box>,
    <Box display="flex" flexDirection="column" gap={2}>
      <TabHeader demand={demand} />
      {demandId && <ListInvitedCandidates objectName="Interview" entityId={demandId} />}
    </Box>,

    <Box display="flex" flexDirection="column" gap={2}>
      <TabHeader demand={demand} />
      {demand && <ListInterviewers demand={demand} />}
    </Box>,
  ];

  return <TabContainer tabsContent={tabsContent} tabsNav={tabsNav} />;
};

export default ManageInterviewPage;
