// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/profiler/v1/invite.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  InviteActionRequest,
  InviteActionResponse,
  ListInvitesRequest,
  ListInvitesResponse,
  ListUserInvitesRequest,
  ListUserInvitesResponse,
  SendInvitesRequest,
  SendInvitesResponse,
} from '../../../profiler/invite/v1/invite_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.profiler.v1.InviteAPI
 */
export const InviteAPI = {
  typeName: 'api.profiler.v1.InviteAPI',
  methods: {
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.SendInvites
     */
    sendInvites: {
      name: 'SendInvites',
      I: SendInvitesRequest,
      O: SendInvitesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.InviteAction
     */
    inviteAction: {
      name: 'InviteAction',
      I: InviteActionRequest,
      O: InviteActionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.ListUserInvites
     */
    listUserInvites: {
      name: 'ListUserInvites',
      I: ListUserInvitesRequest,
      O: ListUserInvitesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.ListInvites
     */
    listInvites: {
      name: 'ListInvites',
      I: ListInvitesRequest,
      O: ListInvitesResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
