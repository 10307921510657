import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ListSuppliers from '@pages/Marketplace/Dashboards/ListSuppliers';
import React from 'react';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';

const ListInterviewers = ({ demand }: { demand: Demand }) => {
  return (
    <Box display="flex" gap={1} flexDirection="column">
      <Box display="flex" flexDirection="column" gap={2}>
        {demand?.supply?.view?.value ? (
          <Box display="flex" gap={2} flexDirection="column">
            <ListSuppliers demandType="interview" supply={demand?.supply} />
          </Box>
        ) : (
          <Typography>No interviewers</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ListInterviewers;
