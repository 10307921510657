import { TCreateContractForm } from '@pages/Marketplace/Dashboards/CreateContracForm';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import { getTagType } from '@store/profiler/helpers';
import { TCreateInterviewForm } from '@pages/Marketplace/ManageDemands/settings';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Entity } from '@proto/grpc/type/v1/entity_pb';

export type TCreateProductForm = {
  title: string;
  description: string;
  owner?: Entity;
  version: string;
  demoUrl: string;
};

export type UpdateContractForm = {
  rate?: number | undefined;
  price?: number | undefined;
  capacity?: number | undefined;
  experience?: number;
  contractId?: string;
  interviewId?: string;
  paymentType?: 'fixed' | 'hourly';
  fixedRate?: number;
};

export type TDemandRecords = {
  contracts: { [contractId: string]: Demand };
  interviews: { [interviewId: string]: Demand };
};

export function convertDemands(demands: Demand[] | undefined): TDemandRecords {
  const customDemands: TDemandRecords = {
    contracts: {},
    interviews: {},
  };

  if (!demands) return customDemands;

  demands.forEach((demand) => {
    if (demand.entity.case === 'interview') {
      customDemands.interviews[demand.entity.value?.interviewId?.value as string] = demand;
    } else if (demand.entity.case === 'contract') {
      customDemands.contracts[(demand.entity.value as Contract)?.contractId?.value as string] =
        demand;
    }
  });

  return customDemands;
}

export const getTagsFromPayload = (
  tags: TCreateContractForm | TCreateInterviewForm | TCreateProductForm | any
) => {
  const tagsResult: Tag[] = [];
  Object.entries(tags).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.map((element) => {
        const attribute = new Tag({
          tagId: element.tagId,
          value: element.value,
          tagType: getTagType[key as keyof typeof getTagType],
          active: true,
        });
        return tagsResult.push(attribute);
      });
    }
  });
  return tagsResult;
};

export interface Section {
  [contractId: string]: Demand;
}
