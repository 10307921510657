import bigInt from 'big-integer';
import { Timestamp } from '@bufbuild/protobuf';
import { DateSpanApi } from '@fullcalendar/core';
import { Evaluation, EvaluationScheduleSlots } from '@proto/marketplace/demand/v1/evaluation_pb';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';

export interface CalendarEvent {
  title?: string;
  start: Date;
  end: Date;
  id?: string;
  color?: string;
  occuped?: boolean;
  disabled?: boolean;
  display?: 'background';
}

export const convertDateToTimeStamp = (dateString: string) => {
  const date = new Date(dateString);
  const seconds = bigInt(Math.floor(date.getTime() / 1000));
  const nanos = (date.getTime() % 1000) * 1000000;
  // @ts-ignore
  return new Timestamp({ seconds: seconds.value, nanos });
};

export const selectAllow = (selectInfo: DateSpanApi) => {
  const now = new Date();
  const start = new Date(now);
  const end = new Date(now);
  end.setDate(end.getDate() + 7);

  return selectInfo.start >= start && selectInfo.end <= end;
};

export function createOccupiedEvents(evaluations: Evaluation[]): CalendarEvent[] {
  return evaluations.reduce<CalendarEvent[]>((events, evaluation) => {
    const { scheduledAt } = evaluation?.status || {};

    if (!scheduledAt || !isInvalidTimestamp(scheduledAt.toString())) {
      return events;
    }

    const start = new Date(Number(scheduledAt.seconds) * 1000);
    const end = new Date(start.getTime() + 60 * 60 * 1000); // Adding 1 hour

    events.push({
      start,
      end,
      color: '#00a0b2',
      occuped: true,
      disabled: true,
    });

    return events;
  }, []);
}

export const convertAvailabilityToEvents = (
  availability: EvaluationScheduleSlots[] | undefined,
  occupedSlots: CalendarEvent[] = []
): CalendarEvent[] => {
  if (!availability) return occupedSlots;

  const availableEvents = availability.map((slot) => {
    if (!slot.from || !slot.to) {
      throw new Error("Slot 'from' and 'to' timestamps are required");
    }

    const startDate = new Date(Number(slot.from.seconds) * 1000);
    const endDate = new Date(Number(slot.to.seconds) * 1000);

    endDate.setHours(endDate.getHours() - 1);

    return {
      start: startDate,
      end: endDate,
      display: 'background',
      backgroundClassName: 'background-event',
    } as CalendarEvent;
  });

  return [...occupedSlots, ...availableEvents];
};
