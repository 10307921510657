import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { ListMembersResponse_Membership } from '@proto/iam/membership/v1/membership_pb';
import { Profile } from '@proto/profiler/user/v1/profile_pb';

export const getDemandId = (demand: Demand) => {
  if (demand?.entity?.case === 'interview') return demand.entity.value.interviewId;
  if (demand?.entity?.case === 'contract') return demand.entity.value.contractId;

  return undefined;
};

export function mapMembershipsById(memberships: ListMembersResponse_Membership[] | undefined) {
  if (!memberships) return {};

  return memberships.reduce((result, membership) => {
    const key = membership?.entity?.id?.value as string;
    result[key] = membership.members.map((member) => member.profile.value as Profile);
    return result;
  }, {} as Record<string, Profile[]>);
}
