import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import { InputController } from '@components/Form/controllers/InputController';
import { KanbanBoard } from '@proto/marketplace/dashboard/v1/dashboard_pb';
import { useUpdateDashboardInfo } from '@services/queries/MarketplaceQueries';
import Dialog from '@mui/material/Dialog';
import { FIELD_LABELS, FieldNames, getDefaultValues } from './settings';

type UpdateDashboardInfoFormProps = {
  dashboard: KanbanBoard;
  isOpen: boolean;
  handleClose: () => void;
};

const UpdateDashboardInfoForm = (props: UpdateDashboardInfoFormProps) => {
  const { dashboard, isOpen, handleClose } = props;
  const { mutate } = useUpdateDashboardInfo();

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Form
        width="100%"
        display="flex"
        alignSelf="center"
        flexDirection="column"
        formProps={{ defaultValues: getDefaultValues(dashboard) }}
        submitHandler={(data) => {
          mutate(data, { onSuccess: handleClose });
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          p={2}
          className="min-w-[400px]"
        >
          <InputController
            fullWidth
            name={FieldNames.TITLE}
            label={FIELD_LABELS[FieldNames.TITLE]}
            variant="standard"
          />
          <Tooltip placement="top-start" title="Edit dashboard title">
            <LoadingButton type="submit">
              <CheckIcon />
            </LoadingButton>
          </Tooltip>
        </Box>
      </Form>
    </Dialog>
  );
};
export default UpdateDashboardInfoForm;
