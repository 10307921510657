import { Step } from '@proto/marketplace/product/v1/break_down_pb';

export const isRateEmpty = (value: { rate: bigint; hours: number }) => {
  return value.rate === BigInt(0) && value.hours === 0;
};

export function sortStepsByPosition(steps: Step[]): Step[] {
  return [...steps].sort((a, b) => {
    const positionA = a.data?.position;
    const positionB = b.data?.position;

    if (positionA === undefined && positionB === undefined) return 0;
    if (positionA === undefined) return -1;
    if (positionB === undefined) return 1;

    return positionA - positionB;
  });
}
