import React, { SyntheticEvent } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Chip } from '@mui/material';
import { PlainMsg } from '@services/api/helpers';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';

export type TAutocompleteItem = {
  tagId: number;
  value: string;
  active?: any;
  tagType?: any;
};

interface IAutoCompleteProps {
  items: TAutocompleteItem[];
  defaultItems: TAutocompleteItem[];
  label: string;
  onSelect: (tag: PlainMsg<Tag>) => void;
  onRemove: (tag: PlainMsg<Tag>) => void;
  statusChanger?: (tag: TAutocompleteItem, active: boolean) => void;
  getItems: () => void;
}

const TagAutocomplete: React.FC<IAutoCompleteProps> = ({
  items,
  defaultItems,
  label,
  onSelect,
  onRemove,
  statusChanger,
  getItems,
}: IAutoCompleteProps) => {
  const handleChange = (e: SyntheticEvent, data: any, reason: string, details: any) => {
    return reason === 'selectOption' ? onSelect(details.option) : onRemove(details.option);
  };
  const handleOpen = () => {
    if (items?.length === 0) {
      getItems();
    }
  };
  const changeTagStatus = (tag: TAutocompleteItem, active: boolean) => {
    if (statusChanger) {
      statusChanger(tag, active);
    }
  };

  return (
    <Stack spacing={2} direction="column">
      <Autocomplete
        multiple
        onOpen={handleOpen}
        disabledItemsFocusable
        disableClearable
        options={items}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, _value) => option.tagId === _value.tagId}
        defaultValue={defaultItems}
        onChange={(e, data, reason, details) => {
          handleChange(e, data, reason, details);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            return (
              <Chip
                size="small"
                label={option.value}
                {...getTagProps({ index })}
                variant={option.active ? 'outlined' : 'filled'}
                onClick={() => {
                  option.active = !option.active;
                  changeTagStatus(option, option.active);
                }}
              />
            );
          })
        }
        renderInput={(params) => <TextField variant="standard" {...params} label={label} />}
      />
    </Stack>
  );
};
export default TagAutocomplete;
