import Joi from 'joi';
import {
  AddStepRequest,
  BreakDown,
  Step,
  Step_Complexity,
  Step_Data,
  Step_Fibonacci,
  UpdateStepRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';

export enum FieldNames {
  TITLE = 'title',
  DESCRIPTION = 'description',
  PRICE_CASE = 'priceCase',
  PRICE = 'price',
  HOURS = 'hours',
  FIBONACCI = 'fibonacci',
  COMPLEXITY = 'complexity',
  ROLES = 'roles',
  POSITION = 'position',
  TAG_ID = 'tagId',
}

export const getDefaultValuesAddStep = (step: Step | undefined) => ({
  [FieldNames.TITLE]: step?.data?.title || '',
  [FieldNames.DESCRIPTION]: step?.data?.description || '',
  [FieldNames.COMPLEXITY]: step?.data?.complexity || Step_Complexity.LOW,
  [FieldNames.FIBONACCI]: step?.data?.fibonacci || Step_Fibonacci.FIBONACCI_1,
  // [FieldNames.TAG_ID]: 0,
});

export const getDefaultValuesCreateBreakdown: () => {
  [FieldNames.ROLES]: Tag | undefined;
} = () => ({
  [FieldNames.ROLES]: undefined,
});

export const createBreakdownSchema = {
  [FieldNames.ROLES]: Joi.object().required().messages({
    'object.empty': 'select the role',
  }),
};

export const createStepSchema = {
  [FieldNames.TITLE]: Joi.string().required().min(2).max(128).messages({
    'string.empty': 'enter the name of the product',
    'string.min': 'product name must be at least 2 characters long',
    'string.max': 'product name must be less than or equal to 128 characters long',
  }),
  [FieldNames.DESCRIPTION]: Joi.string().required().min(2).max(512).messages({
    'string.empty': 'enter a description of the product',
    'string.min': 'the description of the product must be at least 2 characters long',
    'string.max':
      'the description of the product must be less than or equal to 512 characters long',
  }),
  [FieldNames.HOURS]: Joi.number().allow(),
  [FieldNames.FIBONACCI]: Joi.number().allow(),
  [FieldNames.COMPLEXITY]: Joi.number().required().valid(1, 2, 3).messages({
    'number.empty': 'select the complexity',
    'any.only': 'select the complexity',
  }),
  [FieldNames.ROLES]: Joi.array().allow(),
};

export type AddStepFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  productId?: UUID;
  step?: Step;
  breakDowns?: BreakDown;
  position?: number;
  tagId?: number;
};

export type CreateBreakdownormProps = {
  isOpen: boolean;
  handleClose: () => void;
  productId?: UUID;
};

export function createStepEstimate(isRate: boolean, price: number, hours: number) {}

// Функція для створення Step_Data
export function createStepData(title: string, description: string, position: number): Step_Data {
  return new Step_Data({
    title,
    description,
    position,
  });
}

export function buildAddStepRequest(priceCase: string, productId: UUID, data: any): AddStepRequest {
  const stepEstimate = createStepEstimate(
    priceCase === 'rate',
    Number(data.price),
    Number(data.hours)
  );

  const stepData = createStepData(data.title as string, data.description as string, 0);

  return new AddStepRequest({
    productId,
    data: stepData,
  });
}

export function buildUpdateStepRequest(
  stepId: UUID,
  data: any,
  isRate: boolean
): UpdateStepRequest {
  const stepData = new Step_Data({
    title: data.title as string,
    description: data.description as string,
    // estimate,
    // tags: new Tags({tags: data.roles}),
  });

  return new UpdateStepRequest({
    step: new Step({
      stepId,
      data: stepData,
    }),
  });
}
