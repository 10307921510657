import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { a11yProps, TabPanel } from '@components/TabPanel/TabPanel';
import Container from '@mui/material/Container';
import * as React from 'react';
import { ReactNode } from 'react';

const TabContainer = ({
  tabsNav,
  tabsContent,
}: {
  tabsNav: string[];
  tabsContent: ReactNode[];
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box display="flex">
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className="w-[12%]"
        >
          {tabsNav.map((item) => (
            <Tab className="!items-start !pr-6" label={item} {...a11yProps(0)} />
          ))}
        </Tabs>
        {tabsContent.map((item, index) => {
          return (
            <TabPanel value={value} index={index}>
              {item}
            </TabPanel>
          );
        })}
      </Box>
    </Container>
  );
};

export default TabContainer;
