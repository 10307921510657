import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract, Contract_Traits_Hourly } from '@proto/marketplace/demand/v1/contract_pb';

type DemandTraitsProps = {
  demand: Contract | Interview;
  fontSize?: string;
  matches?: any;
  flexDirection?: 'row' | 'column';
};

const DemandTraits = (props: DemandTraitsProps) => {
  const { demand, fontSize, matches, flexDirection } = props;
  return (
    <Box
      display="flex"
      className={`font-bold ${flexDirection === 'row' ? 'items-center' : ''}`}
      flexDirection={flexDirection || 'column'}
      gap={flexDirection ? 2 : undefined}
    >
      {(demand as Contract)?.traits?.payment?.case === 'fixed' ? (
        <Box
          display="flex"
          gap={0.5}
          alignItems="center"
          color={matches?.rate > 0 ? '#7986cb' : 'white'}
        >
          <InfoIcon sx={{ width: '14px', height: '14px' }} />
          <Typography fontSize={fontSize}>
            Fix ${Number((demand as Contract)?.traits?.payment?.value)}
          </Typography>
        </Box>
      ) : null}

      {(demand as Contract)?.traits?.payment?.case === 'hourly' &&
      Number((demand as Contract)?.traits?.payment?.value) !== 0 ? (
        <Box className="flex cursor-pointer   items-center   gap-2">
          <Typography fontSize={fontSize}>
            $
            {Number(
              ((demand as Contract)?.traits?.payment?.value as Contract_Traits_Hourly).rate
            ).toFixed(0)}
            /h
          </Typography>
          <Typography fontSize={fontSize}>
            {((demand as Contract)?.traits?.payment?.value as Contract_Traits_Hourly)?.capacity}{' '}
            capacity
          </Typography>
        </Box>
      ) : null}

      {(demand as Interview)?.traits?.price ? (
        <Box
          display="flex"
          gap={0.5}
          alignItems="center"
          color={matches?.price > 0 ? '#6FF9BF' : 'white'}
        >
          <Typography fontSize={fontSize}>
            Fix: ${Number((demand as Interview)?.traits?.price)}
          </Typography>
        </Box>
      ) : null}
      {flexDirection === 'row' && <div className="w-2 h-2 rounded-full bg-white/70" />}
      {demand?.traits?.experience ? (
        <Box
          display="flex"
          gap={0.5}
          alignItems="center"
          color={matches?.experience > 0 ? '#7986cb' : 'white'}
        >
          <Typography fontSize={fontSize}>
            {' '}
            {demand.traits.experience}+ experience years{' '}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};
export default DemandTraits;
