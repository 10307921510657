// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/demand.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { SupplyResponse } from './supply_pb.js';
import { RWX } from '../../../iam/auth/v1/iam_pb.js';
import { Interview } from './interview_pb.js';
import { Contract } from './contract_pb.js';
import { Profile, ProfileName, Traits } from '../../../profiler/user/v1/profile_pb.js';
import { Org } from '../../../profiler/biz/v1/org_pb.js';
import { Tags } from '../../../profiler/tags/v1/tags_pb.js';
import { Entities } from '../../../grpc/type/v1/entity_pb.js';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message marketplace.demand.v1.Demand
 */
export class Demand extends Message<Demand> {
  /**
   * @generated from field: marketplace.demand.v1.Demand.Status status = 1;
   */
  status?: Demand_Status;

  /**
   * @generated from field: marketplace.demand.v1.SupplyResponse supply = 2;
   */
  supply?: SupplyResponse;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 3;
   */
  rwx?: RWX;

  /**
   * @generated from oneof marketplace.demand.v1.Demand.entity
   */
  entity:
    | {
        /**
         * @generated from field: marketplace.demand.v1.Interview interview = 4;
         */
        value: Interview;
        case: 'interview';
      }
    | {
        /**
         * @generated from field: marketplace.demand.v1.Contract contract = 5;
         */
        value: Contract;
        case: 'contract';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Demand>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Demand';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'status', kind: 'message', T: Demand_Status },
    { no: 2, name: 'supply', kind: 'message', T: SupplyResponse },
    { no: 3, name: 'rwx', kind: 'message', T: RWX },
    { no: 4, name: 'interview', kind: 'message', T: Interview, oneof: 'entity' },
    { no: 5, name: 'contract', kind: 'message', T: Contract, oneof: 'entity' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Demand {
    return new Demand().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Demand {
    return new Demand().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Demand {
    return new Demand().fromJsonString(jsonString, options);
  }

  static equals(
    a: Demand | PlainMessage<Demand> | undefined,
    b: Demand | PlainMessage<Demand> | undefined
  ): boolean {
    return proto3.util.equals(Demand, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Demand.Status
 */
export class Demand_Status extends Message<Demand_Status> {
  /**
   * @generated from field: marketplace.demand.v1.Demand.Status.Status status = 1;
   */
  status = Demand_Status_Status.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp changed = 2;
   */
  changed?: Timestamp;

  constructor(data?: PartialMessage<Demand_Status>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Demand.Status';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'status', kind: 'enum', T: proto3.getEnumType(Demand_Status_Status) },
    { no: 2, name: 'changed', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Demand_Status {
    return new Demand_Status().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Demand_Status {
    return new Demand_Status().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Demand_Status {
    return new Demand_Status().fromJsonString(jsonString, options);
  }

  static equals(
    a: Demand_Status | PlainMessage<Demand_Status> | undefined,
    b: Demand_Status | PlainMessage<Demand_Status> | undefined
  ): boolean {
    return proto3.util.equals(Demand_Status, a, b);
  }
}

/**
 * @generated from enum marketplace.demand.v1.Demand.Status.Status
 */
export enum Demand_Status_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_DRAFT = 1;
   */
  DRAFT = 1,

  /**
   * @generated from enum value: STATUS_ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * @generated from enum value: STATUS_IN_PROGRESS = 3;
   */
  IN_PROGRESS = 3,

  /**
   * @generated from enum value: STATUS_DONE = 4;
   */
  DONE = 4,

  /**
   * @generated from enum value: STATUS_CANCELLED = 5;
   */
  CANCELLED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Demand_Status_Status)
proto3.util.setEnumType(Demand_Status_Status, 'marketplace.demand.v1.Demand.Status.Status', [
  { no: 0, name: 'STATUS_UNSPECIFIED' },
  { no: 1, name: 'STATUS_DRAFT' },
  { no: 2, name: 'STATUS_ACTIVE' },
  { no: 3, name: 'STATUS_IN_PROGRESS' },
  { no: 4, name: 'STATUS_DONE' },
  { no: 5, name: 'STATUS_CANCELLED' },
]);

/**
 * @generated from message marketplace.demand.v1.Demands
 */
export class Demands extends Message<Demands> {
  /**
   * @generated from field: repeated marketplace.demand.v1.Demand demands = 1;
   */
  demands: Demand[] = [];

  /**
   * @generated from field: marketplace.demand.v1.Recommendations recommendations = 2;
   */
  recommendations?: Recommendations;

  /**
   * @generated from field: optional marketplace.demand.v1.Demands.OwnerOfDemands owner = 3;
   */
  owner?: Demands_OwnerOfDemands;

  constructor(data?: PartialMessage<Demands>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Demands';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'demands', kind: 'message', T: Demand, repeated: true },
    { no: 2, name: 'recommendations', kind: 'message', T: Recommendations },
    { no: 3, name: 'owner', kind: 'message', T: Demands_OwnerOfDemands, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Demands {
    return new Demands().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Demands {
    return new Demands().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Demands {
    return new Demands().fromJsonString(jsonString, options);
  }

  static equals(
    a: Demands | PlainMessage<Demands> | undefined,
    b: Demands | PlainMessage<Demands> | undefined
  ): boolean {
    return proto3.util.equals(Demands, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Demands.OwnerOfDemands
 */
export class Demands_OwnerOfDemands extends Message<Demands_OwnerOfDemands> {
  /**
   * @generated from oneof marketplace.demand.v1.Demands.OwnerOfDemands.owner
   */
  owner:
    | {
        /**
         * @generated from field: profiler.user.v1.Profile profile = 1;
         */
        value: Profile;
        case: 'profile';
      }
    | {
        /**
         * @generated from field: profiler.biz.v1.Org org = 2;
         */
        value: Org;
        case: 'org';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Demands_OwnerOfDemands>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Demands.OwnerOfDemands';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile, oneof: 'owner' },
    { no: 2, name: 'org', kind: 'message', T: Org, oneof: 'owner' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): Demands_OwnerOfDemands {
    return new Demands_OwnerOfDemands().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): Demands_OwnerOfDemands {
    return new Demands_OwnerOfDemands().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): Demands_OwnerOfDemands {
    return new Demands_OwnerOfDemands().fromJsonString(jsonString, options);
  }

  static equals(
    a: Demands_OwnerOfDemands | PlainMessage<Demands_OwnerOfDemands> | undefined,
    b: Demands_OwnerOfDemands | PlainMessage<Demands_OwnerOfDemands> | undefined
  ): boolean {
    return proto3.util.equals(Demands_OwnerOfDemands, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Supplier
 */
export class Supplier extends Message<Supplier> {
  /**
   * @generated from field: marketplace.demand.v1.Supplier.Profile profile = 1;
   */
  profile?: Supplier_Profile;

  /**
   * @generated from field: profiler.tags.v1.Tags matched_tags = 2;
   */
  matchedTags?: Tags;

  /**
   * @generated from field: profiler.user.v1.Traits traits = 3;
   */
  traits?: Traits;

  /**
   * @generated from field: grpc.type.v1.Entities membership = 4;
   */
  membership?: Entities;

  constructor(data?: PartialMessage<Supplier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Supplier';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Supplier_Profile },
    { no: 2, name: 'matched_tags', kind: 'message', T: Tags },
    { no: 3, name: 'traits', kind: 'message', T: Traits },
    { no: 4, name: 'membership', kind: 'message', T: Entities },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Supplier {
    return new Supplier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Supplier {
    return new Supplier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Supplier {
    return new Supplier().fromJsonString(jsonString, options);
  }

  static equals(
    a: Supplier | PlainMessage<Supplier> | undefined,
    b: Supplier | PlainMessage<Supplier> | undefined
  ): boolean {
    return proto3.util.equals(Supplier, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Supplier.Profile
 */
export class Supplier_Profile extends Message<Supplier_Profile> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: string avatar = 2;
   */
  avatar = '';

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 3;
   */
  name?: ProfileName;

  constructor(data?: PartialMessage<Supplier_Profile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Supplier.Profile';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'avatar', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'name', kind: 'message', T: ProfileName },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Supplier_Profile {
    return new Supplier_Profile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Supplier_Profile {
    return new Supplier_Profile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Supplier_Profile {
    return new Supplier_Profile().fromJsonString(jsonString, options);
  }

  static equals(
    a: Supplier_Profile | PlainMessage<Supplier_Profile> | undefined,
    b: Supplier_Profile | PlainMessage<Supplier_Profile> | undefined
  ): boolean {
    return proto3.util.equals(Supplier_Profile, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Suppliers
 */
export class Suppliers extends Message<Suppliers> {
  /**
   * @generated from field: repeated marketplace.demand.v1.Supplier suppliers = 1;
   */
  suppliers: Supplier[] = [];

  /**
   * @generated from field: grpc.type.v1.UUIDS connected_recommendations = 2;
   */
  connectedRecommendations?: UUIDS;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  constructor(data?: PartialMessage<Suppliers>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Suppliers';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'suppliers', kind: 'message', T: Supplier, repeated: true },
    { no: 2, name: 'connected_recommendations', kind: 'message', T: UUIDS },
    { no: 3, name: 'created_at', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Suppliers {
    return new Suppliers().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Suppliers {
    return new Suppliers().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Suppliers {
    return new Suppliers().fromJsonString(jsonString, options);
  }

  static equals(
    a: Suppliers | PlainMessage<Suppliers> | undefined,
    b: Suppliers | PlainMessage<Suppliers> | undefined
  ): boolean {
    return proto3.util.equals(Suppliers, a, b);
  }
}

/**
 * map<recommendation_id, suppliers>
 *
 * @generated from message marketplace.demand.v1.Recommendation
 */
export class Recommendation extends Message<Recommendation> {
  /**
   * @generated from field: map<string, marketplace.demand.v1.Suppliers> recommendations = 1;
   */
  recommendations: { [key: string]: Suppliers } = {};

  constructor(data?: PartialMessage<Recommendation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Recommendation';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'recommendations',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Suppliers },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recommendation {
    return new Recommendation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recommendation {
    return new Recommendation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recommendation {
    return new Recommendation().fromJsonString(jsonString, options);
  }

  static equals(
    a: Recommendation | PlainMessage<Recommendation> | undefined,
    b: Recommendation | PlainMessage<Recommendation> | undefined
  ): boolean {
    return proto3.util.equals(Recommendation, a, b);
  }
}

/**
 * map<demand_id, Recommendation>
 *
 * @generated from message marketplace.demand.v1.Recommendations
 */
export class Recommendations extends Message<Recommendations> {
  /**
   * @generated from field: map<string, marketplace.demand.v1.Recommendation> recommendations = 1;
   */
  recommendations: { [key: string]: Recommendation } = {};

  constructor(data?: PartialMessage<Recommendations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Recommendations';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'recommendations',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Recommendation },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recommendations {
    return new Recommendations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recommendations {
    return new Recommendations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recommendations {
    return new Recommendations().fromJsonString(jsonString, options);
  }

  static equals(
    a: Recommendations | PlainMessage<Recommendations> | undefined,
    b: Recommendations | PlainMessage<Recommendations> | undefined
  ): boolean {
    return proto3.util.equals(Recommendations, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApproveRecommendationRequest
 */
export class ApproveRecommendationRequest extends Message<ApproveRecommendationRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID demand_id = 1;
   */
  demandId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUIDS supply_ids = 2;
   */
  supplyIds?: UUIDS;

  constructor(data?: PartialMessage<ApproveRecommendationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApproveRecommendationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'demand_id', kind: 'message', T: UUID },
    { no: 2, name: 'supply_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApproveRecommendationRequest {
    return new ApproveRecommendationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApproveRecommendationRequest {
    return new ApproveRecommendationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApproveRecommendationRequest {
    return new ApproveRecommendationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApproveRecommendationRequest | PlainMessage<ApproveRecommendationRequest> | undefined,
    b: ApproveRecommendationRequest | PlainMessage<ApproveRecommendationRequest> | undefined
  ): boolean {
    return proto3.util.equals(ApproveRecommendationRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApproveRecommendationResponse
 */
export class ApproveRecommendationResponse extends Message<ApproveRecommendationResponse> {
  constructor(data?: PartialMessage<ApproveRecommendationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApproveRecommendationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApproveRecommendationResponse {
    return new ApproveRecommendationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApproveRecommendationResponse {
    return new ApproveRecommendationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApproveRecommendationResponse {
    return new ApproveRecommendationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApproveRecommendationResponse | PlainMessage<ApproveRecommendationResponse> | undefined,
    b: ApproveRecommendationResponse | PlainMessage<ApproveRecommendationResponse> | undefined
  ): boolean {
    return proto3.util.equals(ApproveRecommendationResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetDemandRequest
 */
export class GetDemandRequest extends Message<GetDemandRequest> {
  /**
   * @generated from field: marketplace.demand.v1.DemandEntityId entity_id = 1;
   */
  entityId?: DemandEntityId;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<GetDemandRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetDemandRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'message', T: DemandEntityId },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDemandRequest {
    return new GetDemandRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDemandRequest {
    return new GetDemandRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDemandRequest {
    return new GetDemandRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetDemandRequest | PlainMessage<GetDemandRequest> | undefined,
    b: GetDemandRequest | PlainMessage<GetDemandRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetDemandRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DemandEntityId
 */
export class DemandEntityId extends Message<DemandEntityId> {
  /**
   * @generated from oneof marketplace.demand.v1.DemandEntityId.id
   */
  id:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID contract = 1;
         */
        value: UUID;
        case: 'contract';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUID interview = 2;
         */
        value: UUID;
        case: 'interview';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DemandEntityId>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DemandEntityId';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contract', kind: 'message', T: UUID, oneof: 'id' },
    { no: 2, name: 'interview', kind: 'message', T: UUID, oneof: 'id' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DemandEntityId {
    return new DemandEntityId().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DemandEntityId {
    return new DemandEntityId().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DemandEntityId {
    return new DemandEntityId().fromJsonString(jsonString, options);
  }

  static equals(
    a: DemandEntityId | PlainMessage<DemandEntityId> | undefined,
    b: DemandEntityId | PlainMessage<DemandEntityId> | undefined
  ): boolean {
    return proto3.util.equals(DemandEntityId, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetDemandResponse
 */
export class GetDemandResponse extends Message<GetDemandResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Demand demand = 1;
   */
  demand?: Demand;

  constructor(data?: PartialMessage<GetDemandResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetDemandResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'demand', kind: 'message', T: Demand },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDemandResponse {
    return new GetDemandResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDemandResponse {
    return new GetDemandResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDemandResponse {
    return new GetDemandResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetDemandResponse | PlainMessage<GetDemandResponse> | undefined,
    b: GetDemandResponse | PlainMessage<GetDemandResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetDemandResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SetDemandStatusRequest
 */
export class SetDemandStatusRequest extends Message<SetDemandStatusRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID demand_id = 1;
   */
  demandId?: UUID;

  /**
   * @generated from field: marketplace.demand.v1.Demand.Status.Status status = 2;
   */
  status = Demand_Status_Status.UNSPECIFIED;

  constructor(data?: PartialMessage<SetDemandStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SetDemandStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'demand_id', kind: 'message', T: UUID },
    { no: 2, name: 'status', kind: 'enum', T: proto3.getEnumType(Demand_Status_Status) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetDemandStatusRequest {
    return new SetDemandStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetDemandStatusRequest {
    return new SetDemandStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetDemandStatusRequest {
    return new SetDemandStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetDemandStatusRequest | PlainMessage<SetDemandStatusRequest> | undefined,
    b: SetDemandStatusRequest | PlainMessage<SetDemandStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetDemandStatusRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SetDemandStatusResponse
 */
export class SetDemandStatusResponse extends Message<SetDemandStatusResponse> {
  constructor(data?: PartialMessage<SetDemandStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SetDemandStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetDemandStatusResponse {
    return new SetDemandStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetDemandStatusResponse {
    return new SetDemandStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetDemandStatusResponse {
    return new SetDemandStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetDemandStatusResponse | PlainMessage<SetDemandStatusResponse> | undefined,
    b: SetDemandStatusResponse | PlainMessage<SetDemandStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetDemandStatusResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListAppliedDemandsRequest
 */
export class ListAppliedDemandsRequest extends Message<ListAppliedDemandsRequest> {
  constructor(data?: PartialMessage<ListAppliedDemandsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListAppliedDemandsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListAppliedDemandsRequest {
    return new ListAppliedDemandsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListAppliedDemandsRequest {
    return new ListAppliedDemandsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAppliedDemandsRequest {
    return new ListAppliedDemandsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAppliedDemandsRequest | PlainMessage<ListAppliedDemandsRequest> | undefined,
    b: ListAppliedDemandsRequest | PlainMessage<ListAppliedDemandsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListAppliedDemandsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListAppliedDemandsResponse
 */
export class ListAppliedDemandsResponse extends Message<ListAppliedDemandsResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Demands demands = 1;
   */
  demands?: Demands;

  constructor(data?: PartialMessage<ListAppliedDemandsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListAppliedDemandsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'demands', kind: 'message', T: Demands },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListAppliedDemandsResponse {
    return new ListAppliedDemandsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListAppliedDemandsResponse {
    return new ListAppliedDemandsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAppliedDemandsResponse {
    return new ListAppliedDemandsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAppliedDemandsResponse | PlainMessage<ListAppliedDemandsResponse> | undefined,
    b: ListAppliedDemandsResponse | PlainMessage<ListAppliedDemandsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListAppliedDemandsResponse, a, b);
  }
}
