import { Page } from '@components/Page/Page';
import React from 'react';
import SupplyList from '@pages/Marketplace/Dashboards/SupplyList';
import { DemandsList } from '@pages/Marketplace/Dashboards/DemandsList';
import { Chip, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import UpdateDashboardInfoForm from '@pages/Marketplace/ManageDemands/UpdateDashboardInfoForm';
import { useParams } from 'react-router-dom';
import { useGetDashboard } from '@services/queries/MarketplaceQueries';
import Container from '@mui/material/Container';
import { useDialog } from '@components/hooks/useDialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const displayContent = {
  demandsList: <DemandsList />,
  supplyList: <SupplyList />,
};

const KanbanBoard = () => {
  const { dashboardId } = useParams();
  const { data } = useGetDashboard(dashboardId as string);
  const [display, setDisplay] = React.useState<'demandsList' | 'supplyList'>('demandsList');
  const { isOpen, handleOpen, handleClose } = useDialog();

  return (
    <Page description="Kanbanboard" title="Kanbanboard">
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="subtitle1" flex="grow">
            {data?.kanbanBoard?.title || 'No title'}
          </Typography>
          <Box className="flex gap-4 items-center">
            <Box className="flex items-center" gap={1}>
              <Chip
                size="small"
                variant={display === 'demandsList' ? 'outlined' : 'filled'}
                onClick={() => setDisplay('demandsList')}
                label="Demands"
              />
              <Chip
                size="small"
                variant={display === 'supplyList' ? 'outlined' : 'filled'}
                onClick={() => setDisplay('supplyList')}
                label="Supplies"
              />
            </Box>

            <Tooltip onClick={handleOpen} placement="top-start" title="Edit dashboard title">
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {data?.kanbanBoard && (
          <UpdateDashboardInfoForm
            handleClose={handleClose}
            isOpen={isOpen}
            dashboard={data.kanbanBoard}
          />
        )}

        {displayContent[display]}
      </Container>
    </Page>
  );
};
export default KanbanBoard;
