import React, { useEffect } from 'react';
import { Form } from '@components/Form';
import { useFormContext } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import AutocompleteController, {
  TAutocompleteItem,
} from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import { FieldNames } from '@pages/Product/Steps/config';
import { useTags } from '@store/tags';
import { useUpdateBreakdownRole } from '@services/queries/StepsQueries';
import { UpdateBreakDownRoleRequest } from '@proto/marketplace/product/v1/break_down_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { useParams } from 'react-router-dom';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import { useGetProduct } from '@services/queries/ProductsQueries';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';

function filterTagsOptimized(
  roles: TAutocompleteItem[],
  filterRoles: Tag[] | undefined
): TAutocompleteItem[] {
  if (!filterRoles) {
    return roles;
  }
  const tagFilterIds = new Set(filterRoles.map((tag) => tag.tagId));
  return roles.filter((tag) => !tagFilterIds.has(tag.tagId));
}

export type UpdateBreakdownRoleFormProps = {
  roleId: number;
  dialogIsOpen: boolean;
};

const ControlledSubmitButton = ({
  initialRole,
}: {
  initialRole: TAutocompleteItem | undefined;
}) => {
  const { watch } = useFormContext();
  const selectedRole = watch(FieldNames.ROLES);

  const isDisabled = !selectedRole || selectedRole.tagId === initialRole?.tagId;

  return (
    <LoadingButton type="submit" disabled={isDisabled}>
      <SaveIcon />
    </LoadingButton>
  );
};

const UpdateBreakdownRoleForm = (props: UpdateBreakdownRoleFormProps) => {
  const { productId } = useParams();
  const { data: product } = useGetProduct(productId as string);
  const { roleId, dialogIsOpen } = props;
  const { roles, getRoles } = useTags();
  const { mutate: updateBreakdownRole } = useUpdateBreakdownRole();

  useEffect(() => {
    if (!roles.length && dialogIsOpen) {
      getRoles();
    }
  }, [getRoles, roles, dialogIsOpen]);

  const initialRole = roles.find((role) => role.tagId === roleId);

  return (
    <Form
      submitHandler={(data) => {
        updateBreakdownRole(
          new UpdateBreakDownRoleRequest({
            productId: new UUID({ value: productId }),
            oldRoleId: roleId,
            newRoleId: data.roles?.tagId,
          })
        );
      }}
      formProps={{
        defaultValues: {
          [FieldNames.ROLES]: initialRole,
        },
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" mb={1}>
          {' '}
          Role
        </Typography>
        <Box className="flex gap-2">
          <Box className="!w-full">
            <AutocompleteController
              multiple={false}
              getItems={getRoles}
              name={`${FieldNames.ROLES}`}
              label="Select the role"
              options={filterTagsOptimized(
                roles,
                product?.product?.breakDown?.roles?.map((breakdown) => breakdown.tag as Tag)
              )}
              className="!w-full"
            />
          </Box>
          <ControlledSubmitButton initialRole={initialRole} />
        </Box>
      </Stack>
    </Form>
  );
};

export default UpdateBreakdownRoleForm;
