import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { TagTypeEnum, TTag } from '@services/api/profiler/tags';
import { transformTagsToTagsByType } from '@utils/transformTagsToTagsByType';
import Card from '@components/Card/Card';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import { Link, useParams } from 'react-router-dom';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { useSetDemandstatus } from '@services/queries/MarketplaceQueries';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Demand_Status_Status } from '@proto/marketplace/demand/v1/demand_pb';

type KanbanItemProps = {
  demand: any;
};

const KanbanDemandCard = (props: KanbanItemProps) => {
  const { dashboardId } = useParams();
  const { demand } = props;
  const [tagByType, setTagByType] = useState<Array<[TagTypeEnum, TTag[]]>>([]);
  const { mutate } = useSetDemandstatus();

  useEffect(() => {
    setTagByType(transformTagsToTagsByType(demand?.entity?.value?.tags.tags || []));
  }, [demand?.entity?.value?.tags.tags]);

  const handleСlick = () => {
    if (demand?.status?.status >= 5) return;
    mutate({
      demandId:
        demand?.entity?.value?.contractId?.value || demand?.entity?.value?.interviewId?.value,
      status: Number(demand?.status?.status) + 1,
    });
  };

  return (
    <Box>
      <Link
        className="NavLink"
        to={`/${dashboardId}/${demand?.entity?.case}-manage/${
          demand.entity?.value.contractId?.value || demand.entity?.value.interviewId?.value
        }`}
      >
        <Card>
          <Box display="flex" gap={1} flexDirection="column">
            <Box
              sx={{ cursor: 'pointer' }}
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Typography fontSize="13px" fontWeight="bold">
                {demand?.entity?.case.toUpperCase()}
              </Typography>
              {tagByType
                // @ts-ignore
                .filter(([type]) => type === TagType.ROLE)
                .map(([type, tags]) => (
                  <Box display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                    {tags.map((tag) => (
                      <Chip
                        size="small"
                        key={tag?.value}
                        label={tag.alias || tag?.value}
                        variant="outlined"
                      />
                    ))}
                  </Box>
                ))}
            </Box>
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <DemandTraits fontSize="13px" demand={demand.entity?.value} />
              {demand?.status?.status === Demand_Status_Status.DRAFT && (
                <Box sx={{ cursor: 'pointer' }} onClick={handleСlick}>
                  <KeyboardDoubleArrowRightIcon />
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </Link>
    </Box>
  );
};

export default KanbanDemandCard;
