import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputController } from '@components/Form/controllers/InputController';
import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useGetMyProfile, useUupdateProfileTraits } from '@services/queries/ProfilesQueries';
import Typography from '@mui/material/Typography';
import InterviewerButton from '@pages/Main/SidebarProfileSettings/InterviewerButton';
import { FIELD_LABELS, FieldNames, getDefaultValues, myProfileTraitsFormSchema } from './config';

type ProfileTraitsFormProps = {
  isOnboarding?: () => void;
};

export const ProfileTraitsForm = (props: ProfileTraitsFormProps) => {
  const { isOnboarding } = props;
  const { mutate: updateTraits } = useUupdateProfileTraits();
  const { data: userProfile } = useGetMyProfile();

  const handleTraits = (traits: any) => {
    if (isOnboarding) {
      isOnboarding();
    }
    updateTraits(traits);
  };

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile?.profile?.traits) }}
      resolverSchema={myProfileTraitsFormSchema}
      submitHandler={handleTraits}
    >
      <Box className="flex flex-wrap gap-4 mb-6">
        <Box className="flex flex-col gap-2 ">
          <Typography className="font-bold" mb={1}>
            How much do you expect to earn per hour?
          </Typography>
          <InputController
            type="number"
            name={`traits.${FieldNames.RATE}`}
            label={FIELD_LABELS[FieldNames.RATE]}
          />
        </Box>
        <Box className="flex flex-col gap-2 ">
          <Typography className="font-bold" mb={1}>
            How much do you expect to earn per hour?
          </Typography>
          <InputController
            type="number"
            name={`traits.${FieldNames.CAPACITY}`}
            label={FIELD_LABELS[FieldNames.CAPACITY]}
          />
        </Box>
        <Box className="flex flex-col gap-2 ">
          <Typography className="font-bold" mb={1}>
            How much do you expect to earn per hour?
          </Typography>
          <InputController
            type="number"
            name={`traits.${FieldNames.EXPIRIENCE}`}
            label={FIELD_LABELS[FieldNames.EXPIRIENCE]}
          />
        </Box>
        <Box className="flex items-center gap-1 w-[40%]">
          <InterviewerButton />

          <Tooltip title="Performing job interviews and providing feedback on candidates is another way you can collaborate at Squady. You will receive invitations to be an interviewer for roles that match your expertise. ">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box display="flex" gap={1} justifyContent="flex-start">
        <LoadingButton type="submit" variant="contained">
          {isOnboarding ? 'Update traits' : 'Update traits'}
        </LoadingButton>
        {isOnboarding && (
          <Button onClick={isOnboarding} variant="outlined">
            Skip
          </Button>
        )}
      </Box>
    </Form>
  );
};
