// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file profiler/user/v1/tool_tips.proto (package profiler.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from enum profiler.user.v1.ToolTips
 */
export enum ToolTips {
  /**
   * @generated from enum value: TOOL_TIPS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TOOL_TIPS_PROFILE_SETTINGS = 1;
   */
  PROFILE_SETTINGS = 1,

  /**
   * @generated from enum value: TOOL_TIPS_MANAGE_DEMAND = 2;
   */
  MANAGE_DEMAND = 2,

  /**
   * @generated from enum value: TOOL_TIPS_MANAGE_SQUADS = 3;
   */
  MANAGE_SQUADS = 3,

  /**
   * @generated from enum value: TOOL_TIPS_EXPLORE_SQUADS = 4;
   */
  EXPLORE_SQUADS = 4,

  /**
   * @generated from enum value: TOOL_TIPS_SQUAD = 5;
   */
  SQUAD = 5,

  /**
   * @generated from enum value: TOOL_TIPS_MANAGE_ORG = 6;
   */
  MANAGE_ORG = 6,

  /**
   * @generated from enum value: TOOL_TIPS_EXPLORE_ORG = 7;
   */
  EXPLORE_ORG = 7,

  /**
   * @generated from enum value: TOOL_TIPS_ORG = 8;
   */
  ORG = 8,

  /**
   * @generated from enum value: TOOL_TIPS_DEMAND = 9;
   */
  DEMAND = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(ToolTips)
proto3.util.setEnumType(ToolTips, 'profiler.user.v1.ToolTips', [
  { no: 0, name: 'TOOL_TIPS_UNSPECIFIED' },
  { no: 1, name: 'TOOL_TIPS_PROFILE_SETTINGS' },
  { no: 2, name: 'TOOL_TIPS_MANAGE_DEMAND' },
  { no: 3, name: 'TOOL_TIPS_MANAGE_SQUADS' },
  { no: 4, name: 'TOOL_TIPS_EXPLORE_SQUADS' },
  { no: 5, name: 'TOOL_TIPS_SQUAD' },
  { no: 6, name: 'TOOL_TIPS_MANAGE_ORG' },
  { no: 7, name: 'TOOL_TIPS_EXPLORE_ORG' },
  { no: 8, name: 'TOOL_TIPS_ORG' },
  { no: 9, name: 'TOOL_TIPS_DEMAND' },
]);

/**
 * @generated from message profiler.user.v1.ListToolTipsRequest
 */
export class ListToolTipsRequest extends Message<ListToolTipsRequest> {
  constructor(data?: PartialMessage<ListToolTipsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListToolTipsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListToolTipsRequest {
    return new ListToolTipsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListToolTipsRequest {
    return new ListToolTipsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListToolTipsRequest {
    return new ListToolTipsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListToolTipsRequest | PlainMessage<ListToolTipsRequest> | undefined,
    b: ListToolTipsRequest | PlainMessage<ListToolTipsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListToolTipsRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListToolTipsResponse
 */
export class ListToolTipsResponse extends Message<ListToolTipsResponse> {
  /**
   * @generated from field: repeated profiler.user.v1.ToolTips tool_tips = 1;
   */
  toolTips: ToolTips[] = [];

  constructor(data?: PartialMessage<ListToolTipsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListToolTipsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tool_tips', kind: 'enum', T: proto3.getEnumType(ToolTips), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListToolTipsResponse {
    return new ListToolTipsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListToolTipsResponse {
    return new ListToolTipsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListToolTipsResponse {
    return new ListToolTipsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListToolTipsResponse | PlainMessage<ListToolTipsResponse> | undefined,
    b: ListToolTipsResponse | PlainMessage<ListToolTipsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListToolTipsResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.FinishToolTipsRequest
 */
export class FinishToolTipsRequest extends Message<FinishToolTipsRequest> {
  /**
   * @generated from field: profiler.user.v1.ToolTips tool_tips = 1;
   */
  toolTips = ToolTips.UNSPECIFIED;

  constructor(data?: PartialMessage<FinishToolTipsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.FinishToolTipsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tool_tips', kind: 'enum', T: proto3.getEnumType(ToolTips) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): FinishToolTipsRequest {
    return new FinishToolTipsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinishToolTipsRequest {
    return new FinishToolTipsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): FinishToolTipsRequest {
    return new FinishToolTipsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: FinishToolTipsRequest | PlainMessage<FinishToolTipsRequest> | undefined,
    b: FinishToolTipsRequest | PlainMessage<FinishToolTipsRequest> | undefined
  ): boolean {
    return proto3.util.equals(FinishToolTipsRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.FinishToolTipsResponse
 */
export class FinishToolTipsResponse extends Message<FinishToolTipsResponse> {
  constructor(data?: PartialMessage<FinishToolTipsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.FinishToolTipsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): FinishToolTipsResponse {
    return new FinishToolTipsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): FinishToolTipsResponse {
    return new FinishToolTipsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): FinishToolTipsResponse {
    return new FinishToolTipsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: FinishToolTipsResponse | PlainMessage<FinishToolTipsResponse> | undefined,
    b: FinishToolTipsResponse | PlainMessage<FinishToolTipsResponse> | undefined
  ): boolean {
    return proto3.util.equals(FinishToolTipsResponse, a, b);
  }
}
