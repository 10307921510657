// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file notificator/stream/v1/notifications.proto (package notificator.stream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { SubscribeRequest, SubscribeResponse } from './notifications_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service notificator.stream.v1.NotificationsService
 */
export const NotificationsService = {
  typeName: 'notificator.stream.v1.NotificationsService',
  methods: {
    /**
     * @generated from rpc notificator.stream.v1.NotificationsService.Subscribe
     */
    subscribe: {
      name: 'Subscribe',
      I: SubscribeRequest,
      O: SubscribeResponse,
      kind: MethodKind.ServerStreaming,
    },
  },
} as const;
