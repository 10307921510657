import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { PropsWithChildren } from 'react';

export type LayoutContainerProps = {
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
};

export const BackgroundBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme?.palette?.bgcolor?.main,
  // overflowX: 'hidden', // Added because GradientSinWave on Home page creates horizontal scroll, if it needs to be removed, should be taken into account
  zIndex: 0,
}));
export const LayoutContainer = ({
  children,
  tag = 'div',
  className,
}: PropsWithChildren<LayoutContainerProps>) => (
  <BackgroundBox component={tag} className={className}>
    <Container maxWidth="xl">{children}</Container>
  </BackgroundBox>
);
