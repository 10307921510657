import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Divider from '@mui/material/Divider';
import Tags from '@components/Tags/Tags';
import { useGetDemand } from '@services/queries/MarketplaceQueries';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import MDRenderer from '@components/Form/controllers/WysiwygController/MDRenderer';

const ContractPage = () => {
  const { contractId } = useParams();
  const { data: demand } = useGetDemand({ demandId: contractId || '', type: 'contract' });

  return (
    <Page description="Contract" title="Contract">
      <Container>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="subtitle1">Contract</Typography>
            {/* <Tooltip title="Created at"> */}
            {/*    <Box display="flex" gap={1} alignItems="center"> */}
            {/*        <EventIcon/> */}
            {/*        <Typography variant="subtitle2"> */}
            {/*            {demand?.createdAt && format(new Date(demand?.createdAt), 'dd/MM/yyyy')} */}
            {/*        </Typography> */}
            {/*    </Box> */}
            {/* </Tooltip> */}
          </Box>
          <Divider />
          {demand?.entity?.value?.tags?.tags && <Tags tags={demand?.entity.value.tags.tags} />}
          {demand?.entity.value && <DemandTraits demand={demand?.entity.value} />}
          {(demand?.entity?.value as Contract)?.description && (
            <MDRenderer content={(demand?.entity?.value as Contract)?.description} />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ContractPage;
