import React from 'react';
import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import { useUpdateBreakDown } from '@services/queries/StepsQueries';
import { useParams } from 'react-router-dom';
import {
  BreakDown_Rate,
  UpdateBreakDownRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';

export type UpdateBreakdownFormProps = {
  roleId: number;
  defaultValues?: {
    paymentType: 'fixed' | 'rate' | undefined;
    fixedRate: number;
    rate: number;
    hours: number;
  };
};

export const paymentOptions = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Rate', value: 'rate' },
];

type Payment = { case: 'fixed'; value: bigint } | { case: 'rate'; value: BreakDown_Rate };

export const PaymentFields = () => {
  const { watch } = useFormContext();
  const paymentType = watch('paymentType');

  return paymentType === 'fixed' ? (
    <InputController name="fixedRate" fullWidth label="Fixed Payment Rate" type="number" />
  ) : (
    <Box className="flex gap-2">
      <InputController fullWidth name="rate" label="Rate" type="number" />
      <InputController fullWidth name="hours" label="Hours" type="number" />
    </Box>
  );
};

const ControlledSubmitButton = ({
  defaultValues,
}: {
  defaultValues: UpdateBreakdownFormProps['defaultValues'];
}) => {
  const { watch } = useFormContext();
  const paymentType = watch('paymentType');
  const fixedRate = watch('fixedRate');
  const rate = watch('rate');
  const hours = watch('hours');

  const isDisabled =
    paymentType === defaultValues?.paymentType &&
    fixedRate === defaultValues?.fixedRate &&
    rate === defaultValues?.rate &&
    hours === defaultValues?.hours;

  return (
    <LoadingButton type="submit" disabled={isDisabled}>
      <SaveIcon />
    </LoadingButton>
  );
};

const UpdateBreakdownPriceForm = (props: UpdateBreakdownFormProps) => {
  const { productId } = useParams();
  const { roleId, defaultValues } = props;
  const { mutate: updateBreakdown } = useUpdateBreakDown();

  return (
    <Form
      submitHandler={(data) => {
        const payment: Payment =
          data.paymentType === 'fixed'
            ? { case: 'fixed', value: BigInt(data.fixedRate!) }
            : {
                case: 'rate',
                value: new BreakDown_Rate({
                  rate: BigInt(data.rate!),
                  hours: data.hours,
                }),
              };

        updateBreakdown(
          new UpdateBreakDownRequest({
            productId: new UUID({ value: productId }),
            roleId,
            price: payment,
          })
        );
      }}
      formProps={{
        defaultValues: {
          paymentType: defaultValues?.paymentType || 'fixed',
          fixedRate: defaultValues?.fixedRate || 0,
          rate: defaultValues?.rate || 0,
          hours: defaultValues?.hours || 0,
        },
      }}
    >
      <Typography variant="subtitle2" mb={2}>
        Price
      </Typography>
      <Box className="flex gap-2">
        <Box className="flex w-full  flex-col gap-4">
          <InputController select fullWidth label="Payment Type" name="paymentType">
            {paymentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </InputController>
          <PaymentFields />
        </Box>
        <Box className="flex h-[56px]">
          <ControlledSubmitButton defaultValues={defaultValues} />
        </Box>
      </Box>
    </Form>
  );
};

export default UpdateBreakdownPriceForm;
