// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file profiler/avatars/v1/avatar.proto (package profiler.avatars.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message profiler.avatars.v1.Avatar
 */
export class Avatar extends Message<Avatar> {
  /**
   * @generated from field: grpc.type.v1.UUID avatar_id = 1;
   */
  avatarId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 2;
   */
  entityId?: UUID;

  /**
   * @generated from field: string file_name = 3;
   */
  fileName = '';

  /**
   * @generated from field: google.protobuf.Timestamp uploaded = 4;
   */
  uploaded?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp activated = 5;
   */
  activated?: Timestamp;

  constructor(data?: PartialMessage<Avatar>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.Avatar';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'avatar_id', kind: 'message', T: UUID },
    { no: 2, name: 'entity_id', kind: 'message', T: UUID },
    { no: 3, name: 'file_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'uploaded', kind: 'message', T: Timestamp },
    { no: 5, name: 'activated', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Avatar {
    return new Avatar().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Avatar {
    return new Avatar().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Avatar {
    return new Avatar().fromJsonString(jsonString, options);
  }

  static equals(
    a: Avatar | PlainMessage<Avatar> | undefined,
    b: Avatar | PlainMessage<Avatar> | undefined
  ): boolean {
    return proto3.util.equals(Avatar, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.Avatars
 */
export class Avatars extends Message<Avatars> {
  /**
   * @generated from field: repeated profiler.avatars.v1.Avatar avatars = 1;
   */
  avatars: Avatar[] = [];

  constructor(data?: PartialMessage<Avatars>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.Avatars';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'avatars', kind: 'message', T: Avatar, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Avatars {
    return new Avatars().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Avatars {
    return new Avatars().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Avatars {
    return new Avatars().fromJsonString(jsonString, options);
  }

  static equals(
    a: Avatars | PlainMessage<Avatars> | undefined,
    b: Avatars | PlainMessage<Avatars> | undefined
  ): boolean {
    return proto3.util.equals(Avatars, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.DeleteAvatarRequest
 */
export class DeleteAvatarRequest extends Message<DeleteAvatarRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID avatar_id = 1;
   */
  avatarId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 2;
   */
  entityId?: UUID;

  constructor(data?: PartialMessage<DeleteAvatarRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.DeleteAvatarRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'avatar_id', kind: 'message', T: UUID },
    { no: 2, name: 'entity_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAvatarRequest {
    return new DeleteAvatarRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAvatarRequest {
    return new DeleteAvatarRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAvatarRequest {
    return new DeleteAvatarRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteAvatarRequest | PlainMessage<DeleteAvatarRequest> | undefined,
    b: DeleteAvatarRequest | PlainMessage<DeleteAvatarRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAvatarRequest, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.DeleteAvatarResponse
 */
export class DeleteAvatarResponse extends Message<DeleteAvatarResponse> {
  constructor(data?: PartialMessage<DeleteAvatarResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.DeleteAvatarResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAvatarResponse {
    return new DeleteAvatarResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAvatarResponse {
    return new DeleteAvatarResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAvatarResponse {
    return new DeleteAvatarResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteAvatarResponse | PlainMessage<DeleteAvatarResponse> | undefined,
    b: DeleteAvatarResponse | PlainMessage<DeleteAvatarResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAvatarResponse, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.GetAvatarUploadUrlRequest
 */
export class GetAvatarUploadUrlRequest extends Message<GetAvatarUploadUrlRequest> {
  /**
   * @generated from field: string file_name = 1;
   */
  fileName = '';

  /**
   * @generated from field: int64 content_length = 2;
   */
  contentLength = protoInt64.zero;

  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 3;
   */
  entityId?: UUID;

  constructor(data?: PartialMessage<GetAvatarUploadUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.GetAvatarUploadUrlRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'file_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'content_length', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: 'entity_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetAvatarUploadUrlRequest {
    return new GetAvatarUploadUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetAvatarUploadUrlRequest {
    return new GetAvatarUploadUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetAvatarUploadUrlRequest {
    return new GetAvatarUploadUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetAvatarUploadUrlRequest | PlainMessage<GetAvatarUploadUrlRequest> | undefined,
    b: GetAvatarUploadUrlRequest | PlainMessage<GetAvatarUploadUrlRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetAvatarUploadUrlRequest, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.GetAvatarUploadUrlResponse
 */
export class GetAvatarUploadUrlResponse extends Message<GetAvatarUploadUrlResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = '';

  constructor(data?: PartialMessage<GetAvatarUploadUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.GetAvatarUploadUrlResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetAvatarUploadUrlResponse {
    return new GetAvatarUploadUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetAvatarUploadUrlResponse {
    return new GetAvatarUploadUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetAvatarUploadUrlResponse {
    return new GetAvatarUploadUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetAvatarUploadUrlResponse | PlainMessage<GetAvatarUploadUrlResponse> | undefined,
    b: GetAvatarUploadUrlResponse | PlainMessage<GetAvatarUploadUrlResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetAvatarUploadUrlResponse, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.ListAvatarsRequest
 */
export class ListAvatarsRequest extends Message<ListAvatarsRequest> {
  /**
   * @generated from oneof profiler.avatars.v1.ListAvatarsRequest.option
   */
  option:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID entity_id = 1;
         */
        value: UUID;
        case: 'entityId';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS entity_ids = 2;
         */
        value: UUIDS;
        case: 'entityIds';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListAvatarsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.ListAvatarsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'message', T: UUID, oneof: 'option' },
    { no: 2, name: 'entity_ids', kind: 'message', T: UUIDS, oneof: 'option' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAvatarsRequest {
    return new ListAvatarsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAvatarsRequest {
    return new ListAvatarsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAvatarsRequest {
    return new ListAvatarsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAvatarsRequest | PlainMessage<ListAvatarsRequest> | undefined,
    b: ListAvatarsRequest | PlainMessage<ListAvatarsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListAvatarsRequest, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.ListAvatarsResponse
 */
export class ListAvatarsResponse extends Message<ListAvatarsResponse> {
  /**
   * @generated from field: profiler.avatars.v1.Avatars avatars = 1;
   */
  avatars?: Avatars;

  constructor(data?: PartialMessage<ListAvatarsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.ListAvatarsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'avatars', kind: 'message', T: Avatars },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAvatarsResponse {
    return new ListAvatarsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAvatarsResponse {
    return new ListAvatarsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAvatarsResponse {
    return new ListAvatarsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAvatarsResponse | PlainMessage<ListAvatarsResponse> | undefined,
    b: ListAvatarsResponse | PlainMessage<ListAvatarsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListAvatarsResponse, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.ActivateAvatarRequest
 */
export class ActivateAvatarRequest extends Message<ActivateAvatarRequest> {
  /**
   * @generated from oneof profiler.avatars.v1.ActivateAvatarRequest.option
   */
  option:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID avatar_id = 1;
         */
        value: UUID;
        case: 'avatarId';
      }
    | {
        /**
         * @generated from field: string url = 2;
         */
        value: string;
        case: 'url';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ActivateAvatarRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.ActivateAvatarRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'avatar_id', kind: 'message', T: UUID, oneof: 'option' },
    { no: 2, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'option' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ActivateAvatarRequest {
    return new ActivateAvatarRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateAvatarRequest {
    return new ActivateAvatarRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ActivateAvatarRequest {
    return new ActivateAvatarRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ActivateAvatarRequest | PlainMessage<ActivateAvatarRequest> | undefined,
    b: ActivateAvatarRequest | PlainMessage<ActivateAvatarRequest> | undefined
  ): boolean {
    return proto3.util.equals(ActivateAvatarRequest, a, b);
  }
}

/**
 * @generated from message profiler.avatars.v1.ActivateAvatarResponse
 */
export class ActivateAvatarResponse extends Message<ActivateAvatarResponse> {
  constructor(data?: PartialMessage<ActivateAvatarResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.avatars.v1.ActivateAvatarResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ActivateAvatarResponse {
    return new ActivateAvatarResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ActivateAvatarResponse {
    return new ActivateAvatarResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ActivateAvatarResponse {
    return new ActivateAvatarResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ActivateAvatarResponse | PlainMessage<ActivateAvatarResponse> | undefined,
    b: ActivateAvatarResponse | PlainMessage<ActivateAvatarResponse> | undefined
  ): boolean {
    return proto3.util.equals(ActivateAvatarResponse, a, b);
  }
}
