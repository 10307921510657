// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file profiler/invite/v1/invite.proto (package profiler.invite.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Strings } from '../../../grpc/type/v1/types_pb.js';
import { ProfileName } from '../../user/v1/profile_pb.js';

/**
 * @generated from enum profiler.invite.v1.InviteAction
 */
export enum InviteAction {
  /**
   * @generated from enum value: INVITE_ACTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INVITE_ACTION_ACCEPT = 1;
   */
  ACCEPT = 1,

  /**
   * @generated from enum value: INVITE_ACTION_REJECT = 2;
   */
  REJECT = 2,

  /**
   * @generated from enum value: INVITE_ACTION_CANCEL = 3;
   */
  CANCEL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(InviteAction)
proto3.util.setEnumType(InviteAction, 'profiler.invite.v1.InviteAction', [
  { no: 0, name: 'INVITE_ACTION_UNSPECIFIED' },
  { no: 1, name: 'INVITE_ACTION_ACCEPT' },
  { no: 2, name: 'INVITE_ACTION_REJECT' },
  { no: 3, name: 'INVITE_ACTION_CANCEL' },
]);

/**
 * @generated from message profiler.invite.v1.SendInvitesRequest
 */
export class SendInvitesRequest extends Message<SendInvitesRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from oneof profiler.invite.v1.SendInvitesRequest.recipients
   */
  recipients:
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS profiles = 2;
         */
        value: UUIDS;
        case: 'profiles';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.Strings emails = 3;
         */
        value: Strings;
        case: 'emails';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SendInvitesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.SendInvitesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'profiles', kind: 'message', T: UUIDS, oneof: 'recipients' },
    { no: 3, name: 'emails', kind: 'message', T: Strings, oneof: 'recipients' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendInvitesRequest {
    return new SendInvitesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendInvitesRequest {
    return new SendInvitesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendInvitesRequest {
    return new SendInvitesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendInvitesRequest | PlainMessage<SendInvitesRequest> | undefined,
    b: SendInvitesRequest | PlainMessage<SendInvitesRequest> | undefined
  ): boolean {
    return proto3.util.equals(SendInvitesRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.SendInvitesResponse
 */
export class SendInvitesResponse extends Message<SendInvitesResponse> {
  constructor(data?: PartialMessage<SendInvitesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.SendInvitesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendInvitesResponse {
    return new SendInvitesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendInvitesResponse {
    return new SendInvitesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendInvitesResponse {
    return new SendInvitesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendInvitesResponse | PlainMessage<SendInvitesResponse> | undefined,
    b: SendInvitesResponse | PlainMessage<SendInvitesResponse> | undefined
  ): boolean {
    return proto3.util.equals(SendInvitesResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.InviteActionRequest
 */
export class InviteActionRequest extends Message<InviteActionRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID invite_id = 1;
   */
  inviteId?: UUID;

  /**
   * @generated from field: profiler.invite.v1.InviteAction action = 2;
   */
  action = InviteAction.UNSPECIFIED;

  constructor(data?: PartialMessage<InviteActionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.InviteActionRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_id', kind: 'message', T: UUID },
    { no: 2, name: 'action', kind: 'enum', T: proto3.getEnumType(InviteAction) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteActionRequest {
    return new InviteActionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteActionRequest {
    return new InviteActionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): InviteActionRequest {
    return new InviteActionRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: InviteActionRequest | PlainMessage<InviteActionRequest> | undefined,
    b: InviteActionRequest | PlainMessage<InviteActionRequest> | undefined
  ): boolean {
    return proto3.util.equals(InviteActionRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.InviteActionResponse
 */
export class InviteActionResponse extends Message<InviteActionResponse> {
  constructor(data?: PartialMessage<InviteActionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.InviteActionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteActionResponse {
    return new InviteActionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteActionResponse {
    return new InviteActionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): InviteActionResponse {
    return new InviteActionResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: InviteActionResponse | PlainMessage<InviteActionResponse> | undefined,
    b: InviteActionResponse | PlainMessage<InviteActionResponse> | undefined
  ): boolean {
    return proto3.util.equals(InviteActionResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ObjectInvite
 */
export class ObjectInvite extends Message<ObjectInvite> {
  /**
   * @generated from field: grpc.type.v1.UUID invite_id = 1;
   */
  inviteId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 2;
   */
  profileId?: UUID;

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 3;
   */
  name?: ProfileName;

  /**
   * @generated from field: google.protobuf.Timestamp invited_at = 4;
   */
  invitedAt?: Timestamp;

  constructor(data?: PartialMessage<ObjectInvite>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ObjectInvite';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_id', kind: 'message', T: UUID },
    { no: 2, name: 'profile_id', kind: 'message', T: UUID },
    { no: 3, name: 'name', kind: 'message', T: ProfileName },
    { no: 4, name: 'invited_at', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ObjectInvite {
    return new ObjectInvite().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ObjectInvite {
    return new ObjectInvite().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ObjectInvite {
    return new ObjectInvite().fromJsonString(jsonString, options);
  }

  static equals(
    a: ObjectInvite | PlainMessage<ObjectInvite> | undefined,
    b: ObjectInvite | PlainMessage<ObjectInvite> | undefined
  ): boolean {
    return proto3.util.equals(ObjectInvite, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.UserInvite
 */
export class UserInvite extends Message<UserInvite> {
  /**
   * @generated from field: grpc.type.v1.UUID invite_id = 1;
   */
  inviteId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp invited_at = 2;
   */
  invitedAt?: Timestamp;

  /**
   * @generated from field: grpc.type.v1.Entity entity = 3;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<UserInvite>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.UserInvite';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_id', kind: 'message', T: UUID },
    { no: 2, name: 'invited_at', kind: 'message', T: Timestamp },
    { no: 3, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInvite {
    return new UserInvite().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInvite {
    return new UserInvite().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInvite {
    return new UserInvite().fromJsonString(jsonString, options);
  }

  static equals(
    a: UserInvite | PlainMessage<UserInvite> | undefined,
    b: UserInvite | PlainMessage<UserInvite> | undefined
  ): boolean {
    return proto3.util.equals(UserInvite, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListInvitesRequest
 */
export class ListInvitesRequest extends Message<ListInvitesRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<ListInvitesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListInvitesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvitesRequest {
    return new ListInvitesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvitesRequest {
    return new ListInvitesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInvitesRequest {
    return new ListInvitesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInvitesRequest | PlainMessage<ListInvitesRequest> | undefined,
    b: ListInvitesRequest | PlainMessage<ListInvitesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListInvitesRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListInvitesResponse
 */
export class ListInvitesResponse extends Message<ListInvitesResponse> {
  /**
   * @generated from field: repeated profiler.invite.v1.ObjectInvite invites = 1;
   */
  invites: ObjectInvite[] = [];

  constructor(data?: PartialMessage<ListInvitesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListInvitesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invites', kind: 'message', T: ObjectInvite, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvitesResponse {
    return new ListInvitesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvitesResponse {
    return new ListInvitesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse {
    return new ListInvitesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInvitesResponse | PlainMessage<ListInvitesResponse> | undefined,
    b: ListInvitesResponse | PlainMessage<ListInvitesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListInvitesResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListUserInvitesRequest
 */
export class ListUserInvitesRequest extends Message<ListUserInvitesRequest> {
  constructor(data?: PartialMessage<ListUserInvitesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListUserInvitesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUserInvitesRequest {
    return new ListUserInvitesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesRequest {
    return new ListUserInvitesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesRequest {
    return new ListUserInvitesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUserInvitesRequest | PlainMessage<ListUserInvitesRequest> | undefined,
    b: ListUserInvitesRequest | PlainMessage<ListUserInvitesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListUserInvitesRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListUserInvitesResponse
 */
export class ListUserInvitesResponse extends Message<ListUserInvitesResponse> {
  /**
   * @generated from field: repeated profiler.invite.v1.UserInvite invites = 1;
   */
  invites: UserInvite[] = [];

  constructor(data?: PartialMessage<ListUserInvitesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListUserInvitesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invites', kind: 'message', T: UserInvite, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUserInvitesResponse {
    return new ListUserInvitesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesResponse {
    return new ListUserInvitesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesResponse {
    return new ListUserInvitesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUserInvitesResponse | PlainMessage<ListUserInvitesResponse> | undefined,
    b: ListUserInvitesResponse | PlainMessage<ListUserInvitesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListUserInvitesResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.AcceptEmailInvitationRequest
 */
export class AcceptEmailInvitationRequest extends Message<AcceptEmailInvitationRequest> {
  /**
   * @generated from field: string otp = 1;
   */
  otp = '';

  /**
   * @generated from field: string email = 2;
   */
  email = '';

  constructor(data?: PartialMessage<AcceptEmailInvitationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.AcceptEmailInvitationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'otp', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AcceptEmailInvitationRequest {
    return new AcceptEmailInvitationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AcceptEmailInvitationRequest {
    return new AcceptEmailInvitationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptEmailInvitationRequest {
    return new AcceptEmailInvitationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AcceptEmailInvitationRequest | PlainMessage<AcceptEmailInvitationRequest> | undefined,
    b: AcceptEmailInvitationRequest | PlainMessage<AcceptEmailInvitationRequest> | undefined
  ): boolean {
    return proto3.util.equals(AcceptEmailInvitationRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.AcceptEmailInvitationResponse
 */
export class AcceptEmailInvitationResponse extends Message<AcceptEmailInvitationResponse> {
  /**
   * @generated from field: grpc.type.v1.Entity invitation_target = 1;
   */
  invitationTarget?: Entity;

  constructor(data?: PartialMessage<AcceptEmailInvitationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.AcceptEmailInvitationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invitation_target', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AcceptEmailInvitationResponse {
    return new AcceptEmailInvitationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AcceptEmailInvitationResponse {
    return new AcceptEmailInvitationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptEmailInvitationResponse {
    return new AcceptEmailInvitationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AcceptEmailInvitationResponse | PlainMessage<AcceptEmailInvitationResponse> | undefined,
    b: AcceptEmailInvitationResponse | PlainMessage<AcceptEmailInvitationResponse> | undefined
  ): boolean {
    return proto3.util.equals(AcceptEmailInvitationResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.DeleteEmailInvitationRequest
 */
export class DeleteEmailInvitationRequest extends Message<DeleteEmailInvitationRequest> {
  /**
   * @generated from field: string otp = 1;
   */
  otp = '';

  /**
   * @generated from field: string email = 2;
   */
  email = '';

  constructor(data?: PartialMessage<DeleteEmailInvitationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.DeleteEmailInvitationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'otp', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEmailInvitationRequest {
    return new DeleteEmailInvitationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteEmailInvitationRequest {
    return new DeleteEmailInvitationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEmailInvitationRequest {
    return new DeleteEmailInvitationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteEmailInvitationRequest | PlainMessage<DeleteEmailInvitationRequest> | undefined,
    b: DeleteEmailInvitationRequest | PlainMessage<DeleteEmailInvitationRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteEmailInvitationRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.DeleteEmailInvitationResponse
 */
export class DeleteEmailInvitationResponse extends Message<DeleteEmailInvitationResponse> {
  constructor(data?: PartialMessage<DeleteEmailInvitationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.DeleteEmailInvitationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEmailInvitationResponse {
    return new DeleteEmailInvitationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteEmailInvitationResponse {
    return new DeleteEmailInvitationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEmailInvitationResponse {
    return new DeleteEmailInvitationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteEmailInvitationResponse | PlainMessage<DeleteEmailInvitationResponse> | undefined,
    b: DeleteEmailInvitationResponse | PlainMessage<DeleteEmailInvitationResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteEmailInvitationResponse, a, b);
  }
}
