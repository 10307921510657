// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/profiler/v1/pin.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  PinItemRequest,
  PinItemResponse,
  UnpinItemRequest,
  UnpinItemResponse,
} from '../../../profiler/user/v1/pin_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.profiler.v1.PinAPI
 */
export const PinAPI = {
  typeName: 'api.profiler.v1.PinAPI',
  methods: {
    /**
     * @generated from rpc api.profiler.v1.PinAPI.PinItem
     */
    pinItem: {
      name: 'PinItem',
      I: PinItemRequest,
      O: PinItemResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.PinAPI.UnpinItem
     */
    unpinItem: {
      name: 'UnpinItem',
      I: UnpinItemRequest,
      O: UnpinItemResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
