// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/iam/v1/auth.proto (package api.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { PreSignRequest, PreSignResponse, SignInRequest } from '../../../iam/auth/v1/auth_pb.js';
import { MethodKind } from '@bufbuild/protobuf';
import {
  RefreshTokensRequest,
  RefreshTokensResponse,
  SignInResponse,
  SignOutRequest,
  SignOutResponse,
  ValidateTokenRequest,
  ValidateTokenResponse,
} from './auth_pb.js';

/**
 * @generated from service api.iam.v1.AuthAPI
 */
export const AuthAPI = {
  typeName: 'api.iam.v1.AuthAPI',
  methods: {
    /**
     * @generated from rpc api.iam.v1.AuthAPI.PreSign
     */
    preSign: {
      name: 'PreSign',
      I: PreSignRequest,
      O: PreSignResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.AuthAPI.SignIn
     */
    signIn: {
      name: 'SignIn',
      I: SignInRequest,
      O: SignInResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.AuthAPI.SignOut
     */
    signOut: {
      name: 'SignOut',
      I: SignOutRequest,
      O: SignOutResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.AuthAPI.RefreshTokens
     */
    refreshTokens: {
      name: 'RefreshTokens',
      I: RefreshTokensRequest,
      O: RefreshTokensResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.AuthAPI.ValidateToken
     */
    validateToken: {
      name: 'ValidateToken',
      I: ValidateTokenRequest,
      O: ValidateTokenResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
