import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CreateDashboardButton from '@pages/Marketplace/CreateDashboardButton';
import { useListDashboards } from '@services/queries/MarketplaceQueries';

const ManageDashboardsList = () => {
  const { data, isLoading, error } = useListDashboards();

  if (isLoading) <CircularProgress />;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography className="font-bold">Dashboards</Typography>

      {!data?.dashboards?.dashboards.length ? (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            One place to track all your active contracts, applications, and interviews.
          </Typography>
          <CreateDashboardButton />
        </Box>
      ) : (
        <Box>
          {data.dashboards.dashboards.map((dashboard) => (
            <Link className="NavLink" to={`/${dashboard?.dashboardId?.value}`}>
              <Typography fontSize="13px" className="hover:underline">
                {dashboard.title}
              </Typography>
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ManageDashboardsList;
