// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/demand.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetDemandRequest } from './demand_pb.js';
import {
  GetDemandResponse,
  ListAppliedDemandsRequest,
  ListAppliedDemandsResponse,
  SetDemandStatusRequest,
  SetDemandStatusResponse,
} from '../../../marketplace/demand/v1/demand_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.DemandAPI
 */
export const DemandAPI = {
  typeName: 'api.marketplace.v1.DemandAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.DemandAPI.GetDemand
     */
    getDemand: {
      name: 'GetDemand',
      I: GetDemandRequest,
      O: GetDemandResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.DemandAPI.SetDemandStatus
     */
    setDemandStatus: {
      name: 'SetDemandStatus',
      I: SetDemandStatusRequest,
      O: SetDemandStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.DemandAPI.ListAppliedDemands
     */
    listAppliedDemands: {
      name: 'ListAppliedDemands',
      I: ListAppliedDemandsRequest,
      O: ListAppliedDemandsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
