import { getConnectClient } from '@services/api/helpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Tag, Tags, TagType } from '@proto/profiler/tags/v1/tags_pb';
import { getTagsFromPayload, TCreateProductForm } from '@store/marketplace/helpers';
import { showSnackbar } from '@store/snackbars';
import { useAppDispatch } from '@store/helpers';
import { ProductAPI } from '@proto/api/marketplace/v1/product_connect';
import {
  CreateProductRequest,
  CreateProductResponse,
  DeleteProductRequest,
  DeleteProductResponse,
  GetProductRequest,
  SearchProductsRequest,
  SetProductStatusRequest,
  SetProductStatusResponse,
  UpdateProductRequest,
  UpdateProductResponse,
} from '@proto/marketplace/product/v1/product_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { ListProductsRequest } from '@proto/api/marketplace/v1/product_pb';
import { useEffect, useState } from 'react';
import { useMarketplace } from '../../zustand/marketplace';

const productClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ProductAPI
);

export const useCreateProduct = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const createProduct = async (payload: TCreateProductForm): Promise<CreateProductResponse> => {
    return productClient.createProduct(
      new CreateProductRequest({
        owner: payload.owner,
        title: payload.title,
        description: payload.description,
        version: payload.version,
        demoUrl: payload.demoUrl,
        tags: new Tags({
          tags: getTagsFromPayload(payload),
        }),
      })
    );
  };

  return useMutation({
    mutationFn: createProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
      dispatch(
        showSnackbar({ id: 'create-product', message: 'Product created', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'create-product', message: error.message, severity: 'error' }));
    },
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  const updateProduct = async (payload: UpdateProductRequest): Promise<UpdateProductResponse> => {
    return productClient.updateProduct(payload);
  };

  return useMutation({
    mutationFn: updateProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
    },
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  const deleteProduct = async (payload: DeleteProductRequest): Promise<DeleteProductResponse> => {
    return productClient.deleteProduct(payload);
  };

  return useMutation({
    mutationFn: deleteProduct,
    onSuccess: () => {
      // queryClient.invalidateQueries({queryKey: ['listDashboards']});
    },
  });
};

export const useGetProduct = (productId: string) => {
  const getProduct = async () => {
    return productClient.getProduct(
      new GetProductRequest({ productId: new UUID({ value: productId }) })
    );
  };

  return useQuery({
    queryKey: ['getProduct', productId],
    queryFn: getProduct,
  });
};

export const useSetProductStatus = () => {
  const setProductStatus = async (
    payload: SetProductStatusRequest
  ): Promise<SetProductStatusResponse> => {
    return productClient.setProductStatus(payload);
  };

  return useMutation({
    mutationFn: setProductStatus,
    onSuccess: () => {
      // queryClient.invalidateQueries({queryKey: ['listDashboards']});
    },
  });
};

export const useSearchProducts = () => {
  const { searchText, searchTags } = useMarketplace();
  const [debouncedText, setDebouncedText] = useState(searchText);
  const [debouncedTags, setDebouncedTags] = useState(searchTags);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedText(searchText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTags(searchTags);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTags]);

  const searchProducts = async () => {
    const tagTypetoEnumValue = {
      TAG_TYPE_ROLE: TagType.ROLE,
      TAG_TYPE_PROGRAMMING_LANGUAGE: TagType.PROGRAMMING_LANGUAGE,
    };

    return productClient.searchProducts(
      new SearchProductsRequest({
        text: debouncedText,
        tags: new Tags({
          tags:
            debouncedTags?.map(
              (tag) =>
                new Tag({
                  tagId: tag.tagId,
                  value: tag.value,
                  tagType:
                    tagTypetoEnumValue[tag.tagType as unknown as keyof typeof tagTypetoEnumValue],
                })
            ) || [],
        }),
      })
    );
  };

  const isEnable = !!debouncedText || !!debouncedTags?.length;

  return useQuery({
    queryKey: ['searchProducts', debouncedText, debouncedTags],
    queryFn: searchProducts,
    enabled: isEnable,
  });
};

export const useListProducts = (payload: ListProductsRequest) => {
  const listProducts = async () => {
    return productClient.listProducts(payload);
  };

  return useQuery({
    queryKey: ['listProducts', payload],
    queryFn: listProducts,
  });
};
