import * as React from 'react';
import { groupTagsByType, TagTypeLabels } from '@utils/transformTagsToTagsByType';
import Box from '@mui/material/Box';
import { Chip, Typography } from '@mui/material';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';

type TagsProps = {
  tags: Tag[];
  showType?: boolean;
  matchedTags?: any;
};

const Tags = (props: TagsProps) => {
  const { tags, showType, matchedTags } = props;

  const transformedTags = groupTagsByType(tags);

  return (
    <Box display="flex" flexDirection={!showType ? 'column' : 'row'} flexWrap="wrap" gap={1}>
      {transformedTags &&
        Object.entries(transformedTags)?.map(([type, _tags]) => {
          if (!_tags?.length) return null;
          return !showType ? (
            <Box display="flex" alignItems="center" gap={1} key={type}>
              <Typography>{TagTypeLabels[Number(type) as keyof typeof TagTypeLabels]}:</Typography>
              <Box display="flex" gap={1}>
                {_tags?.map((tag) => (
                  <Chip
                    size="small"
                    color={
                      matchedTags &&
                      matchedTags?.find((_tag: any) => {
                        return _tag.value === tag.value;
                      })
                        ? 'primary'
                        : 'default'
                    }
                    key={tag.value}
                    label={tag.value}
                    sx={{ fontSize: '12px' }}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box display="flex" gap={1} flexWrap="wrap" key={type}>
              {_tags?.map((tag) => (
                <Chip
                  size="small"
                  color={
                    matchedTags &&
                    matchedTags?.find((_tag: any) => {
                      return _tag.value === tag.value;
                    })
                      ? 'primary'
                      : 'default'
                  }
                  key={tag.tagId}
                  label={tag.value}
                  sx={{ fontSize: '12px' }}
                  variant="outlined"
                />
              ))}
            </Box>
          );
        })}
    </Box>
  );
};

export default Tags;
