// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/profiler/v1/tool_tips.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  FinishToolTipsRequest,
  FinishToolTipsResponse,
  ListToolTipsRequest,
  ListToolTipsResponse,
} from '../../../profiler/user/v1/tool_tips_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.profiler.v1.ToolTipsAPI
 */
export const ToolTipsAPI = {
  typeName: 'api.profiler.v1.ToolTipsAPI',
  methods: {
    /**
     * @generated from rpc api.profiler.v1.ToolTipsAPI.ListToolTips
     */
    listToolTips: {
      name: 'ListToolTips',
      I: ListToolTipsRequest,
      O: ListToolTipsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ToolTipsAPI.FinishToolTips
     */
    finishToolTips: {
      name: 'FinishToolTips',
      I: FinishToolTipsRequest,
      O: FinishToolTipsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
