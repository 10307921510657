import { Chip, Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';
import CustomAvatar from '@components/Avatar/Avatar';
import { Squad } from '@proto/profiler/biz/v1/squad_pb';
import { Tags } from '@proto/profiler/tags/v1/tags_pb';
import { Profiles } from '@proto/profiler/user/v1/profile_pb';

type TExploreSquadCardProps = {
  squad: Squad;
};

const ExploreSquadCard = (props: TExploreSquadCardProps) => {
  const { squad } = props;
  const link = `${ROUTES_PREFIXES.SQUADS}/${squad.name}`;

  return (
    <Box className="rounded-xl  py-4 px-2 border-white/10 bg-white/5 flex flex-col gap-2 hover:bg-white/10 transition">
      <Link className="NavLink" to={link}>
        <Box className="flex items-center gap-2">
          <CustomAvatar
            alt={squad?.name || 'Squad'}
            avatarId={squad?.avatar}
            profileId={squad?.squadId?.value}
            height={30}
            width={30}
          />
          <Typography fontWeight="bold">{squad.name}</Typography>
        </Box>
      </Link>
      <Box display="flex" flexDirection="column" gap={1}>
        <Link className="NavLink" to={link}>
          <Typography sx={{ wordBreak: 'break-all' }} className="text-white/80 text-[14px]">
            {squad.about}
          </Typography>
        </Link>

        <Box display="flex" flexWrap="wrap" gap={1}>
          {(squad?.members?.value as Tags)?.tags?.map((tag) => {
            return <Chip size="small" variant="outlined" label={tag.value} />;
          })}
          {(squad?.members?.value as Profiles)?.profiles && (
            <Box>
              <Typography>
                Mates:
                <span style={{ fontWeight: 'bold' }}>
                  {(squad?.members?.value as Profiles)?.profiles?.length}
                </span>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreSquadCard;
