// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/order.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateProductOrderRequest,
  CreateProductOrderResponse,
  DeleteProductOrderRequest,
  DeleteProductOrderResponse,
  GetProductOrderRequest,
  GetProductOrderResponse,
  ListProductOrdersRequest,
  ListProductOrdersResponse,
} from '../../../marketplace/product/v1/order_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.ProductOrderAPI
 */
export const ProductOrderAPI = {
  typeName: 'api.marketplace.v1.ProductOrderAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.GetProductOrder
     */
    getProductOrder: {
      name: 'GetProductOrder',
      I: GetProductOrderRequest,
      O: GetProductOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.ListProductOrders
     */
    listProductOrders: {
      name: 'ListProductOrders',
      I: ListProductOrdersRequest,
      O: ListProductOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.CreateProductOrder
     */
    createProductOrder: {
      name: 'CreateProductOrder',
      I: CreateProductOrderRequest,
      O: CreateProductOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.DeleteProductOrder
     */
    deleteProductOrder: {
      name: 'DeleteProductOrder',
      I: DeleteProductOrderRequest,
      O: DeleteProductOrderResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
