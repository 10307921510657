// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/product.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetProductRequest, ListProductsRequest } from './product_pb.js';
import {
  CreateProductRequest,
  CreateProductResponse,
  DeleteProductRequest,
  DeleteProductResponse,
  GetProductResponse,
  ListProductsResponse,
  SearchProductsRequest,
  SearchProductsResponse,
  SetProductStatusRequest,
  SetProductStatusResponse,
  UpdateProductRequest,
  UpdateProductResponse,
} from '../../../marketplace/product/v1/product_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.ProductAPI
 */
export const ProductAPI = {
  typeName: 'api.marketplace.v1.ProductAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.ProductAPI.GetProduct
     */
    getProduct: {
      name: 'GetProduct',
      I: GetProductRequest,
      O: GetProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductAPI.DeleteProduct
     */
    deleteProduct: {
      name: 'DeleteProduct',
      I: DeleteProductRequest,
      O: DeleteProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductAPI.ListProducts
     */
    listProducts: {
      name: 'ListProducts',
      I: ListProductsRequest,
      O: ListProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductAPI.CreateProduct
     */
    createProduct: {
      name: 'CreateProduct',
      I: CreateProductRequest,
      O: CreateProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductAPI.UpdateProduct
     */
    updateProduct: {
      name: 'UpdateProduct',
      I: UpdateProductRequest,
      O: UpdateProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductAPI.SetProductStatus
     */
    setProductStatus: {
      name: 'SetProductStatus',
      I: SetProductStatusRequest,
      O: SetProductStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductAPI.SearchProducts
     */
    searchProducts: {
      name: 'SearchProducts',
      I: SearchProductsRequest,
      O: SearchProductsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
