import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { useOrgsStore } from '../../zustand/orgs';

const SearchOrgByName = () => {
  const { searchedOrgsName, setSearchedOrgsName } = useOrgsStore();

  return (
    <TextField
      className=" w-full "
      InputProps={{
        inputProps: {
          onWheel: (event) => {
            const target = event.target as HTMLInputElement;
            target.blur();
          },
        },
      }}
      onChange={(data) => {
        setSearchedOrgsName(data.target.value);
      }}
      value={searchedOrgsName}
      label={
        <Box className="flex items-center gap-2">
          <SearchIcon /> Search by name{' '}
        </Box>
      }
      variant="standard"
    />
  );
};

export default SearchOrgByName;
