import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { useAuth } from '@store/auth';
import { ROUTES } from '@common/routes';
import { InputController } from '@components/Form/controllers/InputController';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { PreSignServiceRequest } from '@services/api/auth';
import Box from '@mui/material/Box';
import { useQueryParams } from '@pages/Home/useQuerryParams';
import OTPInput from 'react-otp-input';
import Button from '@mui/material/Button';
import { SignInRequest } from '@proto/iam/auth/v1/auth_pb';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import { OAuthButton } from './OAuthButton/OAuthButton';
import { OAuthAppTypes } from './OAuthButton/oAuthConfig';
import { useMfaState } from '../../zustand/mfa';

const StyledLink = styled(Link)(() => ({
  color: 'white',
  display: 'inline-flex',
}));

export const AuthForm = () => {
  const params = useQueryParams();
  const { inputError, setMfaInputError } = useMfaState();
  const navigate = useNavigate();
  const { isLoading, showOtpInput, preSignIn, signIn, device } = useAuth();
  const { data: userProfile } = useGetMyProfile();
  const [otp, setOtp] = React.useState('');

  useEffect(() => {
    if (params?.mfa === 'false' && params?.code && device) {
      signIn(
        new SignInRequest({
          type: {
            case: 'token',
            value: params?.code,
          },
          device: device || undefined,
        })
      );
    }
  }, [params, device, signIn]);

  useEffect(() => {
    if (userProfile) {
      navigate('/main');
    }
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack maxWidth={{ xs: 340, md: 280, xl: 320 }}>
      {params?.mfa !== 'true' && (
        <Box className="flex flex-col gap-4">
          <OAuthButton type={OAuthAppTypes.google} />
          <Typography variant="body1" textAlign="center">
            or
          </Typography>
          <Form<PreSignServiceRequest>
            formProps={{ defaultValues: { email: '' } }}
            submitHandler={(data) => {
              // @ts-ignore
              preSignIn({ email: data.email, device });
            }}
          >
            <InputController
              name="email"
              label="Email"
              variant="outlined"
              type="email"
              autoComplete="email"
              fullWidth
              sx={{ mb: 3 }}
            />
            <LoadingButton type="submit" variant="outlined" loading={isLoading} fullWidth>
              Continue with Email
            </LoadingButton>
          </Form>

          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }} my={3}>
            By continuing I agree to{' '}
            <StyledLink to={ROUTES.LEGAL_PRIVACY}>Privacy Policy</StyledLink>
            <br />
            and <StyledLink to={ROUTES.LEGAL_TERMS}> Terms of Service</StyledLink>
          </Typography>
        </Box>
      )}
      {params?.mfa === 'true' && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography textAlign="center" variant="subtitle1">
            Please enter the OTP code from the authenticator app
          </Typography>
          <OTPInput
            value={otp}
            onChange={(data) => {
              setMfaInputError(false);
              setOtp(data);
            }}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            inputStyle={`inputStyle ${inputError && 'inputStyleError'}`}
          />
          <Button
            variant="contained"
            onClick={() => {
              if (otp.length !== 6) {
                setMfaInputError(true);
                return;
              }

              signIn(
                new SignInRequest({
                  type: {
                    case: 'token',
                    value: params?.code,
                  },
                  device: device || undefined,
                  code: otp,
                })
              );
            }}
          >
            Send OTP
          </Button>
        </Box>
      )}

      {/* <ChatInput onSendMessage={() => null}/> */}
    </Stack>
  );
};
