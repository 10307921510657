import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import React from 'react';
import EventIcon from '@mui/icons-material/Event';
import ParticipantCard from '@pages/Marketplace/Dashboards/ParticipantCard';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Evaluation_Profiles } from '@proto/marketplace/demand/v1/evaluation_pb';
import MDRenderer from '@components/Form/controllers/WysiwygController/MDRenderer';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';

const ManageEvaluationsList = ({ demand }: { demand: Demand }) => {
  const { demandId } = useParams();

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {(demand?.entity?.value as Interview)?.evaluations?.evaluations?.length ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {(demand?.entity.value as Interview).evaluations?.evaluations?.map((evaluation) => {
              return (
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" gap={1} alignItems="center">
                    <EventIcon />
                    <Typography variant="subtitle2">
                      {evaluation?.status?.createdAt && (
                        <Typography fontSize="14px">
                          {evaluation?.status?.createdAt &&
                            new Date(evaluation?.status?.createdAt.toDate()).toLocaleDateString()}
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={2} justifyContent="space-between">
                    {(evaluation.participants.value as Evaluation_Profiles) &&
                      Object.entries(evaluation.participants.value as Evaluation_Profiles)?.map(
                        ([type, participant]: any) => {
                          return (
                            <Box display="flex" flexDirection="column" gap={1} width="50%">
                              <Typography>{type.toUpperCase()}</Typography>
                              {participant ? (
                                <ParticipantCard participant={participant} />
                              ) : (
                                <Box display="flex" gap={1}>
                                  <HourglassEmptyIcon />
                                  <Typography>Wait</Typography>
                                </Box>
                              )}
                            </Box>
                          );
                        }
                      )}
                  </Box>

                  {evaluation?.feedback && (
                    <Box>
                      <Typography>Feedback:</Typography>
                      <MDRenderer content={evaluation.feedback.text} />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography>No evaluations yet</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ManageEvaluationsList;
