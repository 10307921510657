import * as React from 'react';
import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import PreAppliedEntitySelect from '@pages/Marketplace/Feed/FeedByDemand/PreAppliedEntitySelect';
import PreAppliedDemandCard from '@pages/Marketplace/Feed/FeedByDemand/PreAppliedDemandCard';
import { Contractors } from '@proto/marketplace/demand/v1/contractor_pb';
import {
  useApplyAsContractor,
  useListFeedItemsByDemand,
} from '@services/queries/MarketplaceQueries';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { sortDemandsByDate } from '@pages/Marketplace/Dashboards/config';
import FiltersFeedItems from '@pages/Marketplace/Feed/FiltersFeedItems';
import FeedOwner from '@pages/Marketplace/Feed/FeedOwner';
import Button from '@mui/material/Button';
import ScrollableContainer from '@components/ScrollableContainerІ';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { demandsTypes } from '@pages/Marketplace/Feed/FeedPage';

const FeedByDemandsDialog = () => {
  const {
    demandIdFromUrl,
    displayListType,
    preAppliesContracts,
    contractors,
    currentContractorId,
    setDemandId,
  } = useMarketplace();
  const { data: feedByContract } = useListFeedItemsByDemand(
    demandIdFromUrl
      ? new Entity({
          id: new UUID({ value: demandIdFromUrl.demandId }),
          type: demandsTypes[demandIdFromUrl.demandType as keyof typeof demandsTypes],
        })
      : undefined
  );

  const contractList = React.useMemo(
    () => Object.values(feedByContract?.feedItems?.contracts || {}),
    [feedByContract?.feedItems]
  );
  const interviewsList = React.useMemo(
    () => Object.values(feedByContract?.feedItems?.interviews || {}),
    [feedByContract?.feedItems]
  );
  const combinedList = React.useMemo(
    () => [...contractList, ...interviewsList],
    [contractList, interviewsList]
  );

  const { mutate } = useApplyAsContractor();

  const toggleDemand = () => {
    setDemandId(undefined);
  };

  const displayOptions = React.useMemo(
    () => ({
      all: sortDemandsByDate(combinedList),
      contracts: sortDemandsByDate(contractList),
      interviews: sortDemandsByDate(interviewsList),
    }),
    [combinedList, contractList, interviewsList]
  );

  const handleApply = React.useCallback(() => {
    preAppliesContracts.map((id) => {
      return contractors?.some((contractor) => contractor.contractId?.value !== id) && null;
    });

    return mutate({
      contractors: new Contractors({
        contractor: {
          case: currentContractorId?.case || 'orgId',
          value: new UUID({ value: currentContractorId?.id.value }),
        },
        contracts: contractors || [],
      }),
    });
  }, [preAppliesContracts, contractors, currentContractorId, mutate]);

  return (
    <Dialog open={!!feedByContract?.feedItems} fullWidth onClose={toggleDemand}>
      <Box className="flex w-full overflow-hidden">
        <Box className="w-full max-w-[1050px]  flex relative flex-col px-2 pt-4">
          <ScrollableContainer height="87vh">
            <Box display="flex" flexDirection="column" pr={1} gap={2}>
              {displayOptions[displayListType as keyof typeof displayOptions] &&
                displayOptions[displayListType as keyof typeof displayOptions].map((demand) => (
                  <PreAppliedDemandCard
                    key={
                      (demand.entity.value as Interview).interviewId?.value ||
                      (demand.entity.value as Contract).contractId?.value
                    }
                    isPreApplied
                    showActiveState
                    demand={demand}
                  />
                ))}
            </Box>
          </ScrollableContainer>
        </Box>

        <Box className="flex max-w-[350px] flex-col gap-1 w-full py-4  z-50 px-4">
          <Box>
            <Box className="flex gap-4">
              <FiltersFeedItems />
            </Box>
            <Box className="flex flex-col gap-2">
              <FeedOwner feedOwner={feedByContract?.feedOwner} />
              {displayListType !== 'interviews' && contractList.length > 0 ? (
                <PreAppliedEntitySelect />
              ) : null}
              {displayListType !== 'interviews' && contractList.length > 0 ? (
                <Box display="flex">
                  <Button
                    fullWidth
                    disabled={!contractors?.length}
                    variant="outlined"
                    onClick={handleApply}
                  >
                    {contractors?.length
                      ? `Apply to ${contractors?.length} contracts`
                      : 'Choose suppliers'}
                  </Button>
                </Box>
              ) : null}
            </Box>
            <Box className="cursor-pointer absolute right-5 top-5" onClick={toggleDemand}>
              <CloseIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default React.memo(FeedByDemandsDialog);
