// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file marketplace/product/v1/order.proto (package marketplace.product.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { RWX } from '../../../iam/auth/v1/iam_pb.js';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';

/**
 * @generated from enum marketplace.product.v1.ProductOrderStatus
 */
export enum ProductOrderStatus {
  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_COMPLETED = 2;
   */
  COMPLETED = 2,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_CANCELLED = 3;
   */
  CANCELLED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductOrderStatus)
proto3.util.setEnumType(ProductOrderStatus, 'marketplace.product.v1.ProductOrderStatus', [
  { no: 0, name: 'PRODUCT_ORDER_STATUS_UNSPECIFIED' },
  { no: 1, name: 'PRODUCT_ORDER_STATUS_PENDING' },
  { no: 2, name: 'PRODUCT_ORDER_STATUS_COMPLETED' },
  { no: 3, name: 'PRODUCT_ORDER_STATUS_CANCELLED' },
]);

/**
 * @generated from message marketplace.product.v1.ProductOrder
 */
export class ProductOrder extends Message<ProductOrder> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID product_id = 2;
   */
  productId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * @generated from field: marketplace.product.v1.ProductOrderStatus status = 4;
   */
  status = ProductOrderStatus.UNSPECIFIED;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 5;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<ProductOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ProductOrder';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
    { no: 2, name: 'product_id', kind: 'message', T: UUID },
    { no: 3, name: 'created', kind: 'message', T: Timestamp },
    { no: 4, name: 'status', kind: 'enum', T: proto3.getEnumType(ProductOrderStatus) },
    { no: 5, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductOrder {
    return new ProductOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductOrder {
    return new ProductOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductOrder {
    return new ProductOrder().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProductOrder | PlainMessage<ProductOrder> | undefined,
    b: ProductOrder | PlainMessage<ProductOrder> | undefined
  ): boolean {
    return proto3.util.equals(ProductOrder, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.OrderProductRequest
 */
export class OrderProductRequest extends Message<OrderProductRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  constructor(data?: PartialMessage<OrderProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.OrderProductRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderProductRequest {
    return new OrderProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderProductRequest {
    return new OrderProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OrderProductRequest {
    return new OrderProductRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: OrderProductRequest | PlainMessage<OrderProductRequest> | undefined,
    b: OrderProductRequest | PlainMessage<OrderProductRequest> | undefined
  ): boolean {
    return proto3.util.equals(OrderProductRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.OrderProductResponse
 */
export class OrderProductResponse extends Message<OrderProductResponse> {
  constructor(data?: PartialMessage<OrderProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.OrderProductResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderProductResponse {
    return new OrderProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderProductResponse {
    return new OrderProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OrderProductResponse {
    return new OrderProductResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: OrderProductResponse | PlainMessage<OrderProductResponse> | undefined,
    b: OrderProductResponse | PlainMessage<OrderProductResponse> | undefined
  ): boolean {
    return proto3.util.equals(OrderProductResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductOrderRequest
 */
export class GetProductOrderRequest extends Message<GetProductOrderRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  constructor(data?: PartialMessage<GetProductOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductOrderRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetProductOrderRequest {
    return new GetProductOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderRequest {
    return new GetProductOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderRequest {
    return new GetProductOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductOrderRequest | PlainMessage<GetProductOrderRequest> | undefined,
    b: GetProductOrderRequest | PlainMessage<GetProductOrderRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetProductOrderRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductOrderResponse
 */
export class GetProductOrderResponse extends Message<GetProductOrderResponse> {
  /**
   * @generated from field: marketplace.product.v1.ProductOrder order = 1;
   */
  order?: ProductOrder;

  constructor(data?: PartialMessage<GetProductOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductOrderResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order', kind: 'message', T: ProductOrder },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetProductOrderResponse {
    return new GetProductOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse {
    return new GetProductOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse {
    return new GetProductOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductOrderResponse | PlainMessage<GetProductOrderResponse> | undefined,
    b: GetProductOrderResponse | PlainMessage<GetProductOrderResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetProductOrderResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListProductOrdersRequest
 */
export class ListProductOrdersRequest extends Message<ListProductOrdersRequest> {
  /**
   * @generated from field: marketplace.product.v1.ProductOrderStatus status = 1;
   */
  status = ProductOrderStatus.UNSPECIFIED;

  /**
   * @generated from oneof marketplace.product.v1.ListProductOrdersRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID product_id = 2;
         */
        value: UUID;
        case: 'productId';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUID customer_id = 3;
         */
        value: UUID;
        case: 'customerId';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS owner_ids = 4;
         */
        value: UUIDS;
        case: 'ownerIds';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListProductOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListProductOrdersRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'status', kind: 'enum', T: proto3.getEnumType(ProductOrderStatus) },
    { no: 2, name: 'product_id', kind: 'message', T: UUID, oneof: 'by' },
    { no: 3, name: 'customer_id', kind: 'message', T: UUID, oneof: 'by' },
    { no: 4, name: 'owner_ids', kind: 'message', T: UUIDS, oneof: 'by' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProductOrdersRequest {
    return new ListProductOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersRequest {
    return new ListProductOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersRequest {
    return new ListProductOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProductOrdersRequest | PlainMessage<ListProductOrdersRequest> | undefined,
    b: ListProductOrdersRequest | PlainMessage<ListProductOrdersRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProductOrdersRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListProductOrdersResponse
 */
export class ListProductOrdersResponse extends Message<ListProductOrdersResponse> {
  /**
   * @generated from field: repeated marketplace.product.v1.ProductOrder orders = 1;
   */
  orders: ProductOrder[] = [];

  constructor(data?: PartialMessage<ListProductOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListProductOrdersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'orders', kind: 'message', T: ProductOrder, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProductOrdersResponse {
    return new ListProductOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersResponse {
    return new ListProductOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersResponse {
    return new ListProductOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProductOrdersResponse | PlainMessage<ListProductOrdersResponse> | undefined,
    b: ListProductOrdersResponse | PlainMessage<ListProductOrdersResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProductOrdersResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductOrderRequest
 */
export class CreateProductOrderRequest extends Message<CreateProductOrderRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity customer = 2;
   */
  customer?: Entity;

  constructor(data?: PartialMessage<CreateProductOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductOrderRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'customer', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductOrderRequest {
    return new CreateProductOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderRequest {
    return new CreateProductOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderRequest {
    return new CreateProductOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductOrderRequest | PlainMessage<CreateProductOrderRequest> | undefined,
    b: CreateProductOrderRequest | PlainMessage<CreateProductOrderRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductOrderRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductOrderResponse
 */
export class CreateProductOrderResponse extends Message<CreateProductOrderResponse> {
  /**
   * @generated from field: marketplace.product.v1.ProductOrder order = 1;
   */
  order?: ProductOrder;

  constructor(data?: PartialMessage<CreateProductOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductOrderResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order', kind: 'message', T: ProductOrder },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductOrderResponse {
    return new CreateProductOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderResponse {
    return new CreateProductOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderResponse {
    return new CreateProductOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductOrderResponse | PlainMessage<CreateProductOrderResponse> | undefined,
    b: CreateProductOrderResponse | PlainMessage<CreateProductOrderResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductOrderResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteProductOrderRequest
 */
export class DeleteProductOrderRequest extends Message<DeleteProductOrderRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  constructor(data?: PartialMessage<DeleteProductOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteProductOrderRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteProductOrderRequest {
    return new DeleteProductOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteProductOrderRequest {
    return new DeleteProductOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProductOrderRequest {
    return new DeleteProductOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteProductOrderRequest | PlainMessage<DeleteProductOrderRequest> | undefined,
    b: DeleteProductOrderRequest | PlainMessage<DeleteProductOrderRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProductOrderRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteProductOrderResponse
 */
export class DeleteProductOrderResponse extends Message<DeleteProductOrderResponse> {
  constructor(data?: PartialMessage<DeleteProductOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteProductOrderResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteProductOrderResponse {
    return new DeleteProductOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteProductOrderResponse {
    return new DeleteProductOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProductOrderResponse {
    return new DeleteProductOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteProductOrderResponse | PlainMessage<DeleteProductOrderResponse> | undefined,
    b: DeleteProductOrderResponse | PlainMessage<DeleteProductOrderResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProductOrderResponse, a, b);
  }
}
