// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/interviewer.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ApplyAsInterviewerRequest,
  ApplyAsInterviewerResponse,
  ApproveInterviewerRequest,
  ApproveInterviewerResponse,
  AssignInterviewerToEvaluationRequest,
  AssignInterviewerToEvaluationResponse,
  RejectInterviewerRequest,
  RejectInterviewerResponse,
  RevokeInterviewerApplyRequest,
  RevokeInterviewerApplyResponse,
} from '../../../marketplace/demand/v1/interviewer_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.InterviewerAPI
 */
export const InterviewerAPI = {
  typeName: 'api.marketplace.v1.InterviewerAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.InterviewerAPI.ApplyAsInterviewer
     */
    applyAsInterviewer: {
      name: 'ApplyAsInterviewer',
      I: ApplyAsInterviewerRequest,
      O: ApplyAsInterviewerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewerAPI.ApproveInterviewer
     */
    approveInterviewer: {
      name: 'ApproveInterviewer',
      I: ApproveInterviewerRequest,
      O: ApproveInterviewerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewerAPI.RejectInterviewer
     */
    rejectInterviewer: {
      name: 'RejectInterviewer',
      I: RejectInterviewerRequest,
      O: RejectInterviewerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewerAPI.RevokeInterviewerApply
     */
    revokeInterviewerApply: {
      name: 'RevokeInterviewerApply',
      I: RevokeInterviewerApplyRequest,
      O: RevokeInterviewerApplyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewerAPI.AssignInterviewerToEvaluation
     */
    assignInterviewerToEvaluation: {
      name: 'AssignInterviewerToEvaluation',
      I: AssignInterviewerToEvaluationRequest,
      O: AssignInterviewerToEvaluationResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
