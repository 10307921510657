import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { Page } from '@components/Page/Page';
import ExploreSquadCard from '@pages/Squads/ExploreSquads/ExploreSquadCard';
import InfoIcon from '@mui/icons-material/Info';
import { useSearchSquads } from '@services/queries/SquadsQueries';
import { useSearchParams } from 'react-router-dom';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import Container from '@mui/material/Container';
import SearchSquadsByTags from '@pages/Squads/ExploreSquads/SearchSquadsByTags';

const tagConfigs = [
  { key: 'industryDomains', label: 'Industry Domains' },
  { key: 'roles', label: 'Roles' },
  { key: 'language', label: 'Languages' },
  { key: 'programmingLanguage', label: 'Programming Languages' },
  { key: 'country', label: 'Countries' },
];

const ExploreSquadsList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTags, setSelectedTags] = useState<Record<string, Tag[]>>({});

  const { data: searchedSquads } = useSearchSquads({
    tags: Object.values(selectedTags).flat(),
  });

  const handleTagChange = (key: string, newTags: Tag[]) => {
    setSelectedTags((prev) => ({
      ...prev,
      [key]: newTags,
    }));

    const newParams = new URLSearchParams(searchParams);
    if (newTags.length > 0) {
      newParams.set(key, JSON.stringify(newTags));
    } else {
      newParams.delete(key);
    }
    setSearchParams(newParams);
  };

  useEffect(() => {
    const initialTags: Record<string, Tag[]> = {};
    tagConfigs.forEach(({ key }) => {
      const tagsFromUrl = searchParams.get(key);
      if (tagsFromUrl) {
        initialTags[key] = JSON.parse(tagsFromUrl) as Tag[];
      }
    });
    setSelectedTags(initialTags);
  }, [searchParams]);

  return (
    <Page description="Explore squads" title="Explore squads">
      <Container>
        <Box
          className="relative flex flex-col"
          sx={{
            height: 'calc(100vh - 120px)',
          }}
        >
          <div className="flex flex-1 overflow-hidden">
            {/* Main Content */}
            <Box className="flex-1 overflow-y-auto pr-6">
              {searchedSquads?.squads?.squads && searchedSquads.squads.squads.length > 0 ? (
                <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                  {searchedSquads.squads.squads.map((squad) => (
                    <Box key={squad.squadId?.value as string}>
                      <ExploreSquadCard squad={squad} />
                    </Box>
                  ))}
                </Masonry>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <InfoIcon />
                  <Typography align="center" variant="subtitle1">
                    Squads not found
                  </Typography>
                </Box>
              )}
            </Box>
            {/* Sidebar */}
            <aside className="w-80 bg-transparent flex-shrink-0 h-full sticky top-0 overflow-y-auto">
              <SearchSquadsByTags selectedTags={selectedTags} handleTagChange={handleTagChange} />
            </aside>
          </div>
        </Box>
      </Container>
    </Page>
  );
};

export default ExploreSquadsList;
