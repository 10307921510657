import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as React from 'react';
import ManageDashboardsList from '@pages/Marketplace/Dashboards/ManageDashboardsList';
import { useListEvaluations } from '@services/queries/MarketplaceQueries';
import ListEvaluation from '@pages/Main/MainPage/Evaluations/SortedEvaluationsList';
import ListMemberships from '@pages/Main/MainPage/ListMemberships';
import ListAppliedDemands from '@pages/Main/MainPage/ListAppliedDemands';

const MainPage = () => {
  const { data } = useListEvaluations({ refetch: true, enabled: true });

  return (
    <Container>
      <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <Box width={{ xs: '100%', md: '25%' }}>
          <ListAppliedDemands />
          {/* <ListOrders /> */}
        </Box>
        <Box width={{ xs: '100%', md: '50%' }}>
          {data?.sortedEvalutions && (
            <ListEvaluation tags={data.tags} evaluations={data?.sortedEvalutions} />
          )}
        </Box>
        <Box display="flex" gap={2} flexDirection="column" width={{ xs: '100%', md: '25%' }}>
          <ManageDashboardsList />
          <ListMemberships />
        </Box>
      </Box>
    </Container>
  );
};

export default MainPage;
