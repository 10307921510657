import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Traits, UpdateProfileTraitsRequest } from '@proto/profiler/user/v1/profile_pb';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React, { Suspense } from 'react';
import { useGetMyProfile, useUupdateProfileTraits } from '@services/queries/ProfilesQueries';
import { useDialog } from '@components/hooks/useDialog';
import CalendarComponentForInterviewer from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponentForInterviewer';

const InterviewerButton = () => {
  const { data: userProfile } = useGetMyProfile();
  const { mutate: updateTraits } = useUupdateProfileTraits();
  const { isOpen, handleOpen, handleClose } = useDialog();

  return (
    <Box className="flex gap-2">
      <Box className="flex  items-center">
        <Typography lineHeight="16px" fontSize="13px">
          Interviewer
        </Typography>
        <Checkbox
          checked={userProfile?.profile?.traits?.interviewer}
          onChange={(e) => {
            if (e.target.checked) {
              updateTraits(
                new UpdateProfileTraitsRequest({
                  traits: new Traits({
                    ...userProfile?.profile?.traits,
                    interviewer: true,
                  }),
                })
              );
            }
            if (!e.target.checked) {
              updateTraits(
                new UpdateProfileTraitsRequest({
                  traits: new Traits({
                    ...userProfile?.profile?.traits,
                    interviewer: false,
                  }),
                })
              );
            }
          }}
        />
      </Box>
      {userProfile?.profile?.traits?.interviewer && (
        <Box onClick={handleOpen} className="flex cursor-pointer items-center gap-1">
          <Typography lineHeight="16px" fontSize="13px">
            Schedule free slots
          </Typography>
          <CalendarMonthIcon sx={{ cursor: 'pointer', width: '16px', height: '16px' }} />
        </Box>
      )}
      <Suspense>
        <CalendarComponentForInterviewer handleClose={handleClose} isOpen={isOpen} />
      </Suspense>
    </Box>
  );
};
export default InterviewerButton;
