import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import React from 'react';
import { useMarketplace } from '@store/marketplace';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { useFeed } from '../../../zustand/feed';

const listTypes = [
  { name: 'all', label: 'All' },
  { name: 'contracts', label: 'Contracts' },
  { name: 'interviews', label: 'Interviews' },
];

const entityTypes = {
  all: Entity_Type.UNSPECIFIED,
  contracts: Entity_Type.CONTRACT,
  interviews: Entity_Type.INTERVIEW,
};

const FiltersFeedItems = () => {
  const { setDisplayListType, displayListType } = useMarketplace();
  const { setCurrentEntityType } = useFeed();
  return (
    <Box display="flex" gap={2} py={1} className="">
      {listTypes.map((type) => {
        return (
          <Chip
            size="small"
            onClick={() => {
              setDisplayListType(type.name);
              setCurrentEntityType(entityTypes[type.name as keyof typeof entityTypes]);
            }}
            sx={{ cursor: 'pointer' }}
            variant={displayListType === type.name ? 'filled' : 'outlined'}
            label={type.label}
            key={type.name}
          />
        );
      })}
    </Box>
  );
};

export default FiltersFeedItems;
