import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import Typography from '@mui/material/Typography';
import Card from '@components/Card/Card';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { TagTypeEnum, TTag } from '@services/api/profiler/tags';
import { transformTagsToTagsByType } from '@utils/transformTagsToTagsByType';
import { Box, Chip, Divider } from '@mui/material';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';

const SupplyDemandCard = ({
  demand,
}: {
  demand:
    | { value: Interview; case: 'interview' }
    | { value: Contract; case: 'contract' }
    | { case: undefined; value?: undefined }
    | undefined;
}) => {
  const { dashboardId } = useParams();
  const [tagByType, setTagByType] = useState<Array<[TagTypeEnum, TTag[]]>>([]);

  useEffect(() => {
    setTagByType(transformTagsToTagsByType(demand?.value?.tags?.tags || []));
  }, [demand?.value?.tags?.tags]);

  return (
    <Card>
      <Box display="flex" flexDirection="column" gap={2}>
        <Link
          className="NavLink"
          to={`/${dashboardId}/${demand?.case}-manage/${
            (demand?.value as Interview).interviewId?.value ||
            (demand?.value as Contract).contractId?.value
          }`}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Typography variant="subtitle2">{demand?.case?.toUpperCase()}</Typography>

            {tagByType
              // @ts-ignore
              .filter(([type]) => type === TagType.ROLE)
              .map(([type, tags]) => (
                <Box display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                  {tags.map((tag) => (
                    <Chip
                      size="small"
                      key={tag?.value}
                      label={tag.alias || tag?.value}
                      variant="outlined"
                    />
                  ))}
                </Box>
              ))}
          </Box>
        </Link>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {demand?.value && <DemandTraits fontSize="13px" demand={demand?.value} />}
        </Box>
      </Box>
    </Card>
  );
};

export default SupplyDemandCard;
