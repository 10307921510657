import Box from '@mui/material/Box';
import Card from '@components/Card/Card';
import { Chip, Divider } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import { Link } from 'react-router-dom';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';

const DemandAppliedCard = ({ demand }: { demand: Demand }) => {
  const isFreeEvaluation = (demand.entity.value as Interview)?.evaluationWithoutInterviewer;

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Link
        to={`/${demand?.entity?.case}/${
          // @ts-ignore
          demand?.entity.value?.interviewId?.value || demand?.entity.value?.contractId?.value
        }`}
        className="NavLink"
      >
        <Card>
          <Box display="flex" gap={1} px={1} flexDirection="column" justifyContent="center">
            <Typography fontSize="14px" textAlign="center" fontWeight="bold">
              {isFreeEvaluation &&
              (demand?.entity?.value as Interview)?.evaluations?.evaluations?.length ? (
                <Typography color="primary">You can assign</Typography>
              ) : null}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize="14px" fontWeight="bold">
                {demand?.entity?.case?.toUpperCase()}
              </Typography>
              {demand.entity.value?.tags?.tags
                .filter((tag) => tag.tagType === TagType.ROLE)
                .map((tag) => {
                  return <Chip size="small" key={tag.tagId} variant="outlined" label={tag.value} />;
                })}
              {demand?.entity.value?.createdAt && (
                <Typography fontSize="14px">
                  {format(new Date(demand?.entity.value?.createdAt.toDate()), 'dd/MM/yyyy')}
                </Typography>
              )}
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" gap={1}>
              {demand.entity.value && <DemandTraits fontSize="14px" demand={demand.entity.value} />}
              {/* {demand?.tags?.tags && <Tags tags={demand?.tags?.tags}/>} */}
            </Box>
          </Box>
        </Card>
      </Link>
    </Box>
  );
};

export default DemandAppliedCard;
