import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import React from 'react';
import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useAddStep, useRemoveStep, useUpdateStep } from '@services/queries/StepsQueries';
import {
  AddStepFormProps,
  createStepSchema,
  FieldNames,
  getDefaultValuesAddStep,
} from '@pages/Product/Steps/config';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import {
  AddStepRequest,
  RemoveStepRequest,
  Step,
  Step_Complexity,
  Step_Data,
  Step_Fibonacci,
  UpdateStepRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import Button from '@mui/material/Button';
import { SelectController } from '@components/Form/controllers/SelectController/SelectController';
import { createSelectOptions } from '@utils/createSelectOptionsFromEnum';

const CreateStepDialog = (props: AddStepFormProps) => {
  const { isOpen, handleClose, productId, step, breakDowns, position, tagId } = props;
  const { mutate: addStep } = useAddStep();
  const { mutate: updateStep } = useUpdateStep();
  const { mutate: removeStep } = useRemoveStep();
  const complexityOptions = createSelectOptions(Step_Complexity, [Step_Complexity.UNSPECIFIED]);
  const fibonacciOptions = createSelectOptions(Step_Fibonacci, [
    Step_Fibonacci.FIBONACCI_UNSPECIFIED,
  ]).map((option) => ({ ...option, label: `${option.value}` }));

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        display="flex"
        className="w-full no-drag max-w-[848px]"
        flexDirection="column"
        gap={1}
        py={5}
        px={{ xs: 3 }}
      >
        <Box display="flex" gap={2}>
          <Form
            width="100%"
            minWidth="800px"
            display="flex"
            alignSelf="center"
            flexDirection="column"
            formProps={{ defaultValues: getDefaultValuesAddStep(step) }}
            resolverSchema={createStepSchema}
            submitHandler={(data) => {
              handleClose();

              if (step) {
                updateStep(
                  new UpdateStepRequest({
                    step: new Step({
                      ...step,
                      data: new Step_Data({
                        ...step.data,
                        title: data.title,
                        description: data.description,
                        complexity: data.complexity,
                        fibonacci: data.fibonacci,
                        position: position || 0,
                      }),
                    }),
                  })
                );
              }

              if (productId) {
                addStep(
                  new AddStepRequest({
                    tagId,
                    productId,
                    data: new Step_Data({
                      title: data.title,
                      description: data.description,
                      complexity: data.complexity,
                      fibonacci: data.fibonacci,
                      position: position || 0,
                    }),
                  })
                );
              }
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="subtitle1">{step ? 'Update Step' : 'Create Step'}</Typography>

              <InputController
                name={FieldNames.TITLE}
                label="Enter a compelling title for your product to attract potential clients"
              />
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography fontWeight="bold">
                  Write a comprehensive description to give an overview of what your product offers
                </Typography>
                <WysiwygController name={FieldNames.DESCRIPTION} />
              </Box>

              {/* <RadioController name={FieldNames.PRICE_CASE} label="Price type"/> */}

              <SelectController
                label="Fibonacci"
                options={fibonacciOptions}
                name={FieldNames.FIBONACCI}
              />

              {/* {isRate && <InputController name={FieldNames.HOURS} label="Hours"/>} */}

              <SelectController
                label="Complexity"
                options={complexityOptions}
                name={FieldNames.COMPLEXITY}
              />

              <Divider />
              <Box display="flex" className="gap-2" justifyContent="flex-end">
                {step && !productId && (
                  <Box>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() =>
                        removeStep(new RemoveStepRequest({ stepId: step?.stepId }), {
                          onSuccess: handleClose,
                        })
                      }
                    >
                      Remove
                    </Button>
                  </Box>
                )}
                <LoadingButton type="submit" color="success" variant="outlined">
                  {step ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateStepDialog;
