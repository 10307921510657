import { Page } from '@components/Page/Page';
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStreamStore } from '../../zustand/subscribe';

export const Notifications = () => {
  const { bellNotifications } = useStreamStore();

  return (
    <Page description="Orgs" title="Orgs">
      <Container>
        {' '}
        {bellNotifications?.length === 0 ? (
          <Typography>You have no notifications.</Typography>
        ) : (
          <Box className="flex gap-2 flex-col">
            {bellNotifications.map((event) => {
              return <Typography key={event.subject}>{event.message}</Typography>;
            })}
          </Box>
        )}
      </Container>
    </Page>
  );
};
