import Typography from '@mui/material/Typography';
import React from 'react';
import Box from '@mui/material/Box';
import { Chip, Tooltip } from '@mui/material';
import Card from '@components/Card/Card';
import InfoIcon from '@mui/icons-material/Info';
import CustomAvatar from '@components/Avatar/Avatar';
import { Profile } from '@proto/profiler/user/v1/profile_pb';

type TUserCard = {
  userProfile: Profile;
};
const UserCard = (props: TUserCard) => {
  const { userProfile } = props;

  return (
    <Card>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={1}>
          <CustomAvatar
            alt={userProfile?.name?.nickName || 'User'}
            avatarId={userProfile.avatar}
            profileId={userProfile.profileId?.value}
          />
          <Box>
            <Typography color="secondary" textTransform="capitalize" variant="subtitle2">
              {userProfile?.name?.firstName} {userProfile?.name?.lastName}
            </Typography>
            <Typography fontWeight="bold">@{userProfile?.name?.nickName}</Typography>
          </Box>
        </Box>
        {userProfile?.traits?.experience && (
          <Tooltip placement="right" title="years">
            <Box display="flex" gap={1} alignItems="center">
              <InfoIcon fontSize="small" />
              <Typography>Experience: {userProfile.traits.experience}+ </Typography>
            </Box>
          </Tooltip>
        )}
        {userProfile?.traits?.capacity && (
          <Tooltip placement="right" title="hours per week">
            <Box display="flex" gap={1} alignItems="center">
              <InfoIcon fontSize="small" />
              <Typography>Capacity: {userProfile.traits.capacity} </Typography>
            </Box>
          </Tooltip>
        )}
        {/* {userProfile?.traits?.rate && <Typography>Rate: ${userProfile.traits.rate}</Typography>} */}
        <Box display="flex" flexWrap="wrap" gap={1}>
          {userProfile?.tags?.tags &&
            userProfile.tags?.tags.map((tag) => {
              return <Chip size="small" key={tag.value} label={tag.value} variant="outlined" />;
            })}
        </Box>
      </Box>
    </Card>
  );
};

export default UserCard;
