import { useSortable } from '@dnd-kit/sortable';
import { useDialog } from '@components/hooks/useDialog';
import { useParams } from 'react-router-dom';
import React, { Suspense, useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import Box from '@mui/material/Box';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import CreateStepDialog from '@pages/Product/Steps/CreateStepDialog';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { Step } from '@proto/marketplace/product/v1/break_down_pb';
import Complexity from '@pages/Product/BreakDowns/Complexity';

export interface SortableItemProps {
  step: Step;
  tagId: number;
  setMouseOver: (value: boolean) => void;
  steps: Step[];
}

export const StepDnDCard = (props: SortableItemProps) => {
  const { step, tagId, steps, setMouseOver } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: step.stepId?.value as string,
  });
  const {
    isOpen: isOpenUpdate,
    handleOpen: handleOpenUpdate,
    handleClose: handleCloseUpdate,
  } = useDialog();
  const {
    isOpen: isOpenCreate,
    handleOpen: handleOpenCreate,
    handleClose: handleCloseCreate,
  } = useDialog();
  const { productId } = useParams();
  const [position, setPosition] = useState<number | null>(null);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    color: '#fff',
  };

  return (
    <Box className="flex pt-2 px-4 group">
      <Box
        className="flex w-full items-center relative "
        ref={setNodeRef}
        style={style}
        {...attributes}
        onMouseDown={() => setMouseOver(true)}
        onMouseUp={() => setMouseOver(false)}
      >
        <Box
          onClick={handleOpenUpdate}
          className={`flex w-full relative transition justify-center flex-col ${
            steps?.length === 1 ? 'px-2' : ''
          }`}
        >
          <Box
            onClick={(event) => {
              event.stopPropagation();
              handleOpenCreate();
              setPosition((step?.data?.position as number) - 1);
            }}
            className="absolute flex justify-center transition opacity-0 left-0 group-hover:opacity-100 top-[-21px] w-full cursor-pointer"
          >
            <Tooltip title="Add step" placement="top">
              <AddCircleIcon className="absolute" />
            </Tooltip>
          </Box>

          <Box
            onClick={(event) => {
              event.stopPropagation();
              handleOpenCreate();
              setPosition((step?.data?.position as number) + 1);
            }}
            className="absolute flex justify-center transition left-0 group-hover:opacity-100 opacity-0 bottom-[-21px] w-full h-[15px] cursor-pointer"
          >
            <Tooltip title="Add step" placement="bottom">
              <AddCircleIcon className="absolute top-[-13px]" />
            </Tooltip>
          </Box>

          <Box className="flex gap-4 items-center mb-2 justify-between">
            <p className="font-semibold">{step.data?.title || 'No Title'}</p>
            <Box className="flex items-center gap-1">
              <Complexity step={step} />
            </Box>
          </Box>

          <Box className="flex gap-20 justify-between items-center">
            <Box>
              <Typography className="capitalize text-white/80">
                Estimate: <span className="font-bold text-white">{step.data?.fibonacci}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
        {steps?.length > 1 && (
          <Box
            className="cursor-ns-resize absolute right-0 bottom-2  group-hover:opacity-100 opacity-0 "
            {...listeners}
          >
            <DragIndicatorIcon />
          </Box>
        )}
      </Box>

      <Suspense>
        <CreateStepDialog step={step} handleClose={handleCloseUpdate} isOpen={isOpenUpdate} />
      </Suspense>
      <Suspense>
        <CreateStepDialog
          productId={new UUID({ value: productId as string })}
          handleClose={handleCloseCreate}
          isOpen={isOpenCreate}
          position={position as number}
          tagId={tagId}
        />
      </Suspense>
    </Box>
  );
};
