// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/candidate.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Evaluation } from './evaluation_pb.js';
import { Profile, Profiles } from '../../../profiler/user/v1/profile_pb.js';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message marketplace.demand.v1.Candidate
 */
export class Candidate extends Message<Candidate> {
  /**
   * @generated from oneof marketplace.demand.v1.Candidate.is_evaluation_assigned
   */
  isEvaluationAssigned:
    | {
        /**
         * @generated from field: marketplace.demand.v1.Evaluation evaluation = 1;
         */
        value: Evaluation;
        case: 'evaluation';
      }
    | {
        /**
         * @generated from field: profiler.user.v1.Profile candidate = 2;
         */
        value: Profile;
        case: 'candidate';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Candidate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Candidate';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation', kind: 'message', T: Evaluation, oneof: 'is_evaluation_assigned' },
    { no: 2, name: 'candidate', kind: 'message', T: Profile, oneof: 'is_evaluation_assigned' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Candidate {
    return new Candidate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Candidate {
    return new Candidate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Candidate {
    return new Candidate().fromJsonString(jsonString, options);
  }

  static equals(
    a: Candidate | PlainMessage<Candidate> | undefined,
    b: Candidate | PlainMessage<Candidate> | undefined
  ): boolean {
    return proto3.util.equals(Candidate, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.InviteCandidatesRequest
 */
export class InviteCandidatesRequest extends Message<InviteCandidatesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 1;
   */
  interviewId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUIDS profiles = 2;
   */
  profiles?: UUIDS;

  constructor(data?: PartialMessage<InviteCandidatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.InviteCandidatesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'message', T: UUID },
    { no: 2, name: 'profiles', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): InviteCandidatesRequest {
    return new InviteCandidatesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): InviteCandidatesRequest {
    return new InviteCandidatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): InviteCandidatesRequest {
    return new InviteCandidatesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: InviteCandidatesRequest | PlainMessage<InviteCandidatesRequest> | undefined,
    b: InviteCandidatesRequest | PlainMessage<InviteCandidatesRequest> | undefined
  ): boolean {
    return proto3.util.equals(InviteCandidatesRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.InviteCandidatesResponse
 */
export class InviteCandidatesResponse extends Message<InviteCandidatesResponse> {
  constructor(data?: PartialMessage<InviteCandidatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.InviteCandidatesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): InviteCandidatesResponse {
    return new InviteCandidatesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): InviteCandidatesResponse {
    return new InviteCandidatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): InviteCandidatesResponse {
    return new InviteCandidatesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: InviteCandidatesResponse | PlainMessage<InviteCandidatesResponse> | undefined,
    b: InviteCandidatesResponse | PlainMessage<InviteCandidatesResponse> | undefined
  ): boolean {
    return proto3.util.equals(InviteCandidatesResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AddCandidateRequest
 */
export class AddCandidateRequest extends Message<AddCandidateRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 1;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<AddCandidateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AddCandidateRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCandidateRequest {
    return new AddCandidateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCandidateRequest {
    return new AddCandidateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddCandidateRequest {
    return new AddCandidateRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddCandidateRequest | PlainMessage<AddCandidateRequest> | undefined,
    b: AddCandidateRequest | PlainMessage<AddCandidateRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddCandidateRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AddCandidateResponse
 */
export class AddCandidateResponse extends Message<AddCandidateResponse> {
  constructor(data?: PartialMessage<AddCandidateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AddCandidateResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCandidateResponse {
    return new AddCandidateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCandidateResponse {
    return new AddCandidateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddCandidateResponse {
    return new AddCandidateResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddCandidateResponse | PlainMessage<AddCandidateResponse> | undefined,
    b: AddCandidateResponse | PlainMessage<AddCandidateResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddCandidateResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RemoveCandidateRequest
 */
export class RemoveCandidateRequest extends Message<RemoveCandidateRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 2;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<RemoveCandidateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RemoveCandidateRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemoveCandidateRequest {
    return new RemoveCandidateRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemoveCandidateRequest {
    return new RemoveCandidateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveCandidateRequest {
    return new RemoveCandidateRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveCandidateRequest | PlainMessage<RemoveCandidateRequest> | undefined,
    b: RemoveCandidateRequest | PlainMessage<RemoveCandidateRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveCandidateRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RemoveCandidateResponse
 */
export class RemoveCandidateResponse extends Message<RemoveCandidateResponse> {
  constructor(data?: PartialMessage<RemoveCandidateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RemoveCandidateResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemoveCandidateResponse {
    return new RemoveCandidateResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemoveCandidateResponse {
    return new RemoveCandidateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveCandidateResponse {
    return new RemoveCandidateResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveCandidateResponse | PlainMessage<RemoveCandidateResponse> | undefined,
    b: RemoveCandidateResponse | PlainMessage<RemoveCandidateResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveCandidateResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListCandidatesRequest
 */
export class ListCandidatesRequest extends Message<ListCandidatesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 1;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<ListCandidatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListCandidatesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListCandidatesRequest {
    return new ListCandidatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCandidatesRequest {
    return new ListCandidatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListCandidatesRequest {
    return new ListCandidatesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListCandidatesRequest | PlainMessage<ListCandidatesRequest> | undefined,
    b: ListCandidatesRequest | PlainMessage<ListCandidatesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListCandidatesRequest, a, b);
  }
}

/**
 * List Invites++
 *
 * @generated from message marketplace.demand.v1.ListCandidatesResponse
 */
export class ListCandidatesResponse extends Message<ListCandidatesResponse> {
  /**
   * @generated from field: profiler.user.v1.Profiles candidates = 1;
   */
  candidates?: Profiles;

  constructor(data?: PartialMessage<ListCandidatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListCandidatesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'candidates', kind: 'message', T: Profiles },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListCandidatesResponse {
    return new ListCandidatesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListCandidatesResponse {
    return new ListCandidatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListCandidatesResponse {
    return new ListCandidatesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListCandidatesResponse | PlainMessage<ListCandidatesResponse> | undefined,
    b: ListCandidatesResponse | PlainMessage<ListCandidatesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListCandidatesResponse, a, b);
  }
}
