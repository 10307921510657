/**
 * Note: Use position fixed according to your needs
 * Desktop navbar is better positioned at the bottom
 * Mobile navbar is better positioned at bottom right.
 * */
import React, { useRef, useState } from 'react';
import { IconLayoutNavbarCollapse } from '@tabler/icons-react';
import { motion, MotionValue, useMotionValue, useSpring, useTransform } from 'framer-motion';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';

const FloatingDockMobile = ({
  items,
  className,
  activeHref,
}: {
  items: { title: string; icon: React.ReactNode; href: string }[];
  className?: string;
  activeHref: string;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={cn('relative block md:hidden', className)}>
      {open && (
        <motion.div
          layoutId="nav"
          className="absolute bottom-full mb-2 inset-x-0 flex flex-col gap-2"
        >
          {items.map((item, idx) => (
            <motion.div
              key={item.title}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{
                opacity: 0,
                y: 10,
                transition: { delay: idx * 0.05 },
              }}
              transition={{ delay: (items.length - 1 - idx) * 0.05 }}
            >
              <Link to={item.href}>
                <div className="h-4 w-4">{item.icon}</div>
              </Link>
            </motion.div>
          ))}
        </motion.div>
      )}
      <Button
        onClick={() => setOpen(!open)}
        className="h-10 w-10 rounded-full bg-gray-50 dark:bg-neutral-800 flex items-center justify-center"
      >
        <IconLayoutNavbarCollapse className="h-5 w-5 text-neutral-500 dark:text-neutral-400" />
      </Button>
    </div>
  );
};

const IconContainer = ({
  mouseX,
  title,
  icon,
  href,
  isActive,
}: {
  mouseX: MotionValue;
  title: string;
  icon: React.ReactNode;
  href: string;
  isActive: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const distance = useTransform(mouseX, (val) => {
    const bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };

    return val - bounds.x - bounds.width / 2;
  });

  const widthTransform = useTransform(distance, [-150, 0, 150], [40, 50, 40]);
  const heightTransform = useTransform(distance, [-150, 0, 150], [40, 50, 40]);

  const width = useSpring(widthTransform, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });
  const height = useSpring(heightTransform, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  const [hovered, setHovered] = useState(false);

  return (
    <Link to={href}>
      <motion.div
        ref={ref}
        style={{ width, height }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={cn(
          'aspect-square rounded-full flex items-center justify-center relative',
          isActive ? 'bg-[#00695f] text-white' : 'bg-white/5'
        )}
      >
        <motion.div className="flex items-center justify-center">{icon}</motion.div>

        {hovered && (
          <motion.div
            initial={{ opacity: 0, y: 10, x: '-50%' }}
            animate={{ opacity: 1, y: 0, x: '-50%' }}
            exit={{ opacity: 0, y: 2, x: '-50%' }}
            className="px-2 py-0.5 whitespace-pre rounded-md bg-inherit border dark:bg-inherit dark:border-neutral-900 dark:text-white border-gray-200 text-neutral-700 absolute left-1/2 -translate-x-1/2 -bottom-8 w-fit text-xs"
          >
            {title}
          </motion.div>
        )}
      </motion.div>
    </Link>
  );
};
const FloatingDockDesktop = ({
  items,
  className,
  activeHref,
}: {
  items: { title: string; icon: React.ReactNode; href: string }[];
  className?: string;
  activeHref: string;
}) => {
  const mouseX = useMotionValue(Infinity);
  return (
    <motion.div
      onMouseMove={(e) => mouseX.set(e.pageX)}
      onMouseLeave={() => mouseX.set(Infinity)}
      className={cn(
        'mx-auto hidden md:flex h-16 gap-4 items-end  rounded-2xl bg-inherit dark:bg-inherit px-4 pb-3',
        className
      )}
    >
      {items.map((item) => (
        <IconContainer
          mouseX={mouseX}
          key={item.title}
          {...item}
          isActive={item.href === activeHref}
        />
      ))}
    </motion.div>
  );
};

export const FloatingDock = ({
  items,
  desktopClassName,
  mobileClassName,
  activeHref,
}: {
  items: { title: string; icon: React.ReactNode; href: string }[];
  desktopClassName?: string;
  mobileClassName?: string;
  activeHref: string;
}) => {
  return (
    <>
      <FloatingDockDesktop items={items} className={desktopClassName} activeHref={activeHref} />
      <FloatingDockMobile items={items} className={mobileClassName} activeHref={activeHref} />
    </>
  );
};
