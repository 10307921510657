import { getConnectClient } from '@services/api/helpers';
import { TagsAPI } from '@proto/api/profiler/v1/tags_connect';
import {
  AddTagsRequest,
  AddTagsResponse,
  ListObjectsTagsRequest,
  ListObjectsTagsResponse,
  ListTagsRequest,
  ListTagsResponse,
  MatchedTags,
  RemoveTagsRequest,
  RemoveTagsResponse,
  SetObjectTagsStatusRequest,
  SetObjectTagsStatusResponse,
  TagType,
} from '@proto/profiler/tags/v1/tags_pb';
import { useMutation, useQuery } from '@tanstack/react-query';

const TagsClient = getConnectClient<typeof TagsAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  TagsAPI
);

export const useAddTags = () => {
  const addTags = async (payload: AddTagsRequest): Promise<AddTagsResponse> => {
    return TagsClient.addTags(payload);
  };

  return useMutation({
    mutationFn: addTags,
  });
};

export const useRemoveTags = () => {
  const removeTags = async (payload: RemoveTagsRequest): Promise<RemoveTagsResponse> => {
    return TagsClient.removeTags(payload);
  };

  return useMutation({
    mutationFn: removeTags,
  });
};

export const useListObjectsTags = (payload: ListObjectsTagsRequest) => {
  const listObjectsTags = async (): Promise<ListObjectsTagsResponse> => {
    return TagsClient.listObjectsTags(payload);
  };

  return useQuery({
    queryKey: ['listObjectsTags', payload],
    queryFn: listObjectsTags,

    enabled: !!payload?.objectIds?.values.length,
  });
};

export const useSetTagsStatus = () => {
  const removeTags = async (
    payload: SetObjectTagsStatusRequest
  ): Promise<SetObjectTagsStatusResponse> => {
    return TagsClient.setObjectTagsStatus(payload);
  };

  return useMutation({
    mutationFn: removeTags,
  });
};

export const useListRoles = () => {
  const listTags = async (): Promise<ListTagsResponse> => {
    return TagsClient.listTags({ tags: new MatchedTags({ tagType: TagType.ROLE }) });
  };

  return useMutation({
    mutationFn: listTags,
  });
};

export const useTagsList = (payload: { tagType: TagType; enabled: boolean }) => {
  const listTags = async (): Promise<ListTagsResponse> => {
    return TagsClient.listTags(
      new ListTagsRequest({ tags: new MatchedTags({ tagType: payload.tagType }) })
    );
  };

  return useQuery({
    queryKey: ['listTags', payload],
    queryFn: listTags,
    enabled: payload.enabled,
  });
};
