// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file api/marketplace/v1/product.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { ListProductsRequest_Offset } from '../../../marketplace/product/v1/product_pb.js';

/**
 * @generated from message api.marketplace.v1.GetProductRequest
 */
export class GetProductRequest extends Message<GetProductRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  constructor(data?: PartialMessage<GetProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.marketplace.v1.GetProductRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductRequest {
    return new GetProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductRequest {
    return new GetProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductRequest {
    return new GetProductRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductRequest | PlainMessage<GetProductRequest> | undefined,
    b: GetProductRequest | PlainMessage<GetProductRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetProductRequest, a, b);
  }
}

/**
 * @generated from message api.marketplace.v1.ListProductsRequest
 */
export class ListProductsRequest extends Message<ListProductsRequest> {
  /**
   * @generated from oneof api.marketplace.v1.ListProductsRequest.by
   */
  by:
    | {
        /**
         * @generated from field: marketplace.product.v1.ListProductsRequest.Offset offset = 1;
         */
        value: ListProductsRequest_Offset;
        case: 'offset';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS product_ids = 2;
         */
        value: UUIDS;
        case: 'productIds';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.marketplace.v1.ListProductsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'offset', kind: 'message', T: ListProductsRequest_Offset, oneof: 'by' },
    { no: 2, name: 'product_ids', kind: 'message', T: UUIDS, oneof: 'by' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProductsRequest {
    return new ListProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined,
    b: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProductsRequest, a, b);
  }
}
