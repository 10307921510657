import Dialog from '@mui/material/Dialog';
import { TAutocompleteItem } from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import React, { Suspense } from 'react';
import { useDeleteBreakdownRole } from '@services/queries/StepsQueries';
import {
  BreakDown_Role,
  DeleteBreakDownRoleRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { useParams } from 'react-router-dom';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import { useDialog } from '@components/hooks/useDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import AddAssignees from '@pages/Product/BreakDowns/AddAssignees';
import UpdateBreakdownRoleForm from '@pages/Product/Steps/UpdateBreakdownRoleForm';
import UpdateBreakdownPriceForm from '@pages/Product/Steps/UpdateBreakdownPriceForm';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

function filterTagsOptimized(
  roles: TAutocompleteItem[],
  filterRoles: Tag[] | undefined
): TAutocompleteItem[] {
  if (!filterRoles) {
    return roles;
  }
  const tagFilterIds = new Set(filterRoles.map((tag) => tag.tagId));
  return roles.filter((tag) => !tagFilterIds.has(tag.tagId));
}

export type UpdateBreakdownDialogProps = {
  handleCloseModal: () => void;
  isOpen: boolean;
  roleId: number;
  breakdown: BreakDown_Role;
};

const UpdateBreakdownDialog = (props: UpdateBreakdownDialogProps) => {
  const { productId } = useParams();
  const { handleCloseModal, isOpen, roleId, breakdown } = props;
  const { mutate: deleteBreakdown } = useDeleteBreakdownRole();
  const {
    isOpen: isOpenDeleteBreakdown,
    handleOpen: handleOpenDeleteBreakdown,
    handleClose: handleCloseDeleteBreakdown,
  } = useDialog();

  return (
    <Dialog onClose={handleCloseModal} open={isOpen}>
      <Box className="px-20 flex flex-col gap-4 py-10 w-full min-w-[600px]">
        <Typography textAlign="center" variant="subtitle1">
          Breakdown Settings
        </Typography>
        <UpdateBreakdownRoleForm dialogIsOpen={isOpen} roleId={roleId} />
        <Divider />
        <UpdateBreakdownPriceForm
          defaultValues={{
            paymentType: breakdown?.price?.case,
            fixedRate: breakdown?.price?.case === 'fixed' ? Number(breakdown.price.value) : 0,
            rate: breakdown?.price?.case === 'rate' ? Number(breakdown.price.value.rate) : 0,
            hours: breakdown?.price?.case === 'rate' ? breakdown.price.value.hours : 0,
          }}
          roleId={breakdown.tag?.tagId as number}
        />
        <Divider />
        <AddAssignees
          defaultAssignees={breakdown?.assignees}
          roleId={breakdown.tag?.tagId as number}
        />
        <Divider />
        <Box className=" flex justify-center">
          <DeleteForeverIcon
            className="transition cursor-pointer hover:text-[red]"
            onClick={handleOpenDeleteBreakdown}
          />
        </Box>
      </Box>

      <Suspense>
        <ConfirmDialog
          title="You sure delete breakdown?"
          message="This action cannot be undone"
          onClose={handleCloseDeleteBreakdown}
          isOpen={isOpenDeleteBreakdown}
          onConfirm={() => {
            deleteBreakdown(
              new DeleteBreakDownRoleRequest({
                productId: new UUID({ value: productId }),
                roleId: roleId as number,
              }),
              {
                onSuccess: () => {
                  handleCloseDeleteBreakdown();
                  handleCloseModal();
                },
              }
            );
          }}
        />
      </Suspense>
    </Dialog>
  );
};

export default UpdateBreakdownDialog;
