// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file marketplace/product/v1/break_down.proto (package marketplace.product.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf';
import { Tag } from '../../../profiler/tags/v1/tags_pb.js';
import { Profiles } from '../../../profiler/user/v1/profile_pb.js';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { RWX } from '../../../iam/auth/v1/iam_pb.js';

/**
 * @generated from message marketplace.product.v1.BreakDown
 */
export class BreakDown extends Message<BreakDown> {
  /**
   * @generated from field: repeated marketplace.product.v1.BreakDown.Role roles = 1;
   */
  roles: BreakDown_Role[] = [];

  constructor(data?: PartialMessage<BreakDown>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.BreakDown';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'roles', kind: 'message', T: BreakDown_Role, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BreakDown {
    return new BreakDown().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BreakDown {
    return new BreakDown().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BreakDown {
    return new BreakDown().fromJsonString(jsonString, options);
  }

  static equals(
    a: BreakDown | PlainMessage<BreakDown> | undefined,
    b: BreakDown | PlainMessage<BreakDown> | undefined
  ): boolean {
    return proto3.util.equals(BreakDown, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.BreakDown.Rate
 */
export class BreakDown_Rate extends Message<BreakDown_Rate> {
  /**
   * It can be sum of hourly rates of suppliers by default
   *
   * @generated from field: int64 rate = 1;
   */
  rate = protoInt64.zero;

  /**
   * @generated from field: int32 hours = 2;
   */
  hours = 0;

  constructor(data?: PartialMessage<BreakDown_Rate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.BreakDown.Rate';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rate', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: 'hours', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BreakDown_Rate {
    return new BreakDown_Rate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BreakDown_Rate {
    return new BreakDown_Rate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BreakDown_Rate {
    return new BreakDown_Rate().fromJsonString(jsonString, options);
  }

  static equals(
    a: BreakDown_Rate | PlainMessage<BreakDown_Rate> | undefined,
    b: BreakDown_Rate | PlainMessage<BreakDown_Rate> | undefined
  ): boolean {
    return proto3.util.equals(BreakDown_Rate, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.BreakDown.Role
 */
export class BreakDown_Role extends Message<BreakDown_Role> {
  /**
   * @generated from field: profiler.tags.v1.Tag tag = 1;
   */
  tag?: Tag;

  /**
   * @generated from field: repeated marketplace.product.v1.Step steps = 2;
   */
  steps: Step[] = [];

  /**
   * @generated from oneof marketplace.product.v1.BreakDown.Role.price
   */
  price:
    | {
        /**
         * @generated from field: int64 fixed = 3;
         */
        value: bigint;
        case: 'fixed';
      }
    | {
        /**
         * @generated from field: marketplace.product.v1.BreakDown.Rate rate = 4;
         */
        value: BreakDown_Rate;
        case: 'rate';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: profiler.user.v1.Profiles assignees = 5;
   */
  assignees?: Profiles;

  constructor(data?: PartialMessage<BreakDown_Role>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.BreakDown.Role';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tag', kind: 'message', T: Tag },
    { no: 2, name: 'steps', kind: 'message', T: Step, repeated: true },
    { no: 3, name: 'fixed', kind: 'scalar', T: 3 /* ScalarType.INT64 */, oneof: 'price' },
    { no: 4, name: 'rate', kind: 'message', T: BreakDown_Rate, oneof: 'price' },
    { no: 5, name: 'assignees', kind: 'message', T: Profiles },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BreakDown_Role {
    return new BreakDown_Role().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BreakDown_Role {
    return new BreakDown_Role().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BreakDown_Role {
    return new BreakDown_Role().fromJsonString(jsonString, options);
  }

  static equals(
    a: BreakDown_Role | PlainMessage<BreakDown_Role> | undefined,
    b: BreakDown_Role | PlainMessage<BreakDown_Role> | undefined
  ): boolean {
    return proto3.util.equals(BreakDown_Role, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.Step
 */
export class Step extends Message<Step> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  /**
   * @generated from field: marketplace.product.v1.Step.Data data = 2;
   */
  data?: Step_Data;

  /**
   * @generated from field: marketplace.product.v1.Step.Status status = 3;
   */
  status?: Step_Status;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 4;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<Step>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
    { no: 2, name: 'data', kind: 'message', T: Step_Data },
    { no: 3, name: 'status', kind: 'message', T: Step_Status },
    { no: 4, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step {
    return new Step().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step | PlainMessage<Step> | undefined,
    b: Step | PlainMessage<Step> | undefined
  ): boolean {
    return proto3.util.equals(Step, a, b);
  }
}

/**
 * @generated from enum marketplace.product.v1.Step.Complexity
 */
export enum Step_Complexity {
  /**
   * @generated from enum value: COMPLEXITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPLEXITY_LOW = 1;
   */
  LOW = 1,

  /**
   * @generated from enum value: COMPLEXITY_MEDIUM = 2;
   */
  MEDIUM = 2,

  /**
   * @generated from enum value: COMPLEXITY_HIGH = 3;
   */
  HIGH = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Step_Complexity)
proto3.util.setEnumType(Step_Complexity, 'marketplace.product.v1.Step.Complexity', [
  { no: 0, name: 'COMPLEXITY_UNSPECIFIED' },
  { no: 1, name: 'COMPLEXITY_LOW' },
  { no: 2, name: 'COMPLEXITY_MEDIUM' },
  { no: 3, name: 'COMPLEXITY_HIGH' },
]);

/**
 * @generated from enum marketplace.product.v1.Step.Fibonacci
 */
export enum Step_Fibonacci {
  /**
   * @generated from enum value: FIBONACCI_UNSPECIFIED = 0;
   */
  FIBONACCI_UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIBONACCI_1 = 1;
   */
  FIBONACCI_1 = 1,

  /**
   * @generated from enum value: FIBONACCI_2 = 2;
   */
  FIBONACCI_2 = 2,

  /**
   * @generated from enum value: FIBONACCI_3 = 3;
   */
  FIBONACCI_3 = 3,

  /**
   * @generated from enum value: FIBONACCI_5 = 5;
   */
  FIBONACCI_5 = 5,

  /**
   * @generated from enum value: FIBONACCI_8 = 8;
   */
  FIBONACCI_8 = 8,

  /**
   * @generated from enum value: FIBONACCI_13 = 13;
   */
  FIBONACCI_13 = 13,

  /**
   * @generated from enum value: FIBONACCI_21 = 21;
   */
  FIBONACCI_21 = 21,

  /**
   * @generated from enum value: FIBONACCI_34 = 34;
   */
  FIBONACCI_34 = 34,

  /**
   * @generated from enum value: FIBONACCI_55 = 55;
   */
  FIBONACCI_55 = 55,

  /**
   * @generated from enum value: FIBONACCI_89 = 89;
   */
  FIBONACCI_89 = 89,
}
// Retrieve enum metadata with: proto3.getEnumType(Step_Fibonacci)
proto3.util.setEnumType(Step_Fibonacci, 'marketplace.product.v1.Step.Fibonacci', [
  { no: 0, name: 'FIBONACCI_UNSPECIFIED' },
  { no: 1, name: 'FIBONACCI_1' },
  { no: 2, name: 'FIBONACCI_2' },
  { no: 3, name: 'FIBONACCI_3' },
  { no: 5, name: 'FIBONACCI_5' },
  { no: 8, name: 'FIBONACCI_8' },
  { no: 13, name: 'FIBONACCI_13' },
  { no: 21, name: 'FIBONACCI_21' },
  { no: 34, name: 'FIBONACCI_34' },
  { no: 55, name: 'FIBONACCI_55' },
  { no: 89, name: 'FIBONACCI_89' },
]);

/**
 * @generated from message marketplace.product.v1.Step.Data
 */
export class Step_Data extends Message<Step_Data> {
  /**
   * @generated from field: string title = 1;
   */
  title = '';

  /**
   * @generated from field: string description = 2;
   */
  description = '';

  /**
   * @generated from field: marketplace.product.v1.Step.Complexity complexity = 3;
   */
  complexity = Step_Complexity.UNSPECIFIED;

  /**
   * @generated from field: marketplace.product.v1.Step.Fibonacci fibonacci = 4;
   */
  fibonacci = Step_Fibonacci.FIBONACCI_UNSPECIFIED;

  /**
   * @generated from field: int32 position = 5;
   */
  position = 0;

  constructor(data?: PartialMessage<Step_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step.Data';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'complexity', kind: 'enum', T: proto3.getEnumType(Step_Complexity) },
    { no: 4, name: 'fibonacci', kind: 'enum', T: proto3.getEnumType(Step_Fibonacci) },
    { no: 5, name: 'position', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step_Data {
    return new Step_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step_Data {
    return new Step_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step_Data {
    return new Step_Data().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step_Data | PlainMessage<Step_Data> | undefined,
    b: Step_Data | PlainMessage<Step_Data> | undefined
  ): boolean {
    return proto3.util.equals(Step_Data, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.Step.Status
 */
export class Step_Status extends Message<Step_Status> {
  /**
   * @generated from field: google.protobuf.Timestamp started = 3;
   */
  started?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp taken = 4;
   */
  taken?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp confirmed = 5;
   */
  confirmed?: Timestamp;

  constructor(data?: PartialMessage<Step_Status>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step.Status';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: 'started', kind: 'message', T: Timestamp },
    { no: 4, name: 'taken', kind: 'message', T: Timestamp },
    { no: 5, name: 'confirmed', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step_Status {
    return new Step_Status().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step_Status {
    return new Step_Status().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step_Status {
    return new Step_Status().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step_Status | PlainMessage<Step_Status> | undefined,
    b: Step_Status | PlainMessage<Step_Status> | undefined
  ): boolean {
    return proto3.util.equals(Step_Status, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.AddStepRequest
 */
export class AddStepRequest extends Message<AddStepRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: int32 tag_id = 2;
   */
  tagId = 0;

  /**
   * @generated from field: marketplace.product.v1.Step.Data data = 3;
   */
  data?: Step_Data;

  constructor(data?: PartialMessage<AddStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.AddStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'tag_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'data', kind: 'message', T: Step_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddStepRequest {
    return new AddStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddStepRequest {
    return new AddStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddStepRequest {
    return new AddStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddStepRequest | PlainMessage<AddStepRequest> | undefined,
    b: AddStepRequest | PlainMessage<AddStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.AddStepResponse
 */
export class AddStepResponse extends Message<AddStepResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  constructor(data?: PartialMessage<AddStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.AddStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddStepResponse {
    return new AddStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddStepResponse {
    return new AddStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddStepResponse {
    return new AddStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddStepResponse | PlainMessage<AddStepResponse> | undefined,
    b: AddStepResponse | PlainMessage<AddStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.RemoveStepRequest
 */
export class RemoveStepRequest extends Message<RemoveStepRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  constructor(data?: PartialMessage<RemoveStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.RemoveStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveStepRequest {
    return new RemoveStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveStepRequest {
    return new RemoveStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveStepRequest {
    return new RemoveStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveStepRequest | PlainMessage<RemoveStepRequest> | undefined,
    b: RemoveStepRequest | PlainMessage<RemoveStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.RemoveStepResponse
 */
export class RemoveStepResponse extends Message<RemoveStepResponse> {
  constructor(data?: PartialMessage<RemoveStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.RemoveStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveStepResponse {
    return new RemoveStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveStepResponse {
    return new RemoveStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveStepResponse {
    return new RemoveStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveStepResponse | PlainMessage<RemoveStepResponse> | undefined,
    b: RemoveStepResponse | PlainMessage<RemoveStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetStepRequest
 */
export class GetStepRequest extends Message<GetStepRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<GetStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStepRequest {
    return new GetStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStepRequest {
    return new GetStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStepRequest {
    return new GetStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetStepRequest | PlainMessage<GetStepRequest> | undefined,
    b: GetStepRequest | PlainMessage<GetStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetStepResponse
 */
export class GetStepResponse extends Message<GetStepResponse> {
  /**
   * @generated from field: marketplace.product.v1.Step step = 1;
   */
  step?: Step;

  constructor(data?: PartialMessage<GetStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step', kind: 'message', T: Step },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStepResponse {
    return new GetStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStepResponse {
    return new GetStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStepResponse {
    return new GetStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetStepResponse | PlainMessage<GetStepResponse> | undefined,
    b: GetStepResponse | PlainMessage<GetStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepRequest
 */
export class UpdateStepRequest extends Message<UpdateStepRequest> {
  /**
   * @generated from field: marketplace.product.v1.Step step = 1;
   */
  step?: Step;

  constructor(data?: PartialMessage<UpdateStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step', kind: 'message', T: Step },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStepRequest {
    return new UpdateStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStepRequest {
    return new UpdateStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStepRequest {
    return new UpdateStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepRequest | PlainMessage<UpdateStepRequest> | undefined,
    b: UpdateStepRequest | PlainMessage<UpdateStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepResponse
 */
export class UpdateStepResponse extends Message<UpdateStepResponse> {
  constructor(data?: PartialMessage<UpdateStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStepResponse {
    return new UpdateStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStepResponse {
    return new UpdateStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateStepResponse {
    return new UpdateStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepResponse | PlainMessage<UpdateStepResponse> | undefined,
    b: UpdateStepResponse | PlainMessage<UpdateStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepsPositionsRequest
 */
export class UpdateStepsPositionsRequest extends Message<UpdateStepsPositionsRequest> {
  /**
   * @generated from field: map<int32, grpc.type.v1.UUID> positions = 3;
   */
  positions: { [key: number]: UUID } = {};

  constructor(data?: PartialMessage<UpdateStepsPositionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepsPositionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 3,
      name: 'positions',
      kind: 'map',
      K: 5 /* ScalarType.INT32 */,
      V: { kind: 'message', T: UUID },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateStepsPositionsRequest {
    return new UpdateStepsPositionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsRequest {
    return new UpdateStepsPositionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsRequest {
    return new UpdateStepsPositionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepsPositionsRequest | PlainMessage<UpdateStepsPositionsRequest> | undefined,
    b: UpdateStepsPositionsRequest | PlainMessage<UpdateStepsPositionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepsPositionsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepsPositionsResponse
 */
export class UpdateStepsPositionsResponse extends Message<UpdateStepsPositionsResponse> {
  constructor(data?: PartialMessage<UpdateStepsPositionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepsPositionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateStepsPositionsResponse {
    return new UpdateStepsPositionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsResponse {
    return new UpdateStepsPositionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsResponse {
    return new UpdateStepsPositionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepsPositionsResponse | PlainMessage<UpdateStepsPositionsResponse> | undefined,
    b: UpdateStepsPositionsResponse | PlainMessage<UpdateStepsPositionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepsPositionsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.AddAssigneesRequest
 */
export class AddAssigneesRequest extends Message<AddAssigneesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: int32 role_id = 2;
   */
  roleId = 0;

  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 3;
   */
  profileIds?: UUIDS;

  constructor(data?: PartialMessage<AddAssigneesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.AddAssigneesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'profile_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddAssigneesRequest {
    return new AddAssigneesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddAssigneesRequest {
    return new AddAssigneesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddAssigneesRequest {
    return new AddAssigneesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddAssigneesRequest | PlainMessage<AddAssigneesRequest> | undefined,
    b: AddAssigneesRequest | PlainMessage<AddAssigneesRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddAssigneesRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.AddAssigneesResponse
 */
export class AddAssigneesResponse extends Message<AddAssigneesResponse> {
  constructor(data?: PartialMessage<AddAssigneesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.AddAssigneesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddAssigneesResponse {
    return new AddAssigneesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddAssigneesResponse {
    return new AddAssigneesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddAssigneesResponse {
    return new AddAssigneesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddAssigneesResponse | PlainMessage<AddAssigneesResponse> | undefined,
    b: AddAssigneesResponse | PlainMessage<AddAssigneesResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddAssigneesResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteAssigneesRequest
 */
export class DeleteAssigneesRequest extends Message<DeleteAssigneesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: int32 role_id = 2;
   */
  roleId = 0;

  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 3;
   */
  profileIds?: UUIDS;

  constructor(data?: PartialMessage<DeleteAssigneesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteAssigneesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'profile_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteAssigneesRequest {
    return new DeleteAssigneesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteAssigneesRequest {
    return new DeleteAssigneesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAssigneesRequest {
    return new DeleteAssigneesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteAssigneesRequest | PlainMessage<DeleteAssigneesRequest> | undefined,
    b: DeleteAssigneesRequest | PlainMessage<DeleteAssigneesRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAssigneesRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteAssigneesResponse
 */
export class DeleteAssigneesResponse extends Message<DeleteAssigneesResponse> {
  constructor(data?: PartialMessage<DeleteAssigneesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteAssigneesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteAssigneesResponse {
    return new DeleteAssigneesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteAssigneesResponse {
    return new DeleteAssigneesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAssigneesResponse {
    return new DeleteAssigneesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteAssigneesResponse | PlainMessage<DeleteAssigneesResponse> | undefined,
    b: DeleteAssigneesResponse | PlainMessage<DeleteAssigneesResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAssigneesResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateBreakDownRequest
 */
export class CreateBreakDownRequest extends Message<CreateBreakDownRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: profiler.tags.v1.Tag role = 2;
   */
  role?: Tag;

  constructor(data?: PartialMessage<CreateBreakDownRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateBreakDownRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'role', kind: 'message', T: Tag },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateBreakDownRequest {
    return new CreateBreakDownRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateBreakDownRequest {
    return new CreateBreakDownRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateBreakDownRequest {
    return new CreateBreakDownRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateBreakDownRequest | PlainMessage<CreateBreakDownRequest> | undefined,
    b: CreateBreakDownRequest | PlainMessage<CreateBreakDownRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateBreakDownRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateBreakDownResponse
 */
export class CreateBreakDownResponse extends Message<CreateBreakDownResponse> {
  constructor(data?: PartialMessage<CreateBreakDownResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateBreakDownResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateBreakDownResponse {
    return new CreateBreakDownResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateBreakDownResponse {
    return new CreateBreakDownResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateBreakDownResponse {
    return new CreateBreakDownResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateBreakDownResponse | PlainMessage<CreateBreakDownResponse> | undefined,
    b: CreateBreakDownResponse | PlainMessage<CreateBreakDownResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateBreakDownResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetBreakDownRequest
 */
export class GetBreakDownRequest extends Message<GetBreakDownRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<GetBreakDownRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetBreakDownRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBreakDownRequest {
    return new GetBreakDownRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBreakDownRequest {
    return new GetBreakDownRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetBreakDownRequest {
    return new GetBreakDownRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetBreakDownRequest | PlainMessage<GetBreakDownRequest> | undefined,
    b: GetBreakDownRequest | PlainMessage<GetBreakDownRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetBreakDownRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetBreakDownResponse
 */
export class GetBreakDownResponse extends Message<GetBreakDownResponse> {
  /**
   * @generated from field: marketplace.product.v1.BreakDown break_down = 1;
   */
  breakDown?: BreakDown;

  constructor(data?: PartialMessage<GetBreakDownResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetBreakDownResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'break_down', kind: 'message', T: BreakDown },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBreakDownResponse {
    return new GetBreakDownResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBreakDownResponse {
    return new GetBreakDownResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetBreakDownResponse {
    return new GetBreakDownResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetBreakDownResponse | PlainMessage<GetBreakDownResponse> | undefined,
    b: GetBreakDownResponse | PlainMessage<GetBreakDownResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetBreakDownResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateBreakDownRequest
 */
export class UpdateBreakDownRequest extends Message<UpdateBreakDownRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: int32 role_id = 2;
   */
  roleId = 0;

  /**
   * @generated from oneof marketplace.product.v1.UpdateBreakDownRequest.price
   */
  price:
    | {
        /**
         * @generated from field: int64 fixed = 3;
         */
        value: bigint;
        case: 'fixed';
      }
    | {
        /**
         * @generated from field: marketplace.product.v1.BreakDown.Rate rate = 4;
         */
        value: BreakDown_Rate;
        case: 'rate';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UpdateBreakDownRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateBreakDownRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'fixed', kind: 'scalar', T: 3 /* ScalarType.INT64 */, oneof: 'price' },
    { no: 4, name: 'rate', kind: 'message', T: BreakDown_Rate, oneof: 'price' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateBreakDownRequest {
    return new UpdateBreakDownRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownRequest {
    return new UpdateBreakDownRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownRequest {
    return new UpdateBreakDownRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateBreakDownRequest | PlainMessage<UpdateBreakDownRequest> | undefined,
    b: UpdateBreakDownRequest | PlainMessage<UpdateBreakDownRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateBreakDownRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateBreakDownResponse
 */
export class UpdateBreakDownResponse extends Message<UpdateBreakDownResponse> {
  constructor(data?: PartialMessage<UpdateBreakDownResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateBreakDownResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateBreakDownResponse {
    return new UpdateBreakDownResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownResponse {
    return new UpdateBreakDownResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownResponse {
    return new UpdateBreakDownResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateBreakDownResponse | PlainMessage<UpdateBreakDownResponse> | undefined,
    b: UpdateBreakDownResponse | PlainMessage<UpdateBreakDownResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateBreakDownResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteBreakDownRoleRequest
 */
export class DeleteBreakDownRoleRequest extends Message<DeleteBreakDownRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: int32 role_id = 2;
   */
  roleId = 0;

  constructor(data?: PartialMessage<DeleteBreakDownRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteBreakDownRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteBreakDownRoleRequest {
    return new DeleteBreakDownRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteBreakDownRoleRequest {
    return new DeleteBreakDownRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteBreakDownRoleRequest {
    return new DeleteBreakDownRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteBreakDownRoleRequest | PlainMessage<DeleteBreakDownRoleRequest> | undefined,
    b: DeleteBreakDownRoleRequest | PlainMessage<DeleteBreakDownRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteBreakDownRoleRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteBreakDownRoleResponse
 */
export class DeleteBreakDownRoleResponse extends Message<DeleteBreakDownRoleResponse> {
  constructor(data?: PartialMessage<DeleteBreakDownRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteBreakDownRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteBreakDownRoleResponse {
    return new DeleteBreakDownRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteBreakDownRoleResponse {
    return new DeleteBreakDownRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteBreakDownRoleResponse {
    return new DeleteBreakDownRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteBreakDownRoleResponse | PlainMessage<DeleteBreakDownRoleResponse> | undefined,
    b: DeleteBreakDownRoleResponse | PlainMessage<DeleteBreakDownRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteBreakDownRoleResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateBreakDownRoleRequest
 */
export class UpdateBreakDownRoleRequest extends Message<UpdateBreakDownRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: int32 old_role_id = 2;
   */
  oldRoleId = 0;

  /**
   * @generated from field: int32 new_role_id = 3;
   */
  newRoleId = 0;

  constructor(data?: PartialMessage<UpdateBreakDownRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateBreakDownRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'old_role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'new_role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateBreakDownRoleRequest {
    return new UpdateBreakDownRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownRoleRequest {
    return new UpdateBreakDownRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownRoleRequest {
    return new UpdateBreakDownRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateBreakDownRoleRequest | PlainMessage<UpdateBreakDownRoleRequest> | undefined,
    b: UpdateBreakDownRoleRequest | PlainMessage<UpdateBreakDownRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateBreakDownRoleRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateBreakDownRoleResponse
 */
export class UpdateBreakDownRoleResponse extends Message<UpdateBreakDownRoleResponse> {
  constructor(data?: PartialMessage<UpdateBreakDownRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateBreakDownRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateBreakDownRoleResponse {
    return new UpdateBreakDownRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownRoleResponse {
    return new UpdateBreakDownRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateBreakDownRoleResponse {
    return new UpdateBreakDownRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateBreakDownRoleResponse | PlainMessage<UpdateBreakDownRoleResponse> | undefined,
    b: UpdateBreakDownRoleResponse | PlainMessage<UpdateBreakDownRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateBreakDownRoleResponse, a, b);
  }
}
