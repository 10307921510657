import { useListMembers } from '@services/queries/MembershipsQueries';
import { ListMembersRequest } from '@proto/iam/membership/v1/membership_pb';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '@services/queries/ProductsQueries';
import { mapMembershipsById } from '@pages/Marketplace/Feed/config';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useAddAssignees, useDeleteAssignees } from '@services/queries/StepsQueries';
import {
  AddAssigneesRequest,
  DeleteAssigneesRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import { Profiles } from '@proto/profiler/user/v1/profile_pb';
import Typography from '@mui/material/Typography';

const AddAssignees = ({
  roleId,
  defaultAssignees,
}: {
  roleId: number;
  defaultAssignees?: Profiles;
}) => {
  const { productId } = useParams();
  const { data: product } = useGetProduct(productId as string);
  const { data: members } = useListMembers(
    new ListMembersRequest({
      by: {
        case: 'entityIds',
        value: new UUIDS({ values: [product?.product?.owner?.id?.value as string] }),
      },
    }),
    !!product?.product?.owner?.id?.value
  );
  const transformedMembers = mapMembershipsById(members?.memberships);
  const { mutate: addAssignees } = useAddAssignees();
  const { mutate: deleteAssignees } = useDeleteAssignees();

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Typography variant="subtitle2" mb={1}>
        Assignes
      </Typography>
      {Object.values(transformedMembers)[0] ? (
        <Autocomplete
          multiple
          id="tags-standard"
          options={Object.values(transformedMembers)[0]}
          getOptionLabel={(option) => option?.name?.nickName || ''}
          defaultValue={defaultAssignees?.profiles}
          isOptionEqualToValue={(option, value) =>
            option.profileId?.value === value.profileId?.value
          }
          onChange={(e, value, reason, option) => {
            console.log({ value, reason, option });
            if (reason === 'removeOption') {
              deleteAssignees(
                new DeleteAssigneesRequest({
                  productId: new UUID({ value: productId }),
                  roleId,
                  profileIds: new UUIDS({ values: [option?.option?.profileId?.value as string] }),
                })
              );
            }

            if (reason === 'selectOption') {
              addAssignees(
                new AddAssigneesRequest({
                  productId: new UUID({ value: productId }),
                  roleId,
                  profileIds: new UUIDS({ values: [option?.option?.profileId?.value as string] }),
                })
              );
            }
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Add ssignes" />
          )}
        />
      ) : null}
    </Box>
  );
};

export default AddAssignees;
