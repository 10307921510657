// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/break_down.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AddAssigneesRequest,
  AddAssigneesResponse,
  AddStepRequest,
  AddStepResponse,
  CreateBreakDownRequest,
  CreateBreakDownResponse,
  DeleteAssigneesRequest,
  DeleteAssigneesResponse,
  DeleteBreakDownRoleRequest,
  DeleteBreakDownRoleResponse,
  GetBreakDownResponse,
  GetStepResponse,
  RemoveStepRequest,
  RemoveStepResponse,
  UpdateBreakDownRequest,
  UpdateBreakDownResponse,
  UpdateBreakDownRoleRequest,
  UpdateBreakDownRoleResponse,
  UpdateStepRequest,
  UpdateStepResponse,
  UpdateStepsPositionsRequest,
  UpdateStepsPositionsResponse,
} from '../../../marketplace/product/v1/break_down_pb.js';
import { MethodKind } from '@bufbuild/protobuf';
import { GetBreakDownRequest, GetStepRequest } from './break_down_pb.js';

/**
 * @generated from service api.marketplace.v1.BreakDownAPI
 */
export const BreakDownAPI = {
  typeName: 'api.marketplace.v1.BreakDownAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.CreateBreakDown
     */
    createBreakDown: {
      name: 'CreateBreakDown',
      I: CreateBreakDownRequest,
      O: CreateBreakDownResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.GetBreakDown
     */
    getBreakDown: {
      name: 'GetBreakDown',
      I: GetBreakDownRequest,
      O: GetBreakDownResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.UpdateBreakDown
     */
    updateBreakDown: {
      name: 'UpdateBreakDown',
      I: UpdateBreakDownRequest,
      O: UpdateBreakDownResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.UpdateBreakDownRole
     */
    updateBreakDownRole: {
      name: 'UpdateBreakDownRole',
      I: UpdateBreakDownRoleRequest,
      O: UpdateBreakDownRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.DeleteBreakDownRole
     */
    deleteBreakDownRole: {
      name: 'DeleteBreakDownRole',
      I: DeleteBreakDownRoleRequest,
      O: DeleteBreakDownRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.AddStep
     */
    addStep: {
      name: 'AddStep',
      I: AddStepRequest,
      O: AddStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.RemoveStep
     */
    removeStep: {
      name: 'RemoveStep',
      I: RemoveStepRequest,
      O: RemoveStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.GetStep
     */
    getStep: {
      name: 'GetStep',
      I: GetStepRequest,
      O: GetStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.UpdateStep
     */
    updateStep: {
      name: 'UpdateStep',
      I: UpdateStepRequest,
      O: UpdateStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.UpdateStepsPositions
     */
    updateStepsPositions: {
      name: 'UpdateStepsPositions',
      I: UpdateStepsPositionsRequest,
      O: UpdateStepsPositionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.AddAssignees
     */
    addAssignees: {
      name: 'AddAssignees',
      I: AddAssigneesRequest,
      O: AddAssigneesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.DeleteAssignees
     */
    deleteAssignees: {
      name: 'DeleteAssignees',
      I: DeleteAssigneesRequest,
      O: DeleteAssigneesResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
