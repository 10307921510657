import React from 'react';
import Box from '@mui/material/Box';
import { Badge, Popover } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Typography from '@mui/material/Typography';
import { useDeleteEvent } from '@services/queries/Chat';
import { Link } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import { useStreamStore } from '../../zustand/subscribe';

const NotificationPopover = () => {
  const { bellNotifications, clearBellNotifications } = useStreamStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { mutate: deleteEvent, error } = useDeleteEvent();

  const handleClose = () => {
    // clearBellNotifications();
    setAnchorEl(null);
    // deleteEvent(
    //   new UUIDS({
    //     values: bellNotifications?.map((event) => event?.id?.value as string),
    //   })
    // );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Badge badgeContent={bellNotifications?.length} color="primary" onClick={handleClick}>
        {bellNotifications?.length === 0 ? (
          <NotificationsIcon />
        ) : (
          <NotificationsActiveIcon className="BellAnimation" />
        )}
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box py={3} px={4} className="flex gap-4 flex-col">
          {bellNotifications?.length === 0 ? (
            <Typography>You have no notifications.</Typography>
          ) : (
            <Box className="flex gap-2 flex-col">
              {bellNotifications.map((event) => {
                return <Typography key={event.subject}>{event.message}</Typography>;
              })}
            </Box>
          )}
          {bellNotifications?.length > 0 && (
            <Link to={ROUTES.NOTIFICATIONS} className="NavLink">
              <Typography textAlign="center" className="hover:underline">
                Show more
              </Typography>
            </Link>
          )}
        </Box>
      </Popover>
    </Box>
  );
};
export default NotificationPopover;
