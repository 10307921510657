import * as React from 'react';
import { ReactNode } from 'react';
import SendEvaluationFeedBackForm from '@pages/Evaluation/SendEvaluationFeedBackForm';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  useAcceptEvaluationFeedback,
  useGetEvaluation,
  useSendEvaluationFeedback,
} from '@services/queries/MarketplaceQueries';
import { Link, useParams } from 'react-router-dom';
import { Timestamp } from '@bufbuild/protobuf';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import { Typography } from '@mui/material';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import MDRenderer from '@components/Form/controllers/WysiwygController/MDRenderer';
import CustomAvatar from '@components/Avatar/Avatar';
import { Evaluation_Profiles } from '@proto/marketplace/demand/v1/evaluation_pb';
import Divider from '@mui/material/Divider';

function evaluationIsHappened(timestamp: Timestamp | undefined): boolean {
  if (!timestamp) {
    return false;
  }

  if (!isInvalidTimestamp(timestamp?.toJsonString())) {
    return false;
  }

  const eventDate = new Date(Number(timestamp.seconds) * 1000 + timestamp.nanos / 1e6);
  const now = new Date();
  const timeDifferenceMs = eventDate.getTime() - now.getTime();

  return timeDifferenceMs <= 0;
}

function formatTimestampToDateString(timestamp: Timestamp | undefined): ReactNode | null {
  if (!timestamp) {
    return null;
  }

  if (!isInvalidTimestamp(timestamp?.toJsonString())) {
    return <Typography className="font-bold">Not scheduled</Typography>;
  }

  const date = timestamp.toDate();

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const weekday = date.toLocaleString('en-US', { weekday: 'long' });
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const now = new Date();
  if (date > now) {
    return (
      <Typography className="font-bold">
        Start at {`${hours}:${minutes}  ${weekday} ${day}.${month}`}
      </Typography>
    );
  }
  return (
    <Typography className="font-bold">
      Event occurred at {`${hours}:${minutes}  ${weekday} ${day}.${month}`}
    </Typography>
  );
}

const EvaluationPage = () => {
  const { evaluationId } = useParams();
  const { mutate: sendEvaluationFeedback } = useSendEvaluationFeedback();
  const { mutate: acceptEvaluation } = useAcceptEvaluationFeedback();
  const { data: evaluationResponse } = useGetEvaluation({
    evaluationId: evaluationId as string,
  });
  const evaluation = evaluationResponse?.evaluation;
  const isInterviewer = evaluation?.participants.case === 'interviewer';
  const isManager = evaluation?.participants.case === 'profiles';

  return (
    <Box px={10} py={6}>
      {evaluation ? (
        <Box
          display="flex"
          className="max-w-[1280px] w-full m-auto"
          flexDirection="column"
          justifyContent="center"
          gap={2}
        >
          <Box className="flex     justify-between">
            <Box className="flex gap-2 items-center">
              {formatTimestampToDateString(evaluation?.status?.scheduledAt)}
            </Box>
            {evaluation.participants.case === 'profiles' && (
              <Box className="flex items-center gap-8">
                <Link
                  className="NavLink"
                  to={`/user/${
                    (evaluation?.participants?.value as Evaluation_Profiles)?.candidate?.profileId
                      ?.value
                  }`}
                >
                  <Box className="flex gap-2 items-center">
                    <CustomAvatar
                      alt={
                        (evaluation?.participants.value as Evaluation_Profiles).candidate?.name
                          ?.nickName || 'User'
                      }
                      avatarId={
                        (evaluation?.participants.value as Evaluation_Profiles).candidate?.name
                          ?.nickName || 'User'
                      }
                      profileId={
                        (evaluation?.participants.value as Evaluation_Profiles).candidate?.name
                          ?.nickName || 'User'
                      }
                    />
                    <Box>
                      <Typography className="text-[#e0e0e0]" fontSize={14}>
                        Candidate
                      </Typography>
                      <Typography className="font-bold">
                        {
                          (evaluation?.participants.value as Evaluation_Profiles).candidate?.name
                            ?.nickName
                        }
                      </Typography>
                    </Box>
                  </Box>
                </Link>
                <Divider color="#ffffff" orientation="vertical" />
                <Link
                  className="NavLink"
                  to={`/user/${
                    (evaluation?.participants?.value as Evaluation_Profiles)?.interviewer?.profileId
                      ?.value
                  }`}
                >
                  <Box className="flex gap-2 items-center">
                    <CustomAvatar
                      alt={
                        (evaluation?.participants.value as Evaluation_Profiles).interviewer?.name
                          ?.nickName || 'User'
                      }
                      avatarId={
                        (evaluation?.participants.value as Evaluation_Profiles).interviewer?.name
                          ?.nickName || 'User'
                      }
                      profileId={
                        (evaluation?.participants.value as Evaluation_Profiles).interviewer?.name
                          ?.nickName || 'User'
                      }
                    />
                    <Box>
                      <Typography className="text-[#e0e0e0]" fontSize={14}>
                        Interviewer
                      </Typography>
                      <Typography className="font-bold">
                        {
                          (evaluation?.participants.value as Evaluation_Profiles).interviewer?.name
                            ?.nickName
                        }
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Box>
            )}
          </Box>

          {!isInvalidTimestamp(evaluation?.feedback?.status?.sent?.toJsonString()) &&
            isInterviewer &&
            evaluationIsHappened(evaluation?.status?.scheduledAt) && (
              <SendEvaluationFeedBackForm
                evaluation={evaluation}
                evaluationId={evaluation?.evaluationId?.value as string}
              />
            )}

          {isInterviewer && evaluationIsHappened(evaluation?.status?.scheduledAt) && (
            <Box className="flex gap-2">
              {isInvalidTimestamp(evaluation.feedback?.status?.sent?.toJsonString()) &&
                evaluation.feedback?.text && <MDRenderer content={evaluation.feedback?.text} />}
            </Box>
          )}

          <Box className="flex justify-end">
            {!isInvalidTimestamp(evaluation?.feedback?.status?.sent?.toJsonString()) &&
            evaluation?.feedback?.text?.length &&
            isInterviewer ? (
              <Button
                variant="contained"
                onClick={() =>
                  sendEvaluationFeedback({ evaluationId: evaluation.evaluationId?.value as string })
                }
              >
                Send Evaluation
              </Button>
            ) : null}
          </Box>

          {isManager && evaluation?.feedback && <MDRenderer content={evaluation?.feedback?.text} />}

          <Box className="flex justify-end">
            {evaluation?.feedback &&
              isManager &&
              !isInvalidTimestamp(evaluation?.feedback?.status?.accepted?.toJsonString()) && (
                <Button
                  variant="contained"
                  onClick={() =>
                    acceptEvaluation({
                      evaluationId: evaluation.evaluationId as UUID,
                      interviewId: evaluation.interviewId as UUID,
                    })
                  }
                >
                  Accept Feedback
                </Button>
              )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
export default EvaluationPage;
