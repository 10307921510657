import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import SearchProfile from '@components/SearchProfiles/SearchProfile';
import React from 'react';
import UserCard from '@components/AddMemberDialog/UserCard';
import { useProfiler } from '@store/profiler';
import SendInvitesButton from '@components/Invites/SendInvites';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import LoadingButton from '@mui/lab/LoadingButton';
import { SendInviteByEmailSchema } from '@components/AddMemberDialog/AddMemberDialog';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { useInviteCandidates } from '@services/queries/MarketplaceQueries';

type AddCandidateProps = {
  isOpen: boolean;
  handleClose: () => void;
  entityId?: string;
  objectName?: string;
};

export const AddCandidateDialog = (props: AddCandidateProps) => {
  const { isOpen, handleClose, entityId, objectName } = props;
  const { selectedProfile, sendInvitesByEmails } = useProfiler();
  const [inviteType, setInviteType] = React.useState<'email' | 'user'>('user');
  const { mutate: inviteCandidates, error } = useInviteCandidates();

  const sendInvite = () => {
    if (selectedProfile && entityId) {
      inviteCandidates({
        interviewId: entityId,
        profileIds: [selectedProfile?.profileId?.value as string],
      });
    }
  };

  const handleSubmit = () => {
    sendInvite();
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box display="flex" flexDirection="column" px={2} py={4} gap={2} sx={{ width: '600px' }}>
        <Typography textAlign="center" variant="subtitle1">
          Add Candidate To {objectName}
        </Typography>
        <Box display="flex" justifyContent="center">
          {inviteType === 'user' ? (
            <Chip
              size="small"
              variant="outlined"
              onClick={() => setInviteType('email')}
              label="Send invitation by e-mail"
            />
          ) : (
            <Chip
              size="small"
              variant="outlined"
              onClick={() => setInviteType('user')}
              label="Find a user on our platform"
            />
          )}
        </Box>

        {inviteType === 'user' ? (
          <Box display="flex" flexDirection="column" gap={1}>
            <SearchProfile />
            {selectedProfile && <UserCard userProfile={selectedProfile} />}
            {selectedProfile ? <SendInvitesButton handleSubmit={handleSubmit} /> : null}
          </Box>
        ) : (
          <Form
            width="100%"
            display="flex"
            alignSelf="center"
            flexDirection="column"
            formProps={{ defaultValues: { email: '' } }}
            submitHandler={(data) => {
              sendInvitesByEmails({
                id: entityId || '',
                type: Entity_Type.INTERVIEW,
                emails: [data.email],
              });
              handleClose();
            }}
            resolverSchema={SendInviteByEmailSchema}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <InputController name="email" label="Email" />
              <Box display="flex" justifyContent="center">
                <LoadingButton type="submit" variant="contained">
                  Send Invite
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Box>
    </Dialog>
  );
};
