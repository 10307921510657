// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file grpc/type/v1/ip.proto (package grpc.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message grpc.type.v1.IpV4
 */
export class IpV4 extends Message<IpV4> {
  /**
   * @generated from field: bytes ip = 1;
   */
  ip = new Uint8Array(0);

  /**
   * @generated from field: bytes mask = 2;
   */
  mask = new Uint8Array(0);

  constructor(data?: PartialMessage<IpV4>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.IpV4';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ip', kind: 'scalar', T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: 'mask', kind: 'scalar', T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IpV4 {
    return new IpV4().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IpV4 {
    return new IpV4().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IpV4 {
    return new IpV4().fromJsonString(jsonString, options);
  }

  static equals(
    a: IpV4 | PlainMessage<IpV4> | undefined,
    b: IpV4 | PlainMessage<IpV4> | undefined
  ): boolean {
    return proto3.util.equals(IpV4, a, b);
  }
}
