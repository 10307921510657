// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file api/marketplace/v1/dashboad.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message api.marketplace.v1.GetDashboardRequest
 */
export class GetDashboardRequest extends Message<GetDashboardRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID dashboard_id = 1;
   */
  dashboardId?: UUID;

  constructor(data?: PartialMessage<GetDashboardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.marketplace.v1.GetDashboardRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboard_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDashboardRequest {
    return new GetDashboardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDashboardRequest {
    return new GetDashboardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetDashboardRequest {
    return new GetDashboardRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetDashboardRequest | PlainMessage<GetDashboardRequest> | undefined,
    b: GetDashboardRequest | PlainMessage<GetDashboardRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetDashboardRequest, a, b);
  }
}
