import React from 'react';
import Box from '@mui/material/Box';

interface ScrollableContainerProps {
  height?: string; // Висота у vh
  maxHeight?: string; // Максимальна висота у px
  children: React.ReactNode; // React елементи які будуть відображені всередині контейнера
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
  height,
  children,
  maxHeight,
}) => {
  return (
    <Box
      sx={{
        maxHeight: `${maxHeight}`,
        height: `${height}`,
        overflow: 'auto',
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default ScrollableContainer;
