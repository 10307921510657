// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/profiler/v1/tags.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AddTagsRequest,
  AddTagsResponse,
  GetTagRequest,
  GetTagResponse,
  ListObjectsTagsRequest,
  ListObjectsTagsResponse,
  ListTagsRequest,
  ListTagsResponse,
  RemoveTagsRequest,
  RemoveTagsResponse,
  SearchEntitiesRequest,
  SearchEntitiesResponse,
  SetObjectsTagsStatusRequest,
  SetObjectsTagsStatusResponse,
  SetObjectTagsStatusRequest,
  SetObjectTagsStatusResponse,
} from '../../../profiler/tags/v1/tags_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.profiler.v1.TagsAPI
 */
export const TagsAPI = {
  typeName: 'api.profiler.v1.TagsAPI',
  methods: {
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.ListObjectsTags
     */
    listObjectsTags: {
      name: 'ListObjectsTags',
      I: ListObjectsTagsRequest,
      O: ListObjectsTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.AddTags
     */
    addTags: {
      name: 'AddTags',
      I: AddTagsRequest,
      O: AddTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.RemoveTags
     */
    removeTags: {
      name: 'RemoveTags',
      I: RemoveTagsRequest,
      O: RemoveTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.SetObjectTagsStatus
     */
    setObjectTagsStatus: {
      name: 'SetObjectTagsStatus',
      I: SetObjectTagsStatusRequest,
      O: SetObjectTagsStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.SetObjectsTagsStatus
     */
    setObjectsTagsStatus: {
      name: 'SetObjectsTagsStatus',
      I: SetObjectsTagsStatusRequest,
      O: SetObjectsTagsStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.ListTags
     */
    listTags: {
      name: 'ListTags',
      I: ListTagsRequest,
      O: ListTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.GetTag
     */
    getTag: {
      name: 'GetTag',
      I: GetTagRequest,
      O: GetTagResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.TagsAPI.SearchEntities
     */
    searchEntities: {
      name: 'SearchEntities',
      I: SearchEntitiesRequest,
      O: SearchEntitiesResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
