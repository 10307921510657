import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KanbanDemandCard from '@pages/Marketplace/Dashboards/KanbanDemandCard';
import { sortDemandsByDate } from '@pages/Marketplace/Dashboards/config';
import CreateDemandButton from '@pages/Marketplace/ManageDemands/CreateDemandButton';
import { useMarketplace } from '@store/marketplace';
import { Demand, Demand_Status_Status } from '@proto/marketplace/demand/v1/demand_pb';
import { TDemandRecords } from '@store/marketplace/helpers';

type KanbanSectionProps = {
  demands?: TDemandRecords;
  status: number;
};

const sectionTitles = {
  1: 'Draft',
  2: 'Active',
  3: 'In progress',
  4: 'Done',
  5: 'Cancelled',
};

const KanbanSection = (props: KanbanSectionProps) => {
  const { demands, status } = props;
  const { displayListType } = useMarketplace();
  const contractList: Demand[] = demands?.contracts ? Object.values(demands.contracts) : [];
  const interviewsList: Demand[] = demands?.interviews ? Object.values(demands.interviews) : [];
  const combinedList: Demand[] = [...contractList, ...interviewsList];

  let displayList;

  if (displayListType === 'all')
    displayList = sortDemandsByDate(combinedList).filter(
      (demand) => demand?.status?.status === status
    );
  if (displayListType === 'contracts')
    displayList = sortDemandsByDate(contractList).filter(
      (demand) => demand?.status?.status === status
    );
  if (displayListType === 'interviews')
    displayList = sortDemandsByDate(interviewsList).filter(
      (demand) => demand?.status?.status === status
    );

  return (
    <Box width="100%" height="80vh" overflow="auto">
      <Box
        border="1px solid rgba(255, 255, 255, 0.12)"
        borderRadius="5px"
        display="flex"
        flexDirection="column"
        p={1}
        gap={2}
        overflow="auto"
        height="100%"
      >
        <Box minHeight="65px" display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2" textTransform="uppercase">
            {sectionTitles[status as keyof typeof sectionTitles]}
          </Typography>
          {status === Demand_Status_Status.DRAFT && <CreateDemandButton />}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          {displayList &&
            displayList.map((demand) => {
              return <KanbanDemandCard demand={demand} />;
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default KanbanSection;
