import { create } from 'zustand/react';

interface RefetchState {
  isFetchedListEvaluation: boolean;
  isFetchedListSquads: boolean;
  setIsFetchedListSquads: (isFetchedListSquads: boolean) => void;
  isFetchedListOrgs: boolean;
  setIsFetchedListOrgs: (isFetchedListOrgs: boolean) => void;
  setIsFetchedListEvaluation: (isFetchedListEvaluation: boolean) => void;
}

export const useRefetch = create<RefetchState>((set, get) => ({
  isFetchedListEvaluation: true,
  isFetchedListSquads: true,
  isFetchedListOrgs: true,
  setIsFetchedListSquads: (isFetchedListSquads: boolean) => set({ isFetchedListSquads }),
  setIsFetchedListOrgs: (isFetchedListOrgs: boolean) => set({ isFetchedListOrgs }),
  setIsFetchedListEvaluation: (isFetchedListEvaluation: boolean) =>
    set({ isFetchedListEvaluation }),
}));
