import { create } from 'zustand';
// Окремий хук для синхронізації з URL
import { ArrayParam, withDefault } from 'use-query-params';

export const OrgFilterParam = withDefault(ArrayParam, []);

interface OrgsState {
  searchedOrgsName: string | undefined;
  setSearchedOrgsName: (name: string | undefined) => void;
}

export const useOrgsStore = create<OrgsState>((set) => ({
  searchedOrgsName: undefined,
  setSearchedOrgsName: (name) => set({ searchedOrgsName: name }),
}));
