import React, { Suspense, useCallback, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useMarketplace } from '@store/marketplace';
import Card from '@components/Card/Card';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import ContractorSelect from '@pages/Marketplace/Feed/ContractorSelect';
import {
  useApplyAsInterviewer,
  useGetInterviewerrAvailability,
  useListAppliedDemands,
  useListFeedItemsByDemand,
} from '@services/queries/MarketplaceQueries';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { demandsTypes } from '@pages/Marketplace/Feed/FeedPage';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import CustomAvatar from '@components/Avatar/Avatar';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import CalendarComponentForInterviewer from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponentForInterviewer';
import { useDialog } from '@components/hooks/useDialog';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { SortedDemandsBySupply } from '@services/api/marketplace/config';
import DemandCardHeader from '@pages/Marketplace/Feed/DemandCardHeader';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';

interface PreAppliedDemandCardProps {
  demand: Demand;
  isPreApplied?: boolean;
  showActiveState?: boolean;
}

export const extractInterviews = (demands: SortedDemandsBySupply | undefined) => {
  if (!demands) return [];

  const interviewsApplied = demands?.applied?.interviews || {};
  const interviewsApproved = demands?.approved?.interviews || {};
  return [...Object.values(interviewsApplied), ...Object.values(interviewsApproved)];
};

const PreAppliedDemandCard: React.FC<PreAppliedDemandCardProps> = React.memo(
  ({ demand, isPreApplied, showActiveState }) => {
    const { demandIdFromUrl } = useMarketplace();
    const demandId = getDemandId(demand);
    const { mutate: applyAsInterviewer } = useApplyAsInterviewer();

    const entity = useMemo(() => {
      return demandIdFromUrl
        ? new Entity({
            id: new UUID({ value: demandIdFromUrl.demandId }),
            type: demandsTypes[demandIdFromUrl.demandType as keyof typeof demandsTypes],
          })
        : undefined;
    }, [demandIdFromUrl]);

    const [matchedTags, setMatchedTags] = React.useState<{ tags: any; traits: any } | null>(null);
    const { data: feedByContract } = useListFeedItemsByDemand(entity);
    const { isOpen, handleOpen, handleClose } = useDialog();
    const { data: appliedDemands } = useListAppliedDemands({ refetch: false });
    const { data: profile } = useGetMyProfile();
    const { data: slots } = useGetInterviewerrAvailability({
      case: 'interviewerId',
      value: profile?.profile?.profileId as UUID,
    });

    const isHaveAvailabilitySlots = useMemo(() => !!slots && slots?.slots?.length > 0, [slots]);

    const recommendations = useMemo(
      () =>
        feedByContract?.recommendations?.recommendations[demandId?.value as string]
          ?.recommendations || [],
      [feedByContract, demandId]
    );

    const isDemandPresent = useMemo(() => {
      const interviews = extractInterviews(appliedDemands?.demands);
      return interviews?.some(
        (interview) => (interview.entity.value as Interview)?.interviewId?.value === demandId?.value
      );
    }, [appliedDemands, demandId]);

    const handleSupplierClick = useCallback((sup: any) => {
      setMatchedTags({
        tags: sup.matchedTags?.tags,
        traits: sup?.traits,
      });
    }, []);

    const suppliers = useMemo(
      () =>
        recommendations &&
        Object.values(recommendations).map((supplier: any) => (
          <Box key={supplier.id}>
            {supplier?.suppliers?.map((sup: any) => (
              <Box
                key={sup.profile.profileId.value}
                onClick={() => handleSupplierClick(sup)}
                display="flex"
                gap={1}
                sx={{ cursor: 'pointer' }}
              >
                <CustomAvatar
                  alt={sup?.profile?.name?.nickName || 'User'}
                  avatarId={sup?.profile?.avatar}
                  profileId={sup?.profile?.profileId?.value}
                />
                <Typography>{sup?.profile?.name?.nickName || '?rec supplier name'}</Typography>
              </Box>
            ))}
          </Box>
        )),
      [recommendations, handleSupplierClick]
    );

    return (
      <Box className={demandIdFromUrl === demandId && showActiveState ? 'matchedCard' : ''}>
        <Card>
          <Box display="flex" gap={2} flexDirection="column" justifyContent="center" mb={2}>
            <Box className="flex justify-between">
              <DemandCardHeader demand={demand} />
              <Box className="flex  ">
                {demand?.entity?.case === 'interview' && (
                  <Button
                    disabled={isDemandPresent}
                    variant="contained"
                    onClick={() => {
                      if (!isHaveAvailabilitySlots) handleOpen();
                      if (isHaveAvailabilitySlots)
                        applyAsInterviewer(demand?.entity?.value?.interviewId?.value || '');
                    }}
                  >
                    {isDemandPresent ? 'You have been applied' : 'Apply'}
                  </Button>
                )}
              </Box>
            </Box>

            {suppliers?.length ? (
              <Box display="flex" gap={1} flexDirection="column">
                <Typography fontWeight="bold">This user is eligible for this demand</Typography>
                {suppliers}
              </Box>
            ) : null}

            <Box display="flex" justifyContent="space-between">
              {demand.entity.value?.tags?.tags && (
                <Tags
                  matchedTags={matchedTags?.tags}
                  showType
                  tags={demand.entity.value?.tags?.tags.filter(
                    (tag) => tag.tagType !== TagType.ROLE
                  )}
                />
              )}
            </Box>
            <div className="w-full break-words whitespace-normal">
              {(demand?.entity?.value as Interview)?.requirements ||
                (demand?.entity?.value as Contract)?.description}
            </div>
          </Box>

          {isPreApplied && demand?.entity?.case === 'contract' && (
            <ContractorSelect contractId={demandId?.value} />
          )}
        </Card>
        <Suspense>
          <CalendarComponentForInterviewer
            afterCloseCallback={() =>
              applyAsInterviewer(demand?.entity?.value?.interviewId?.value as string)
            }
            handleClose={handleClose}
            isOpen={isOpen}
          />
        </Suspense>
      </Box>
    );
  }
);

export default PreAppliedDemandCard;
