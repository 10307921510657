import { useState } from 'react';
import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import AutocompleteController from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FIELD_LABELS } from '@pages/ProfileSettings/ProfileTraitsForm/config';
import { techSpec } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { useTags } from '@store/tags';
import { useCreateContract } from '@services/queries/MarketplaceQueries';
import { useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import { FieldNames } from '@pages/Marketplace/ManageDemands/settings';

export type TCreateContractForm = {
  dashboardId: string;
  rate: number;
  capacity: number;
  experience: number;
  description: string;
  createInterview: boolean;
  paymentType: 'fixed' | 'hourly';
  fixedRate?: number;
  hourlyRate?: number;
  dashboardValue: string;
};

type CreateContractFormProps = {
  handleCloseModal: () => void;
  dashboardId: string;
};

export const paymentOptions = [
  { label: 'Fixed Payment', value: 'fixed' },
  { label: 'Hourly Payment', value: 'hourly' },
];

export const PaymentFields = () => {
  const { watch } = useFormContext();
  const paymentType = watch('paymentType');

  return paymentType === 'fixed' ? (
    <InputController name="fixedRate" label="Fixed Payment Rate" type="number" />
  ) : (
    <Box className="flex gap-2">
      <InputController
        fullWidth
        name="rate"
        label="Define the rate for the contracted work"
        type="number"
      />
      <InputController fullWidth name="capacity" label="Capacity" type="number" />
    </Box>
  );
};

const CreateContractForm = (props: CreateContractFormProps) => {
  const { handleCloseModal, dashboardId } = props;
  const { mutate: createContract } = useCreateContract();

  const {
    getLanguages,
    getProgrammingLanguages,
    getCountries,
    getIndustryDomains,
    getRoles,
    countries,
    industryDomains,
    languages,
    programmingLanguages,
    roles,
  } = useTags();

  const [isTechRole, setIsTechRole] = useState(false);

  return (
    <Form<Omit<TCreateContractForm, 'dashboardType'>>
      submitHandler={(data) => {
        const finalData = { ...data, dashboardType: 'id', dashboardValue: dashboardId };
        createContract(finalData, { onSuccess: handleCloseModal });
      }}
      formProps={{
        defaultValues: {
          dashboardId,
          rate: 0,
          capacity: 0,
          experience: 0,
          description: '',
          createInterview: false,
          paymentType: 'fixed',
          fixedRate: 0,
          hourlyRate: 0,
          dashboardValue: dashboardId, // Початкове значення
        },
      }}
    >
      <Stack spacing={2}>
        <AutocompleteController
          getItems={getRoles}
          multiple
          name="roles"
          label="Specify the role for which the contract is being created"
          options={roles}
          changeHandler={(data: any) =>
            setIsTechRole(data.some((el: any) => techSpec.includes(el.value)))
          }
        />

        {isTechRole && (
          <AutocompleteController
            getItems={getProgrammingLanguages}
            multiple
            name="programmingLanguages"
            label="Mention the programming languages required for the role"
            options={programmingLanguages}
          />
        )}

        <AutocompleteController
          getItems={getCountries}
          multiple
          name={`${FieldNames.COUNTRIES}`}
          label="countries"
          options={countries}
        />
        <AutocompleteController
          getItems={getLanguages}
          multiple
          name={`${FieldNames.LANGUAGES}`}
          label="language"
          options={languages}
        />
        <AutocompleteController
          getItems={getIndustryDomains}
          multiple
          name={`${FieldNames.INDUSTRY_DOMAIN}`}
          label="industryDomains"
          options={industryDomains}
        />

        <InputController select fullWidth label="Payment Type" name="paymentType">
          {paymentOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </InputController>

        <PaymentFields />

        <InputController
          name="experience"
          label="Detail the required experience for the role"
          type="number"
        />

        {/* Checkbox для інтерв'ю */}
        <FormControlLabel
          control={<Checkbox />}
          label={FIELD_LABELS.createInterview}
          name="createInterview"
        />

        {/* Опис */}
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography fontWeight="bold">
            Provide a comprehensive description of the contract:
          </Typography>
          <InputController name="description" multiline rows={4} />
        </Box>

        {/* Кнопка відправлення */}
        <LoadingButton type="submit" variant="outlined">
          Create
        </LoadingButton>
      </Stack>
    </Form>
  );
};

export default CreateContractForm;
