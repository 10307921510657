import {
  BreakDown_Role,
  Step,
  UpdateStepsPositionsRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import Box from '@mui/material/Box';
import React, { Suspense, useEffect, useState } from 'react';
import CreateStepDialog from '@pages/Product/Steps/CreateStepDialog';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { useDialog } from '@components/hooks/useDialog';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '@services/queries/ProductsQueries';
import { Chip, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useUpdateStepsPositions } from '@services/queries/StepsQueries';
import Button from '@mui/material/Button';
import { StepDnDCard } from '@pages/Product/BreakDowns/StepDnDCard';
import { sortStepsByPosition } from '@pages/Product/BreakDowns/config';
import Typography from '@mui/material/Typography';
import UpdateBreakdownDialog from '@pages/Product/Steps/UpdateBreakdownDialog';

const BreakdownDnDCard = ({ breakdown }: { breakdown: BreakDown_Role }) => {
  const { productId } = useParams();
  const { data: product } = useGetProduct(productId as string);
  const [items, setItems] = useState<Step[]>(() => sortStepsByPosition(breakdown?.steps));
  const { isOpen, handleOpen, handleClose } = useDialog();
  const {
    isOpen: isOpenUpdateBreakdownRole,
    handleOpen: handleOpenBreakdownRole,
    handleClose: handleCloseBreakdownRole,
  } = useDialog();
  const { mutate: updatePosition } = useUpdateStepsPositions();
  const [mouseDown, setMouseDown] = useState(false);
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 0.01 },
  });
  const sensors = useSensors(mouseSensor, touchSensor, pointerSensor);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => (item.stepId?.value as string) === active.id);
      const newIndex = items.findIndex((item) => (item.stepId?.value as string) === over.id);

      if (oldIndex === -1 || newIndex === -1) return;

      const newItems = arrayMove(items, oldIndex, newIndex);

      setItems(newItems);

      const indexedStructure = newItems.reduce((acc, item, index) => {
        acc[index] = item.stepId as UUID;
        return acc;
      }, {} as Record<number, UUID>);

      updatePosition(
        new UpdateStepsPositionsRequest({
          positions: indexedStructure,
        }),
        {
          onSuccess: () => {
            setItems(newItems);
          },
        }
      );
    }
  };

  useEffect(() => {
    setItems(sortStepsByPosition(breakdown?.steps));
  }, [breakdown?.steps]);

  return (
    <Box>
      <Box
        key={breakdown?.tag?.value as string}
        className={`flex bg-white/5  ${
          mouseDown ? 'bg-[#212121]' : ''
        } h-auto  pb-4 rounded-xl transition  relative  flex-col w-[300px] gap-2`}
      >
        <Box
          onClick={handleOpenBreakdownRole}
          className="w-full mb-2  !bg-white/20 backdrop-blur-md rounded-t-xl transition cursor-pointer hover:!bg-white/30 py-4 px-4  flex items-center justify-between z-40 sticky top-0"
        >
          <Chip size="small" label={breakdown?.tag?.value} />
          <Box>
            {breakdown?.price.case === 'fixed' ? (
              <Box className="cursor-pointer    !font-bold  flex  items-center gap-2">
                <Typography>${Number(breakdown.price.value).toFixed(0)}</Typography>
              </Box>
            ) : null}

            {breakdown?.price?.case === 'rate' && Number(breakdown?.price?.value.rate) !== 0 ? (
              <Box className="flex cursor-pointer   items-center !font-bold  gap-2">
                <Typography>${Number(breakdown?.price?.value?.rate).toFixed(0)}/h</Typography>
                <Typography>{breakdown?.price?.value?.hours} hours</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>

        {!breakdown?.steps?.length && (
          <Box className="flex px-4 py-4 w-full ">
            <Tooltip className="flex items-center" placement="bottom" title="Add step">
              <Button fullWidth variant="outlined" onClick={handleOpen}>
                <Box className="cursor-pointer flex items-center text-[36px] font-bold">
                  <AddCircleIcon />
                </Box>
              </Button>
            </Tooltip>
          </Box>
        )}

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={items.map((item) => item.stepId?.value as string)}
            strategy={verticalListSortingStrategy}
          >
            <ul className="flex flex-col gap-4" style={{ listStyle: 'none', padding: 0 }}>
              {items.map((item, index) => (
                <StepDnDCard
                  key={item.stepId?.value as string}
                  step={item}
                  steps={items}
                  tagId={breakdown?.tag?.tagId as number}
                  setMouseOver={setMouseDown}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      </Box>

      <Suspense>
        <UpdateBreakdownDialog
          breakdown={breakdown}
          isOpen={isOpenUpdateBreakdownRole}
          roleId={breakdown.tag?.tagId as number}
          handleCloseModal={handleCloseBreakdownRole}
        />
      </Suspense>

      <Suspense>
        <CreateStepDialog
          handleClose={handleClose}
          isOpen={isOpen}
          productId={new UUID({ value: productId })}
          breakDowns={product?.product?.breakDown}
          tagId={breakdown?.tag?.tagId}
        />
      </Suspense>
    </Box>
  );
};

export default BreakdownDnDCard;
