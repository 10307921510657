import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import React from 'react';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useCreateBreakDown } from '@services/queries/StepsQueries';
import {
  CreateBreakdownormProps,
  createBreakdownSchema,
  FieldNames,
  getDefaultValuesCreateBreakdown,
} from '@pages/Product/Steps/config';
import AutocompleteController from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import { useTags } from '@store/tags';
import { CreateBreakDownRequest } from '@proto/marketplace/product/v1/break_down_pb';
import { Tag, TagType } from '@proto/profiler/tags/v1/tags_pb';

const CreateBreakDownDialog = (props: CreateBreakdownormProps) => {
  const { isOpen, handleClose, productId } = props;
  const { roles, getRoles } = useTags();
  const memoizedRoles = React.useMemo(() => roles.filter((role) => role), [roles]);
  const { mutate: createBreakDown } = useCreateBreakDown();

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        display="flex"
        className="w-full no-drag max-w-[848px]"
        flexDirection="column"
        gap={1}
        py={5}
        px={{ xs: 3 }}
      >
        <Box display="flex" gap={2}>
          <Form
            width="100%"
            minWidth="800px"
            display="flex"
            alignSelf="center"
            flexDirection="column"
            formProps={{ defaultValues: getDefaultValuesCreateBreakdown() }}
            resolverSchema={createBreakdownSchema}
            submitHandler={(data) => {
              handleClose();
              createBreakDown(
                new CreateBreakDownRequest({
                  productId,
                  role: new Tag({
                    tagId: data.roles?.tagId,
                    value: data.roles?.value,
                    tagType: TagType.ROLE,
                    alias: data.roles?.alias,
                    active: data.roles?.active,
                  }),
                })
              );
              // if (step) {
              //     updateStep(buildUpdateStepRequest(step.stepId!, data, isRate));
              // }
              //
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="subtitle1">Create Breakdown</Typography>
              {productId && (
                <AutocompleteController
                  multiple={false}
                  getItems={getRoles}
                  name={`${FieldNames.ROLES}`}
                  label="Select the role"
                  options={memoizedRoles}
                />
              )}

              <LoadingButton type="submit" color="success" variant="outlined">
                Create
              </LoadingButton>
            </Box>
          </Form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateBreakDownDialog;
