// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/contractor.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ApplyOnContractsRequest,
  ApplyOnContractsResponse,
  ApproveContractorRequest,
  ApproveContractorResponse,
  RejectContractorRequest,
  RejectContractorResponse,
  RevokeContractorApplyRequest,
  RevokeContractorApplyResponse,
} from '../../../marketplace/demand/v1/contractor_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.ContractorAPI
 */
export const ContractorAPI = {
  typeName: 'api.marketplace.v1.ContractorAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.ContractorAPI.ApplyOnContracts
     */
    applyOnContracts: {
      name: 'ApplyOnContracts',
      I: ApplyOnContractsRequest,
      O: ApplyOnContractsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ContractorAPI.RevokeContractorApply
     */
    revokeContractorApply: {
      name: 'RevokeContractorApply',
      I: RevokeContractorApplyRequest,
      O: RevokeContractorApplyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ContractorAPI.ApproveContractor
     */
    approveContractor: {
      name: 'ApproveContractor',
      I: ApproveContractorRequest,
      O: ApproveContractorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ContractorAPI.RejectContractor
     */
    rejectContractor: {
      name: 'RejectContractor',
      I: RejectContractorRequest,
      O: RejectContractorResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
