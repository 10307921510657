import * as React from 'react';
import { Suspense } from 'react';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import { Page } from '@components/Page/Page';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import Typography from '@mui/material/Typography';
import { useFeed } from '../../../zustand/feed';
import FeedByDemandsDialog from './FeedByDemand/FeedByDemandsDialog';
import SidebarProfileSettings from '../../Main/SidebarProfileSettings/SidebarProfileSettings';
import FeedList from './FeedList';
import FiltersFeedItems from './FiltersFeedItems';

export const demandsTypes = {
  contract: Entity_Type.CONTRACT,
  interview: Entity_Type.INTERVIEW,
};

const FeedPage = () => {
  const { setIsPinnedReturn, isPinnedReturn } = useFeed();

  return (
    <Page description="Demands" title="Demands">
      <Container>
        <Box className="relative flex flex-col">
          <div className="flex gap-4 ">
            {/* Main Content */}
            <Box className="">
              <FeedList />
            </Box>
            {/* Sidebar */}
            <aside className="max-w-[420px]  bg-transparent     flex-shrink-0 h-full sticky top-28">
              <Box className="flex  bg-white/5 p-4 !backdrop-blur-3xl mb-2	rounded-xl flex-col">
                {/* <EventAvailableIcon/> */}
                <Box className="flex" gap={2}>
                  <FiltersFeedItems />
                  <Box className="flex items-center">
                    <Typography lineHeight="16px" fontSize="13px">
                      Show pinned
                    </Typography>
                    <Checkbox
                      onChange={() => setIsPinnedReturn(!isPinnedReturn)}
                      value={isPinnedReturn}
                    />
                  </Box>
                </Box>
              </Box>
              <SidebarProfileSettings />
            </aside>
          </div>
        </Box>
        <Suspense fallback={<div>Loading...</div>}>
          <FeedByDemandsDialog />
        </Suspense>
      </Container>
    </Page>
  );
};

export default FeedPage;
