import React from 'react';
import Box from '@mui/material/Box';
import { Header } from '@components/Header/Header';

interface Props {
  children?: React.ReactNode;
}

export const DashboardContainer = (props: Props) => {
  const { children } = props;

  return (
    <Box className="relative">
      <Header />
      <Box className="pt-[16px]" display="flex" flexDirection="column" width="100%">
        {children}
      </Box>
    </Box>
  );
};
