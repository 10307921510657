// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file notificator/stream/v1/events.proto (package notificator.stream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';

/**
 * @generated from message notificator.stream.v1.Event
 */
export class Event extends Message<Event> {
  /**
   * @generated from field: notificator.stream.v1.Event.Payload payload = 1;
   */
  payload?: Event_Payload;

  /**
   * @generated from field: grpc.type.v1.UUIDS recipients = 2;
   */
  recipients?: UUIDS;

  constructor(data?: PartialMessage<Event>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Event';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'payload', kind: 'message', T: Event_Payload },
    { no: 2, name: 'recipients', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Event {
    return new Event().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJsonString(jsonString, options);
  }

  static equals(
    a: Event | PlainMessage<Event> | undefined,
    b: Event | PlainMessage<Event> | undefined
  ): boolean {
    return proto3.util.equals(Event, a, b);
  }
}

/**
 * @generated from enum notificator.stream.v1.Event.Type
 */
export enum Event_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_INVITE = 1;
   */
  INVITE = 1,

  /**
   * @generated from enum value: TYPE_EVALUATION = 2;
   */
  EVALUATION = 2,

  /**
   * @generated from enum value: TYPE_RECOMMENDATION = 3;
   */
  RECOMMENDATION = 3,

  /**
   * @generated from enum value: TYPE_ACHIEVEMENT = 4;
   */
  ACHIEVEMENT = 4,

  /**
   * @generated from enum value: TYPE_SUPPLY_APPLY = 5;
   */
  SUPPLY_APPLY = 5,

  /**
   * @generated from enum value: TYPE_SUPPLY_APPROVE = 6;
   */
  SUPPLY_APPROVE = 6,

  /**
   * @generated from enum value: TYPE_SUPPLY_REJECT = 7;
   */
  SUPPLY_REJECT = 7,

  /**
   * @generated from enum value: TYPE_SUPPLY_REVOKE = 8;
   */
  SUPPLY_REVOKE = 8,

  /**
   * @generated from enum value: TYPE_NEW_PRODUCT_ORDER = 9;
   */
  NEW_PRODUCT_ORDER = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(Event_Type)
proto3.util.setEnumType(Event_Type, 'notificator.stream.v1.Event.Type', [
  { no: 0, name: 'TYPE_UNSPECIFIED' },
  { no: 1, name: 'TYPE_INVITE' },
  { no: 2, name: 'TYPE_EVALUATION' },
  { no: 3, name: 'TYPE_RECOMMENDATION' },
  { no: 4, name: 'TYPE_ACHIEVEMENT' },
  { no: 5, name: 'TYPE_SUPPLY_APPLY' },
  { no: 6, name: 'TYPE_SUPPLY_APPROVE' },
  { no: 7, name: 'TYPE_SUPPLY_REJECT' },
  { no: 8, name: 'TYPE_SUPPLY_REVOKE' },
  { no: 9, name: 'TYPE_NEW_PRODUCT_ORDER' },
]);

/**
 * @generated from message notificator.stream.v1.Event.Payload
 */
export class Event_Payload extends Message<Event_Payload> {
  /**
   * @generated from field: notificator.stream.v1.Event.Type type = 1;
   */
  type = Event_Type.UNSPECIFIED;

  /**
   * @generated from field: grpc.type.v1.UUID id = 2;
   */
  id?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity entity = 3;
   */
  entity?: Entity;

  /**
   * @generated from field: string subject = 4;
   */
  subject = '';

  /**
   * @generated from field: string message = 5;
   */
  message = '';

  constructor(data?: PartialMessage<Event_Payload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Event.Payload';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type', kind: 'enum', T: proto3.getEnumType(Event_Type) },
    { no: 2, name: 'id', kind: 'message', T: UUID },
    { no: 3, name: 'entity', kind: 'message', T: Entity },
    { no: 4, name: 'subject', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Event_Payload {
    return new Event_Payload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Event_Payload {
    return new Event_Payload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Event_Payload {
    return new Event_Payload().fromJsonString(jsonString, options);
  }

  static equals(
    a: Event_Payload | PlainMessage<Event_Payload> | undefined,
    b: Event_Payload | PlainMessage<Event_Payload> | undefined
  ): boolean {
    return proto3.util.equals(Event_Payload, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.CreateRequest
 */
export class CreateRequest extends Message<CreateRequest> {
  /**
   * @generated from field: string subject = 1;
   */
  subject = '';

  /**
   * @generated from field: repeated notificator.stream.v1.Event events = 2;
   */
  events: Event[] = [];

  constructor(data?: PartialMessage<CreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.CreateRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'events', kind: 'message', T: Event, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRequest {
    return new CreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateRequest | PlainMessage<CreateRequest> | undefined,
    b: CreateRequest | PlainMessage<CreateRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.CreateResponse
 */
export class CreateResponse extends Message<CreateResponse> {
  constructor(data?: PartialMessage<CreateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.CreateResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateResponse {
    return new CreateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateResponse | PlainMessage<CreateResponse> | undefined,
    b: CreateResponse | PlainMessage<CreateResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.DeleteRequest
 */
export class DeleteRequest extends Message<DeleteRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS ids = 1;
   */
  ids?: UUIDS;

  constructor(data?: PartialMessage<DeleteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.DeleteRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRequest {
    return new DeleteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRequest {
    return new DeleteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRequest {
    return new DeleteRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteRequest | PlainMessage<DeleteRequest> | undefined,
    b: DeleteRequest | PlainMessage<DeleteRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.DeleteResponse
 */
export class DeleteResponse extends Message<DeleteResponse> {
  constructor(data?: PartialMessage<DeleteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.DeleteResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteResponse {
    return new DeleteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteResponse {
    return new DeleteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteResponse {
    return new DeleteResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteResponse | PlainMessage<DeleteResponse> | undefined,
    b: DeleteResponse | PlainMessage<DeleteResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.MarkAsReadRequest
 */
export class MarkAsReadRequest extends Message<MarkAsReadRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS ids = 1;
   */
  ids?: UUIDS;

  constructor(data?: PartialMessage<MarkAsReadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.MarkAsReadRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkAsReadRequest {
    return new MarkAsReadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkAsReadRequest {
    return new MarkAsReadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkAsReadRequest {
    return new MarkAsReadRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: MarkAsReadRequest | PlainMessage<MarkAsReadRequest> | undefined,
    b: MarkAsReadRequest | PlainMessage<MarkAsReadRequest> | undefined
  ): boolean {
    return proto3.util.equals(MarkAsReadRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.MarkAsReadResponse
 */
export class MarkAsReadResponse extends Message<MarkAsReadResponse> {
  constructor(data?: PartialMessage<MarkAsReadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.MarkAsReadResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkAsReadResponse {
    return new MarkAsReadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkAsReadResponse {
    return new MarkAsReadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): MarkAsReadResponse {
    return new MarkAsReadResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: MarkAsReadResponse | PlainMessage<MarkAsReadResponse> | undefined,
    b: MarkAsReadResponse | PlainMessage<MarkAsReadResponse> | undefined
  ): boolean {
    return proto3.util.equals(MarkAsReadResponse, a, b);
  }
}
