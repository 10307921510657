// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/evaluation.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AcceptEvaluationFeedbackRequest,
  AcceptEvaluationFeedbackResponse,
  AddEvaluationScheduleSlotsRequest,
  AddEvaluationScheduleSlotsResponse,
  DeleteEvaluationScheduleSlotRequest,
  DeleteEvaluationScheduleSlotResponse,
  GetEvaluationFeedbackDraftRequest,
  GetEvaluationFeedbackDraftResponse,
  GetEvaluationRequest,
  GetEvaluationResponse,
  GetInterviewerAvailabilityRequest,
  GetInterviewerAvailabilityResponse,
  ListEvaluationsRequest,
  OccupySlotRequest,
  OccupySlotResponse,
  RescheduleEvaluationRequest,
  RescheduleEvaluationResponse,
  SaveEvaluationFeedbackDraftRequest,
  SaveEvaluationFeedbackDraftResponse,
  SendEvaluationFeedbackRequest,
  SendEvaluationFeedbackResponse,
} from '../../../marketplace/demand/v1/evaluation_pb.js';
import { MethodKind } from '@bufbuild/protobuf';
import { ListEvaluationsResponse } from './evaluation_pb.js';

/**
 * @generated from service api.marketplace.v1.EvaluationAPI
 */
export const EvaluationAPI = {
  typeName: 'api.marketplace.v1.EvaluationAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.SaveEvaluationFeedbackDraft
     */
    saveEvaluationFeedbackDraft: {
      name: 'SaveEvaluationFeedbackDraft',
      I: SaveEvaluationFeedbackDraftRequest,
      O: SaveEvaluationFeedbackDraftResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.GetEvaluationFeedbackDraft
     */
    getEvaluationFeedbackDraft: {
      name: 'GetEvaluationFeedbackDraft',
      I: GetEvaluationFeedbackDraftRequest,
      O: GetEvaluationFeedbackDraftResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.SendEvaluationFeedback
     */
    sendEvaluationFeedback: {
      name: 'SendEvaluationFeedback',
      I: SendEvaluationFeedbackRequest,
      O: SendEvaluationFeedbackResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.GetEvaluation
     */
    getEvaluation: {
      name: 'GetEvaluation',
      I: GetEvaluationRequest,
      O: GetEvaluationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.ListEvaluations
     */
    listEvaluations: {
      name: 'ListEvaluations',
      I: ListEvaluationsRequest,
      O: ListEvaluationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.AcceptEvaluationFeedback
     */
    acceptEvaluationFeedback: {
      name: 'AcceptEvaluationFeedback',
      I: AcceptEvaluationFeedbackRequest,
      O: AcceptEvaluationFeedbackResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Interviewer
     *
     * @generated from rpc api.marketplace.v1.EvaluationAPI.AddEvaluationScheduleSlots
     */
    addEvaluationScheduleSlots: {
      name: 'AddEvaluationScheduleSlots',
      I: AddEvaluationScheduleSlotsRequest,
      O: AddEvaluationScheduleSlotsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.DeleteEvaluationScheduleSlot
     */
    deleteEvaluationScheduleSlot: {
      name: 'DeleteEvaluationScheduleSlot',
      I: DeleteEvaluationScheduleSlotRequest,
      O: DeleteEvaluationScheduleSlotResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Candidate
     *
     * @generated from rpc api.marketplace.v1.EvaluationAPI.OccupySlot
     */
    occupySlot: {
      name: 'OccupySlot',
      I: OccupySlotRequest,
      O: OccupySlotResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.EvaluationAPI.RescheduleEvaluation
     */
    rescheduleEvaluation: {
      name: 'RescheduleEvaluation',
      I: RescheduleEvaluationRequest,
      O: RescheduleEvaluationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Both
     *
     * @generated from rpc api.marketplace.v1.EvaluationAPI.GetInterviewerAvailability
     */
    getInterviewerAvailability: {
      name: 'GetInterviewerAvailability',
      I: GetInterviewerAvailabilityRequest,
      O: GetInterviewerAvailabilityResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
