import { Chip, Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import { ROUTES_PREFIXES } from '@common/routes';
import CustomAvatar from '@components/Avatar/Avatar';
import { Org } from '@proto/profiler/biz/v1/org_pb';
import { Link, useSearchParams } from 'react-router-dom';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';

type TExploreOrgCardProps = {
  org: Org;
  handleClickTag: (id: number) => void;
};

const ExploreOrgCard = (props: TExploreOrgCardProps) => {
  const { org, handleClickTag } = props;
  const link = `${ROUTES_PREFIXES.ORGS}/${org.name}`;
  const [searchParams] = useSearchParams();
  const selectedTags = (searchParams.get('tags') || '').split(',').map((id) => Number(id));

  return (
    <Box className="rounded-xl  py-4 px-2 border-white/10 bg-white/5 flex flex-col gap-4 hover:bg-white/10 transition">
      <Link className="NavLink flex flex-col gap-2" to={link}>
        <Box className="flex items-center gap-2">
          <CustomAvatar
            alt={org?.name || 'Org'}
            avatarId={org?.avatar}
            profileId={org?.orgId?.value}
            height={30}
            width={30}
          />
          <Typography fontWeight="bold">{org.name}</Typography>
        </Box>

        <Typography sx={{ wordBreak: 'break-all' }} className="text-white/80 text-[14px]">
          {org.about}
        </Typography>
      </Link>
      <Box className="flex flex-wrap gap-2">
        {org?.tags?.tags?.map((tag) => (
          <Chip
            size="small"
            variant={selectedTags.includes(tag.tagId) ? 'filled' : 'outlined'}
            onClick={
              tag.tagType === TagType.INDUSTRY_DOMAIN
                ? (event) => {
                    event.stopPropagation();
                    if (tag.tagType === TagType.INDUSTRY_DOMAIN) handleClickTag(tag.tagId);
                  }
                : undefined
            }
            key={tag.value}
            label={tag.value}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ExploreOrgCard;
