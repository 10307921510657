import Box from '@mui/material/Box';
import React from 'react';
import SidebarProfileTraitsForm from '@pages/Main/SidebarProfileSettings/SidebarProfileTraitsForm';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import InterviewerButton from '@pages/Main/SidebarProfileSettings/InterviewerButton';

const SidebarProfileSettings = () => {
  return (
    <Box display="flex" className=" bg-white/5 p-4 rounded-xl" flexDirection="column" gap={2}>
      {/* <UserInvitesList /> */}
      <Box display="flex" flexDirection="column" gap={1}>
        <InterviewerButton />
        <SidebarProfileTraitsForm />
        <ProfileTags />
      </Box>
    </Box>
  );
};

export default SidebarProfileSettings;
