import Box from '@mui/material/Box';
import React from 'react';
import { InputController } from '@components/Form/controllers/InputController';
import { Form } from '@components/Form';
import {
  FIELD_LABELS,
  FieldNames,
  getDefaultValues,
} from '@pages/ProfileSettings/ProfileTraitsForm/config';
import Grid from '@mui/material/Grid';
import { useGetMyProfile, useUupdateProfileTraits } from '@services/queries/ProfilesQueries';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { useFormContext } from 'react-hook-form';

const SubmitButton = () => {
  const { watch } = useFormContext();
  const { data: userProfile } = useGetMyProfile();

  if (
    Number(userProfile?.profile?.traits?.rate) === Number(watch('traits')?.rate) &&
    userProfile?.profile?.traits?.experience === watch('traits')?.experience &&
    userProfile?.profile?.traits?.capacity === watch('traits')?.capacity
  )
    return null;

  return (
    <Grid item xs={12} display="flex" flexDirection="column">
      <LoadingButton type="submit">
        <CheckIcon sx={{ width: '18px', height: '18px' }} />
      </LoadingButton>
    </Grid>
  );
};

const SidebarProfileTraitsForm = () => {
  const { mutate: updateTraits, error } = useUupdateProfileTraits();
  const { data: userProfile } = useGetMyProfile();

  const handleSubmit = (data: any) => {
    updateTraits({ ...data, rate: BigInt(data.traits.rate) });
  };

  return userProfile ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile?.profile?.traits) }}
      // resolverSchema={myProfileTraitsFormSchema}
      submitHandler={handleSubmit}
    >
      <Box display="flex" flexDirection="column" mb={1} gap={1}>
        <Box className="flex gap-4">
          <Grid pt={0} item xs={4}>
            <InputController
              variant="standard"
              fullWidth
              type="number"
              name={`traits.${FieldNames.RATE}`}
              label={FIELD_LABELS[FieldNames.RATE]}
            />
          </Grid>
          <Grid item xs={4}>
            <InputController
              variant="standard"
              fullWidth
              type="number"
              name={`traits.${FieldNames.CAPACITY}`}
              label={FIELD_LABELS[FieldNames.CAPACITY]}
            />
          </Grid>
          <Grid item xs={4}>
            <InputController
              variant="standard"
              fullWidth
              type="number"
              name={`traits.${FieldNames.EXPIRIENCE}`}
              label={FIELD_LABELS[FieldNames.EXPIRIENCE]}
            />
          </Grid>
        </Box>
        <SubmitButton />
      </Box>
    </Form>
  ) : null;
};

export default SidebarProfileTraitsForm;
