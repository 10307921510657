import React, { useMemo, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useActivateAvatar, useListAvatars } from '@services/queries/AvatarQueries';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ActivateAvatarRequest, ListAvatarsRequest } from '@proto/profiler/avatars/v1/avatar_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import AddAvatarButton from '@components/Avatars/AddAvatarButton';
import Dialog from '@mui/material/Dialog';

const AvatarLightbox = ({ link, entityId }: { link: string; entityId: string }) => {
  const { data: listAvatars } = useListAvatars(
    new ListAvatarsRequest({
      option: {
        case: 'entityId',
        value: new UUID({ value: entityId }),
      },
    })
  );
  const { mutate } = useActivateAvatar();
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const sources = useMemo(
    () =>
      listAvatars?.avatars?.avatars?.map(
        (avatar) =>
          `${process.env.REACT_APP_AVATARS_ENDPOINT}/${avatar.entityId?.value}/${avatar.fileName}`
      ) || [],
    [listAvatars]
  );

  const openModal = () => {
    const initialIndex = sources.indexOf(link);
    if (initialIndex !== -1) {
      setCurrentIndex(initialIndex);
    }
    setIsOpen(true);
  };

  const handleButtonClick = () => {
    const selectedAvatar = listAvatars?.avatars?.avatars[currentIndex];
    if (selectedAvatar) {
      mutate(
        new ActivateAvatarRequest({
          option: {
            case: 'avatarId',
            value: selectedAvatar.avatarId as UUID,
          },
        })
      );
    }
  };

  return (
    <>
      <Avatar
        sx={{
          width: 100,
          height: 100,
          cursor: 'pointer',
          borderRadius: '50px',
          background: 'rgba(255,255,255,0.20)',
        }}
        variant="rounded"
        src={link}
        onClick={openModal}
      />
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Box className="p-10">
          <Box className="flex justify-center items-center">
            <AddAvatarButton entityId={entityId} />
          </Box>
          {sources?.length ? (
            <Box className="mb-2">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={1}
                mb={1}
              >
                <Box sx={{ width: '400px', height: '400px' }}>
                  <Box
                    component="img"
                    src={sources[currentIndex]}
                    alt={`Slide ${currentIndex}`}
                    sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleButtonClick}
                  sx={{ margin: '10px' }}
                >
                  Activate
                </Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {sources.map((src, index) => (
                  <Box
                    key={src}
                    component="img"
                    src={src}
                    alt={`Thumbnail ${index}`}
                    sx={{
                      width: '60px',
                      height: '60px',
                      margin: '0 5px',
                      cursor: 'pointer',
                      border: currentIndex === index ? '2px solid #1769aa' : 'none',
                      borderRadius: '5px',
                    }}
                    onClick={() => setCurrentIndex(index)}
                  />
                ))}
              </Box>
              <ArrowBackIosIcon
                className="absolute top-[50%] hover:text-[#7986cb] left-[15px] cursor-pointer"
                onClick={() =>
                  setCurrentIndex((currentIndex - 1 + sources.length) % sources.length)
                }
              />

              <ArrowForwardIosIcon
                className="absolute top-[50%] hover:text-[#7986cb] right-[15px] cursor-pointer"
                onClick={() => setCurrentIndex((currentIndex + 1) % sources.length)}
              />
            </Box>
          ) : (
            <Box className="text-center font-bold">Add new avatar</Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default AvatarLightbox;
